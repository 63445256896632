export const inputElements = {margin: "16px 8px 16px 12px", width: "95%"};
export const inputElementGroup = {margin: "0px 8px 0.5em 12px", width: "95%"};
export const singleLineInput = {margin: "16px 8px 16px 12px", width: "95%", wordBreak: "break-word"};
export const multilineInput = {margin: "2em 8px 16px 12px", width: "95%"};
export const CardWithoutBoxes = {boxShadow: "none"};
export const comboBox = { width: "90%", padding: 6};
export const ElementWithoutPadding = {paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px"};
export const BottomButtonsBarLeft = {display: "inline-block", borderRight: "1px solid black", margin: "4px"};
export const BottomButtonsBarRight = {display: "inline-block", borderLeft: "1px solid black", margin: "4px"};
export const progressCircle = { margin: "5px"};
export const centeredCircle = {margin: "auto", verticalAlign: "middle"};
export const swipePage = { marginTop: "30px"};
export const tooltip = {tooltip: "text-align: center; font-size : 18px"};
export const limitToOneLine = {overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"};
export const wrapToMultipleLines = {overflow: "visible", whiteSpace: "normal"};
export const rightTop = {position: "absolute", right: -2, top: -6};
export const subHeaderRowMainWordCard = {fontsize: "16px", marginBottom: "15px", marginLeft: "15px"};
export const rowPanel = {marginLeft: "15px", marginBottom: "15px"};
export const rowPanelWithoutReportLevel = {marginLeft: "15px", marginBottom: "0px"};
export const PaperShadow = {
    padding: 12,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 4,
    backgroundColor: "white",
};

export const PaperWithoutPadding = {
    padding: 0,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 4,
    backgroundColor: "white",
};

export const boardSettingsSelect = {
    display: "inline-flex",
    flexWrap: "wrap",
    backgroundColor: "white",
    padding: "3px",
    minHeight: 100,
    border: "1px solid black",
    width: "95%",
    marginTop: 25,
    transition: "all 700ms ease-out 30ms",
};

export const listRowElement = (hovering) => {
    return {
        backgroundColor: hovering ? "#f1f1f1" : "white",
        transition: "0.3s ease-out",
        alignItems: "center"
    };
};

export const ContentPadding = {padding: "1px 10px"};

export const FieldHeight = {
    minHeight: 48,
    marginTop: 16,
    marginBottom: 16
};

export const labelMargins = {
    marginBottom: "16px ",
    marginTop: "16px",
    paddingLeft: "10px"
};

export const headerRowMainWordCard = {
    fontSize: "20px",
    marginBottom: "15px"
};

export const headerRowMainWithoutReportLevel = {
    fontSize: "20px",
    marginTop: "30px",
    marginBottom: "15px"
};

export const button = (color) => {
    if(color === "submit"){
        return {boxShadow: "rgba(0, 36, 139, 1)", margin: "8px"};
    }

    else if(color === "cancel"){
        return {boxShadow: "rgba(45, 79, 98, 0.51)", margin: "8px"};
    }

    else if(color === "disabled"){
        return {boxShadow: "rgba(0, 0, 0, 0,6)", margin: "8px"};
    }
    else{
        return {margin: "8px"};
    }
};

export const leftIcon = {marginRight: "8px"};
export const listFilter = {
    height: 30,
    width: 30,
    fontSize: 18,
    margin: "auto"
};

export const modalPaper = {
    backgroundColor: "white",
    borderRadius: "5px"
};

export const centeredModalPaper = {
    backgroundColor: "white",
    borderRadius: "5px",
    margin: "auto"
};

export const modal = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

export const circleButton = {
    margin: "5px",
    borderRadius: "50px",
    padding: "9px 10px",
    minWidth: "initial",
};

export const NewButton = {
    margin: "10px",
    background: "#F47676",
    borderRadius: 14,
    color: "#2F2F2F",
    width: 40,
    height: 40,
};


export const circleRowButton = (focused, expanded) => {
    return {
        margin: "auto 4px",
        borderRadius: "50px",
        padding: "8px 8px",
        height: "fit-content",
        minWidth: "initial", 
        transition: "opacity 0.3s", 
        opacity: (focused && !expanded ? 1 : 0)
    };
};

export const floatButton = {
    right: "0px",
    position: "fixed",
    zIndex: "100",
    float: "right",
    bottom: "0"
};

export const floatButtonTop = {
    left: "60px",
    position: "fixed",
    zIndex: "100",
    float: "left",
    top: "14px",
    transition: "all ease-out 1s 0s"
};

export const floatButtonCenter = {
    position: "fixed",
    zIndex: "100",
    left: "50%",
    top: "50%",
};

export const endAdormentIput = {
    position: "absolute",
    marginLeft: "-20px",
    marginTop: "34px",
    background: "white"
};

export const floatButtomLeft = {
    left: "auto",
    position: "fixed",
    zIndex: "100",
    bottom: "0"
};
export const BottomButtonsBar = (left) => {
    return {
        background: "rgba(85, 100, 107, 0.89)",
        position: "sticky",
        zIndex: "100",
        bottom: "0",
        left: left,
        right: 0,
        width: "auto",
        margin: 0,
        textAlign: "center",
    };
};

export const BottomBarName = {
    background: "none",
    color: "white",
    display: "inline-block",
    marginTop: "-5px",
    minWidth: "33%",
    maxWidth: "50%",
    textAlign: "center"
};
export const editModal = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px"
};

export const expanded = {
    display: "block",
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: "transform 500ms",
    position: "absolute",
    right: 0,
    top: 0
};
export const expandOpen = {
    marginLeft: "auto",
    position: "absolute",
    display: "block",
    transform: "rotate(180deg)",
    right: 0,
    top: 0
};
export const centeredModal = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px",
};
export const leftScroll = (offset) => {
    return {
        position: "absolute",
        top: "28%",
        left: offset,
        height: "70%"
    };
};

export const rightScroll = {
    position: "absolute",
    top: "28%",
    right: 0,
    height: "70%"
};

export const tagSelectorList = {
    display: "inline-grid",
    overflowY: "scroll",
    overflowX: "hidden",
    margin: "2px -4px",
    maxHeight: 200,
    padding: 8
};
export const tagCreatorList = {
    display: "inline-grid",
    overflowY: "scroll",
    overflowX: "hidden",
    margin: "6px 2px",
    padding: 8,
    maxHeight: 150,
};
export const secondLineEllipsis = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 2,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical"
};

// Pseudorandom color by string
const colorByString = (tagStr) => {
    let hash = 0;
    for (var i = 0; i < tagStr.length; i++) {
        hash = Math.imul(2654435761, hash ^ tagStr.charCodeAt(i)) | 0;
    }

    const h = hash % 359;
    const s = 63 + hash % 37;
    const l = Math.min(46, 44 + hash % 7);

    return `hsl(${h}, ${s}%, ${l}%)`;
};

export const coloredChip = (str) => ({
    background: !!str ? colorByString(str) : "#888888",
    margin: 2, 
    fontSize: 14,
    fontWeight: 700,
    color: "#ffffffee",
    whiteSpace: "nowrap"
});

export const chatMemberChip = {
    border: "gainsboro solid 1px",
    background: "white",
    margin: 2, 
    fontSize: 14,
    boxShadow: "3px 4px 10px -5px rgba(0,0,0,0.75)",
    WebkitBoxShadow: "3px 4px 10px -5px rgba(0,0,0,0.75)",
    MozBoxShadow: "3px 4px 10px -5px rgba(0,0,0,0.75)"
};

export const disabledButton = {
    background: "#DBDBDB",
    boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.6)",
    borderRadius: 4,
    padding: "4px 16px",
    fontWeight: 700,
};

export const saveButton = (bkgOkBtn, colorOkBtn, isLoading) => {
    return{
        margin: 6, 
        fontWeight: 500,
        background: !!isLoading ? "lightgray" : (bkgOkBtn ? bkgOkBtn : "#3461E2"),
        color: colorOkBtn ? colorOkBtn : "white",
        boxShadow: !!isLoading ? " rgb(150 150 150) 0px 3px 5px" : " 0px 3px 5px #00248B",
        borderRadius: 4,
        padding: "4px 16px"
    };
};

export const cancelButton = (bkgCloseBtn, colorCloseBtn) => {
    return{
        margin: 6,
        fontWeight: 600,
        background: bkgCloseBtn ? bkgCloseBtn : "white",
        color: colorCloseBtn ? colorCloseBtn : "#2F2F2F",
        border: "1px solid black",
        boxShadow: "rgb(0 0 0 / 50%) 0px 3px 5px",
        borderRadius: 4,
        padding: "4px 16px"
    };
};

export const secondaryButton = () => {
    return{
        margin: 6,
        fontWeight: 600,
        background: "#B2E2FD",
        color: "#2F2F2F",
        boxShadow: "rgb(0 0 0 / 50%) 0px 3px 5px",
        borderRadius: 4,
        padding: "4px 16px"
    };
};

export const chipPopper = {
    margin: 4,
    padding: 4,
    textAlign: "center",
    border: "1px solid #a7a7a71c",
    borderRadius: 6,
    maxWidth: 180,
    background: "white",
    boxShadow: "3px 4px 10px -7px rgba(0,0,0,0.75)",
    WebkitBoxShadow: "3px 4px 10px -7px rgba(0,0,0,0.75)",
    MozBoxShadow: "3px 4px 10px -7px rgba(0,0,0,0.75)"
};

export const fileDialogPaper = {
    display: "inline-flex",
    flexWrap: "wrap",
    alignItems: "center",
    transition: "all 700ms ease-out 30ms",
    padding: "1em"
};

export const notificationToast = {
    boxShadow: "rgb(0 0 0 / 10%) 1px 1px 12px 1px",
    maxWidth: 600,
    minHeight: 60, 
    padding: 8, 
    backgroundColor: "white",
    margin: "33px -8px 0px 8px",
};

export const SpeedDialMenu = {
    staticTooltipLabel: { 
        zIndex: 9999, 
        whiteSpace: "nowrap", 
        overflow: "hidden", 
        color: "white", 
        background: "#333333d4"
    }, 
    staticTooltip: { height: "10px", 
        zIndex: 9999, 
        whiteSpace: "nowrap", 
        overflow: "hidden", 
        color: "white", 
        background: "#333333d4"
    },
    root: {
        zIndex: 9000
    },
    fab: {
        position: "relative",
        zIndex: 9999,
    },
    actions: {
        marginTop: -52
    },
    directionDown: {
        paddingTop: -52
    },
};

export const userSettingDetails = (selected) => {
    return {
        minWidth: 0, 
        fontWeight: 400, 
        fontSize: 14,
        color: "gray", 
        margin: 4, 
        whiteSpace: selected ? "normal" : "nowrap", 
        overflow: "hidden", 
        textOverflow: "ellipsis",
        textAlign: "left"
    };
};

export const userSettingContainer = (selected) => {
    return {
        padding: 6,
        margin: 8,
        borderRadius: 6,
        height: selected ? "inherit" : "inherit", 
        backgroundColor: "white",
        flexGrow: 1,
        overflow: "hidden",
        maxHeight: selected ? 1000 : 100,
        transition: "all 800ms cubic-bezier(0.4, 0, 0.2, 1) 0s",
        border: !!selected ? "1px solid #0703A5" : "1px solid #D8D8D8",
    }; 
};   

export const userAvatar = (assigned) => {
    return {
        padding: 6,
        margin: 0,
        marginRight: 0,
        width: "40px",
        height: "40px",
        backgroundColor: !assigned ? "#1e6a8e" : "#171eff",
        border: !assigned ? "1px solid #013954" : "1px solid #002a8a",
        color: "white"
    }; 
};   