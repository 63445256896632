
import {Locales} from "../../i18n/locales";

import { Component } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import * as localizationActions from "../../redux/actions/localizationActions";
import { withRouter } from "react-router-dom";

export class AutoLanguageSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            targetLanguage: this.getLocaleFromUser(),
        };
    }

    componentDidMount() {
        this.props.actions.loadTranslations();
        this.props.actions.selectDefaultLanguage(this.state.targetLanguage);
    }

    getLocaleFromUser = () => {
        if (this.props.currentUser && this.props.currentUser.attributes.locale) {
            const locale = Locales[this.props.currentUser.attributes.locale];
            return locale;
        }

        return localStorage.getItem("i18nextLng");
    };

    componentDidUpdate(prevProps, prevState) {
        const userLocale = this.getLocaleFromUser();
        if(userLocale !== this.state.targetLanguage) {
            this.setState({
                targetLanguage: userLocale
            });
        }

        if (this.state.targetLanguage !== prevState.targetLanguage) {
            if (!this.state.targetLanguage) {
                this.props.actions.selectDefaultLanguage();
            }
            else {
                this.props.actions.selectDefaultLanguage(this.state.targetLanguage);
            }
        }
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        currentUser: state.currentUser
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            selectDefaultLanguage: bindActionCreators(localizationActions.selectDefaultLanguage, dispatch),
            selectLanguage: bindActionCreators(localizationActions.selectLanguage, dispatch),
            loadTranslations: bindActionCreators(localizationActions.loadTranslations, dispatch),
        }
    };
};

export default compose(withTranslation("common"),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(withRouter(AutoLanguageSelector));