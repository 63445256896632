import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sessionActions from "../../redux/actions/sessionActions";
import * as localizationActions from "../../redux/actions/localizationActions";
import { withTranslation } from "react-i18next";
import * as userpermissions from "../../redux/actions/userPermissionsActions";
import * as users from "../../redux/actions/usersActions";

const tokenExpirationTimeSec = 60;
const renewExpirationTimeSec = 28800; // 8 h

export class LoginInitiator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loginInitiated: false,
            expired: false,
            expiresIn: 0
        };
    }

    componentDidMount() {
        this.interval = setInterval(() => this.updateExpirationState(), 15000);
        this.updateExpirationState();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    hasUserPropsChanged(prevProps) {
        const user = this.props.oidc.user;
        const prevUser = prevProps.oidc.user;
        return (!!user !== !!prevUser) ||
            (!!user && user.expires_at !== prevUser.expires_at);
    }

    updateExpirationState() {
        const expired = !!this.props.oidc.user && this.props.oidc.user.expired;
        const expiresIn = !!this.props.oidc.user ? this.props.oidc.user.expires_in : 0;
        this.setState({
            expired,
            expiresIn
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.hasUserPropsChanged(prevProps)) {
            if (this.props.oidc && this.props.oidc.user && this.props.oidc.user.access_token && (!prevProps.oidc.user || 
                prevProps.oidc.user.access_token !== this.props.oidc.user.access_token)) {
                this.props.actions.sessionActivated(this.props.oidc.user);
                this.props.actions.sessionAcessTokenActive(this.props.oidc.user.access_token);
            }

            this.updateExpirationState();
        }

        if (prevState.expiresIn < this.state.expiresIn) {
            this.setState(
                {
                    loginInitiated: false
                }
            );
        }

        if (!this.props.oidc.isLoadingUser && 
                (prevState.expiresIn !== this.state.expiresIn || !this.props.oidc.user)) {
            if (!this.props.oidc.user && !this.state.loginInitiated) {
                this.loginIfRequired();
            }
            else if ((this.state.expiresIn < tokenExpirationTimeSec && prevState.expiresIn > -renewExpirationTimeSec)
                || this.state.expired) {
                this.trySilentRenew();
            }
            else if(this.props.oidc.user !== null && prevProps.oidc.user && !this.state.loginInitiated){
                if(prevProps.oidc.user.profile.locale !== this.props.oidc.user.profile.locale){
                    this.props.actions.selectLanguage(this.props.oidc.user.profile.locale);
                }
            }
        }

        if (prevProps.isSessionActive !== this.props.isSessionActive || prevProps.tenant.id !== this.props.tenant.id) {
            this.props.actions.getCurrentUser(this.props.oidc.user.profile.sub)
                .then(() => {})
                .catch(() => {});
        }
    
        if (this.props.currentUser !== prevProps.currentUser || this.props.tenant !== prevProps.tenant) {
            this.props.actions.getUserPermissions(this.props.tenant.modules, this.props.currentUser.realmRoles);
        }
    }

    trySilentRenew() {
        if (!this.props.oidc.isLoadingUser) {
            if (!!this.props.oidc.user && this.state.expiresIn < tokenExpirationTimeSec) {
                this.setState(
                    {
                        loginInitiated: true
                    }
                );
            
                this.props.actions.silentRenew();
            }
        }
    }

    loginIfRequired() {
        if (!this.props.oidc.isLoadingUser && !this.state.loginInitiated) {
            if (!this.props.oidc.user || (this.props.oidc.user && this.props.oidc.user.expired)) {
                if (!this.props.network.offline) {
                    this.setState(
                        {
                            loginInitiated: true
                        }
                    );
                
                    this.props.actions.login();
                }
            }
        }
    }

    render() {
        return <div></div>;
    }
}

const mapStateToProps = (state) => {
    return {
        network: state.network,
        oidc: state.oidc,
        locale: state.locale,
        tenant: state.tenant,
        currentUser: state.currentUser,
        availableTenants: state.availableTenants,
        isSessionActive: state.isSessionActive,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            login: bindActionCreators(sessionActions.login, dispatch),
            silentRenew: bindActionCreators(sessionActions.silentRenew, dispatch),
            selectLanguage: bindActionCreators(localizationActions.selectLanguage, dispatch),
            getUserPermissions: bindActionCreators(userpermissions.getUserPermissions, dispatch),
            getCurrentUser: bindActionCreators(users.getCurrentUser, dispatch),
            sessionAcessTokenActive: bindActionCreators(sessionActions.sessionAcessTokenActive, dispatch),
            sessionActivated: bindActionCreators(sessionActions.sessionActivated, dispatch),
        }
    };
};

export default compose(withTranslation("common"),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(LoginInitiator);
