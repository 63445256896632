import { store } from "../redux/store";
import axios from "axios";
import userManager from "../services/userManager";
import * as sessionActions from "../redux/actions/sessionActions";
import { loadUser } from "redux-oidc";

const fallbBackResilent = 40;
const retryAfter = 30;

axios.interceptors.response.use(async(response) => {
    if (store.getState().oidc.user && store.getState().oidc.user.expires_in < fallbBackResilent) {
        await userManager.signinSilent().then((user) => {
            loadUser(store, userManager);
            store.dispatch(sessionActions.sessionAcessTokenActive(user.access_token));
            response.headers["Authorization"] = `Bearer ${user.access_token}`;
        });
    }

    return response;
}, (error) => {
    /*if (error.response && error.response.status === 429) {
        console.log("===> 429");
        console.log(error.response.headers);
        console.log("===> " + error.response.headers["Retry-After"]);
    }*/
  
    if (error.response && error.response.status === 429 && error.response.headers["Retry-After"] && error.response.headers["Retry-After"] <= retryAfter) {
        return new Promise((resolve) => {
            setTimeout(() => {
                axios.request(error.config).then(resolve);
            }, error.response.headers["retry-after"] );
        });
    }
    else {
        return Promise.reject(error);
    }
});

export default function getRequestOptions(cancelToken, ignoreTenantHeader = false) {
    var options = {
        headers: {
            "Content-Type": "application/json"
        },
        crossdomain: true,
        cancelToken
    };

    const state = store.getState();
    var tenantId = state.tenant.id;
    if (tenantId && !ignoreTenantHeader) {
        options.headers["up-tenant-id"] = tenantId;
    }

    if (store && state.oidc.user) {
        const token = state.accessToken;
        options.headers["Authorization"] = `Bearer ${token}`;
    }
    

    return options;
}