export default class ModalHelper {
    static newGuid() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
            var r = Math.random() * 16 | 0, v = c === "x" ? r : ((r & 0x3) | 0x8);
            return v.toString(16);
        });
    }

    static openModal(modalGuid, history, replace) {
        const newState = {
            ...history.location.state,
            [modalGuid]: true
        };

        if (!!replace) {
            history.replace(history.location.pathname, newState);
        }
        else {
            history.push(history.location.pathname, newState);
        }
    }

    static closeModal(modalGuid, history) {
        history.goBack();
    }

    static isOpen(modalGuid, history) {
        return !!history && !!history.location && !!history.location.state && 
            !!history.location.state[modalGuid];
    }
}