import React, {Fragment} from "react";
import * as styles from "../common/styles";
import CircleButton from "../common/CircleButton";
import {Typography, Grid, Divider, Card, IconButton} from "@material-ui/core";

export class BusinessCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    shortName = (title) => {
        if (title) {
            var name = "";
            var names = title.trim().split(" ");
            if (names.length > 0) {
                name = names[0].substring(0, 1);
                if (names.length > 1) {
                    name += names[1].substring(0, 1);;
                }
            }
        } 

        return name;
    }

    renderCard = () => {
        const {displayName, displayPhoneNumber, displayEmail} = this.props;
        return (
            <Card style={{
                maxWidth: 360,
                margin: "10px auto", 
                boxShadow: " rgb(61 71 82 / 20%) 0px 4px 20px",
                backgroundColor: "rgb(255, 255, 255)",
                border: "1px solid rgb(229, 232, 236)"}}>
                <Grid container style={{padding: "8px 2px"}}>
                    <Grid item xs={2}>
                        <CircleButton
                            name={displayName || this.props.t("HiddenName")}
                            key={"userCircle"}
                            toolTipMessage={displayName}
                            color={"default"}
                            style={{...styles.userAvatar(false), margin: 8}}
                            iconButton={<Fragment>{this.shortName(displayName || this.props.t("HiddenName"))}</Fragment>}>
                        </CircleButton>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container alignItems="center" justifyContent="space-around">
                            <Typography style={{fontWeight: 600}} fontWeight={600}>{displayName || this.props.t("HiddenName")}</Typography>
                        </Grid>
                    </Grid>
                    {this.props.action &&
                    <Grid item xs={2}>
                        <IconButton onClick={() => this.props.action()}>
                            {this.props.actionIcon}
                        </IconButton>
                    </Grid>
                    }
                </Grid>
                <Divider />
                <Grid container
                    direction="column"
                    alignItems="flex-start"
                    justifyContent="space-between"
                    style={{ padding: "14px 20px", backgroundColor: "rgb(243, 246, 249)"}}>
                    <Typography style={{fontSize: 16}} >{displayEmail }</Typography>
                    <Typography style={{fontSize: 16}} >{displayPhoneNumber}</Typography>
                </Grid>
            </Card>
        );
    }

    render() {
        return (
            this.renderCard()
        );
    }
}

export default (BusinessCard);