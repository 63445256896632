
import React, { Component, Fragment } from "react";
import {
    IconButton,
    Tooltip,
    Grid,
    CardHeader
} from "@material-ui/core";
import "../../index.css";
import ArrowBack from "@material-ui/icons/ArrowBack";
import logoLight from "../Navbar/logo_light.png";
export class ModalHeader extends Component {
    render() {
        const { backButton, tooltip, clickAction, color, title, fullScreen, backgroundColor, includeOnlyContent, showLogo } = this.props;
        return (
            <Fragment>
                <Grid container alignItems="center" 
                    style={includeOnlyContent ? {
                        paddingTop: 12,
                        paddingBottom: 12,
                        position: "sticky", 
                        zIndex: 100, 
                        top: 0,
                        backgroundColor: backgroundColor ? backgroundColor : "white"} : {}}
                >
                    {backButton &&
                        <Grid item xs={1} style={{display: "flex"}}>
                            <Tooltip placement='bottom' title={tooltip ? tooltip : ""}>
                                <IconButton data-testid="backId" onClick={clickAction}>
                                    <ArrowBack style={{color: color ? color : "black"}}/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    }
                    <Grid item xs={backButton ? 10 : 12} 
                        style={{
                            fontSize: "120%",
                            marginTop: fullScreen ? 0 : 15,
                            marginBottom: fullScreen ? 0 : 15,
                            textAlign: "center",
                            fontFamily: "Open Sans"
                        }}
                    >
                        {fullScreen ? (
                            <CardHeader title={title} style={{padding: "0 !Important" }}/>
                        ) : (
                            <>
                                {showLogo ? <img alt="Puskee™" width="150" height="41" src={logoLight.toString()} /> : ""}
                                {title ? title : ""}
                            </>
                        )}
                    </Grid>
                </Grid>
            </Fragment>
        );
    };
}

export default (ModalHeader);

