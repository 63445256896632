import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function serviceWorkerReducer(state = initialState.serviceWorker, action) {
    if (action.type === types.SERVICE_WORKER_UPDATE_WAITING) {
        return {
            ...state,
            waitingUpdate: true
        };
    }
    else {
        return state;
    }
}
