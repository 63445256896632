import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function NotificationSettingsReducer(state = initialState.notificationSettings, action) {
    if (action.type === types.READ_NOTIFICATIONSETTINGS_SUCCESS) {
        return action.settings;
    }
    else if (action.type === types.UPDATE_NOTIFICATIONSETTINGS_SUCCESS) {
        return action.settings;
    }
    else {
        return state;
    }
}