import React, { Component } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Slide,
    Fade
} from "@mui/material";
import "../../index.css";
import LoadingButton from "@mui/lab/LoadingButton";
import { withTranslation } from "react-i18next";
import { ModalHeader } from "./modalHeader";
import * as styles from "../common/styles";
import Backdrop from "@material-ui/core/Backdrop";

export class DialogModal extends Component {
    Transition = React.forwardRef((props, ref) => {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    baseTransition = React.forwardRef((props, ref) => {
        return <Fade number="0" ref={ref} {...props} />;
    });

    render() {
        const { t, id, backButton, tooltip, questionDialog, open, cancelLabel, okLabel, hideCloseButton, backgroundColor,
            handleReply, content, notActions, noHeader, fullWidth, maxWidth, scroll, title, clickAction, PaperProps,
            isOkDisabled, textAlign, onClose, transition, fullScreen, disableScrollLock, includeOnlyContent, isTopBarSticky, showLogo,
            bkgCloseBtn, colorCloseBtn, okButtonIcon, bkgOkBtn, colorOkBtn, hideOkButton, isLoading, forceFocus, dialogContentStyleOverride} = this.props;
        return (
            <Dialog 
                disableEnforceFocus={forceFocus ? forceFocus : false}
                id={id}
                open={open}
                onClose={onClose}
                BackdropComponent={Backdrop}
                fullWidth={fullWidth} 
                maxWidth={maxWidth}
                scroll={scroll}
                disableScrollLock={disableScrollLock}
                TransitionComponent={transition ? this.Transition : this.baseTransition}
                fullScreen={fullScreen}
                PaperProps={PaperProps}
            >
                {isTopBarSticky ? (
                    content()
                ) : (
                    <div style={{textAlign: textAlign ? textAlign : "center", background: backgroundColor ? backgroundColor : "white", height: "100%"}}>
                        {!noHeader &&
                            <ModalHeader 
                                includeOnlyContent={includeOnlyContent}
                                showLogo={showLogo}
                                fullScreen={fullScreen}
                                backButton={backButton} 
                                tooltip={tooltip ? t(tooltip) : t("Back")} 
                                title={t(questionDialog ? questionDialog : title ? title : "")} 
                                clickAction={clickAction}/>
                        }
                        {content &&
                            <>
                                {includeOnlyContent ? (
                                    content()
                                ) : (
                                    <DialogContent style={fullScreen ? {paddingTop: 0, paddingBottom: 0, ...dialogContentStyleOverride} 
                                        : {...dialogContentStyleOverride}}>
                                        {content()}
                                    </DialogContent>
                    
                                ) }
                            </> 
                        }
                        {!notActions && 
                            <DialogActions style={{ justifyContent: "center", marginBottom: 10 }}>
                                {!hideCloseButton &&
                                    <Button onClick={() => handleReply(false)}  
                                        style={{...styles.cancelButton(bkgCloseBtn, colorCloseBtn), marginRight: 16}}>
                                        {t(cancelLabel ? cancelLabel : "Cancel")}
                                    </Button>
                                }
                                {!hideOkButton && (
                                    <LoadingButton
                                        onClick={() => handleReply(true)}
                                        endIcon={okButtonIcon}
                                        id="okButton" 
                                        disabled={isOkDisabled}
                                        loading={isLoading}
                                        variant="contained"
                                        style={styles.saveButton(bkgOkBtn, colorOkBtn, isLoading || isOkDisabled)}
                                    >
                                        {t(!!isLoading ? "" : okLabel ? okLabel : "Delete")}
                                    </LoadingButton>
                                )
                                }
                            </DialogActions>
                        }
                    </div>
                )}
            </Dialog>
        );
    };
}

export default withTranslation("common")(DialogModal);
