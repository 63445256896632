import * as types from "./actionTypes";
import * as chatChannelApi from "../../api/chatChannelApi";
import * as notificationActions from "./notificationsActions";
import * as constants from "../../components/common/constants";
import * as historyEntryActions from "./historyEntryActions";
import * as chatMessagesActions from "./chatMessagesActions";
import { store } from "../store";

export const readChatChannelSuccess = (chatChannel) => {
    return { type: types.READ_CHAT_CHANNEL_SUCCESS, chatChannel };
};

export const readChatChannel = (id, sharedToken, cancelToken) => {
    return function(dispatch) {
        return chatChannelApi
            .ReadChannel(id, sharedToken, cancelToken)  
            .then((result) => {
                dispatch(readChatChannelSuccess(result));
                return result;
            })
            .catch((error) => {
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    if(error.message !== "Not Found" && error.message !== "No Authorized"){
                        dispatch(notificationActions.toastMessage("ErrorLoadingChannel", "error"));
                    }

                    if(error.message === "No Authorized"){
                        throw new Error("No Authorized");
                    }
                    
                    throw new Error(error);
                }
            });
    };
}; 

export const updateChatChannelSuccess = (chatChannel) => {
    return { type: types.UPDATE_CHAT_CHANNEL_SUCCESS, chatChannel };
};

export const updateChatChannel = (model, added, deleted, sharedToken) => {
    return function(dispatch) {
        return chatChannelApi
            .updateChannel(model, added, deleted, sharedToken)  
            .then((result) => {
                dispatch(updateChatChannelSuccess(result));
                dispatch(historyEntryActions.updateHistoryEntry(model, constants.collections.channel));
                if (!!added && added.length > 0 && added[0].Id !== store.getState().oidc.user.profile.sub) {
                    model.membersAdded = added;
                    dispatch(historyEntryActions.updateHistoryEntry(model, constants.collections.channelMembers));
                }

                return result;
            })
            .catch((error) => {
                if (!error.toString().includes("PreconditionFailed")) {
                    dispatch(notificationActions.toastMessage("ErrorUpdatingChannel", "error"));
                }
                
                throw new Error(error);
            });
    };
}; 

export const incrementTotalNumberOfMessages = (channelId) => {
    return { type: types.INCREMENT_TOTAL_NUMBER_OF_MESSAGES, channelId };
};

export const SetMessagesCounter = (channelId, sharedToken ) => {
    return function(dispatch) {
        return chatChannelApi
            .SetMessagesCounter(channelId, sharedToken)  
            .then((result) => {
            })
            .catch((error) => {
                if (!error.toString().includes("PreconditionFailed")) {
                    dispatch(notificationActions.toastMessage("ErrorUpdatingChannelCounter", "error"));
                }
                
                throw new Error(error);
            });
    };
}; 

export const GetMessagesCounter = (userId, cancelToken) => {
    return function(dispatch) {
        return chatChannelApi
            .GetMessagesCounter(userId, cancelToken)  
            .then((result) => {
                dispatch(chatMessagesActions.readChatUnReadMessagesCounterSuccess(result));
            })
            .catch((error) => {
                if (!error.toString().includes("PreconditionFailed")) {
                    dispatch(notificationActions.toastMessage("ErrorLoadingChannelCounter", "error"));
                }
                
                throw new Error(error);
            });
    };
}; 

export const GetMessagesCounterByTenant = (cancelToken) => {
    return function(dispatch) {
        return chatChannelApi
            .GetMessagesCounterByTenant(cancelToken)  
            .then((result) => {
                return result;
            })
            .catch((error) => {
                if (!error.toString().includes("PreconditionFailed")) {
                    dispatch(notificationActions.toastMessage("ErrorLoadingChannelCounterbyTenant", "error"));
                }
                
                throw new Error(error);
            });
    };
}; 


