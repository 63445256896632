import * as types from "./actionTypes";
import * as rolesApi from "../../api/rolesApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";
import * as constants from "../../components/common/constants";
import defaultRoles from "../../utils/roles.json";

// only test
//import intialState from "../reducers/initialState";
//import RolesTest from "../../utils/rolesTest.json";

export const getUserPermissionsSuccess = (userPermissions) => {
    return { type: types.READ_USER_PERMISSIONS_SUCCESS, userPermissions };
};

export const getUserPermissions = (tenantModules, realmRoles, cancelToken) => {
    return async function(dispatch) {
        dispatch(beginApiCall());
       
        if (!!realmRoles && realmRoles.length > 0) {
            var batch = {
                batch: realmRoles
            };
            await rolesApi
                .ReadRolesBatch(batch, cancelToken)  
                .then((roles) => {
                    let defaultPermissions = JSON.parse(JSON.stringify(defaultRoles));
                    const userPermissions = combinePermissions(defaultPermissions, tenantModules, roles);
                    
                    dispatch(getUserPermissionsSuccess(userPermissions));
                })
                .catch((error) => {
                    dispatch(apiCallError(error));
                    if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                    !error.message.includes(constants.cancelHttpRequest)) {
                        dispatch(notifications.toastMessage("ErrorloadingUserPermissions", "error"));
                        throw new Error(error);
                    }
                });
        }
    };
};

const combinePermissions = (defaultPermissions, tenantModules, userRoles) => {
    var combined = defaultPermissions;
    var permissions = userRoles;

    // combine default permissions with the permissions determined by user roles
    Object.keys(permissions).forEach((roleName) => {
        Object.keys(permissions[roleName]).forEach((module) => {
            if (!combined.hasOwnProperty(module)) {
                combined[module] = permissions[roleName][module];
            }
            else {
                Object.keys(permissions[roleName][module]).forEach((permission) => {
                    if (combined[module].hasOwnProperty(permission)) {
                        if (permissions[roleName][module][permission]) {
                            combined[module][permission] = permissions[roleName][module][permission]; 
                        }
                    }
                    else {
                        combined[module][permission] = permissions[roleName][module][permission];
                    }
                });
            }
        });        
    });

    // Disable those user permissions that are not enabled on the tenant
    Object.keys(combined).forEach((moduleName) => {
        if (!tenantModules.includes(moduleName)) {
            combined[moduleName] = {};
        }
    });

    return combined;
};

