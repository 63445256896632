import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function realTimeConnectionSignalReducer(state = initialState.isConnectionSignalActive, action) {
    if (action.type === types.RALTIME_CONNECTION_SIGNAL_ACTIVE) {
        return action.isConnectionSignalActive;
    }
    else if (action.type === types.RALTIME_CONNECTION_LOST) {
        return false;
    }
    else {
        return state;
    }
}
