import React, {useEffect } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import { amber, green, brown, blue} from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CodeIcon from "@material-ui/icons/Code";

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
    debug: CodeIcon
};

const useStyles1 = makeStyles((theme) => ({
    root: { 
        minWidth: 200,
        backgroundColor: ({ color }) => color
    },
    icon: {
        marginBottom: -6,
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        alignItems: "center",
        verticalAlign: "middle",
        flex: 6,
    },
    close: {
        flex: 1,
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
        borderRadius: "5px"
    },
    action: {
        flex: 1
    }
}));

export default function SnackbarContentWrapper(props) {
    const { message, variant, lifetimeMs } = props;  
    const colorMap = {
        success: green[500],
        warning: amber[700],
        debug: brown[700],
        error: "#d32f2f",
        info: blue[700],
    };
    const classes = useStyles1({color: colorMap[variant]});
    const Icon = variantIcon[variant];

    useEffect(() => {
        setTimeout(() => {
            handleClose();
        }, !!lifetimeMs ? lifetimeMs : 5000);
    });

  
    const handleClose = () => {
        props.onClose();
    };


    return (
        <SnackbarContent
            classes={{action: classes.action, message: classes.message, root: classes.root }}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" >
                    <Icon className={clsx(classes.icon, classes.iconVariant)} />
                    {message}
                </span>
            }
            action={[
                <IconButton className={clsx(classes.action)} key="close" aria-label="close" color="inherit" onClick={handleClose}>
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
        />
    );
}

