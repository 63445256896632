import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function catalogPaymentTypeReducer(state = initialState.catalogPaymentType, action) {
    if (action.type === types.READ_CATALOG_PAYMENT_TYPE_SUCCESS) {
        return action.catalogPaymentType;
    }
    else {
        return state;
    }
}
