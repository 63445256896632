import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function timeSheetReducer(state = initialState.timeSheet, action) {
    if (action.type === types.READ_TIMESHEET_SUCCESS) {
        return action.timeSheet;
    }
    else {
        return state;
    }
}
