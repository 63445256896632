import React, { Fragment } from "react";
import Layout from "./components/layout";
import Routes from "./routes/routes";
import ServiceWorkerTracker from "./components/serviceWorkerTracker";

// TODO separate theming to a component of it's own
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import AutoLanguageSelector from "./components/localization/autoLanguageSelector";
import OfflineDetector from "./components/offlineDetector";
import { store } from "./redux/store";
import * as blockScrollActions from "./redux/actions/blockScrollActions";

const getSelectedText = () => {
    var text = "";
    if (typeof window.getSelection !== "undefined") {
        text = window.getSelection().toString();
    }
    else if (typeof document.selection !== "undefined" && document.selection.type === "Text") {
        text = document.selection.createRange().text;
    }

    return text;
};

const doSomethingWithSelectedText = () => {
    var selectedText = getSelectedText();
    if (selectedText) {
        store.dispatch(blockScrollActions.blockScrollSuccess(true));
    }
    else {
        store.dispatch(blockScrollActions.blockScrollSuccess(false));
    }
};

document.ontouchstart = doSomethingWithSelectedText;
document.ontouchend = doSomethingWithSelectedText;


const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 800,
            lg: 1280,
            xl: 1920,
        },
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        body1: {
            fontSize: "1.1rem"
        },
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Open Sans"',
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "1rem",
                textAlign: "center"
            }
        }
    },
    palette: {
        primary: {
            light: "#e44c52",
            main: "#de2027",
            dark: "#87103f",
            contrastText: "#fff",
        },
        secondary: {
            light: "#bebed7",
            main: "#aeaece",
            dark: "#797990",
            contrastText: "#000",
        },
        disabled: {
            light: "#DBDBDB",
            main: "#DBDBDB",
            dark: "#DBDBDB",
            contrastText: "#ADADAD",
        }

    },
});

export class App extends React.Component {
    render = () => {
        return (
            <Fragment>
                <MuiThemeProvider theme={theme}>
                    <Layout>
                        <Routes />
                    </Layout>
                    <ServiceWorkerTracker />
                    <AutoLanguageSelector />
                    <OfflineDetector />
                </MuiThemeProvider>
            </Fragment>
        );
    }
}

export default App;