import * as types from "./actionTypes";
import * as tenantApi from "../../api/tenantApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notificationActions from "./notificationsActions";
import * as constants from "../../components/common/constants";
import { store } from "../store";
import { parseJwt } from "../../helpers/jwtHelpers";

export const readTenantSuccess = (tenant) => {
    return { type: types.READ_TENANT_SUCCESS, tenant };
};

export const readTenantAccessDenied = (tenantId) => {
    return { type: types.READ_TENANT_ACCESS_DENIED, tenantId };
};

export const getAllAvailableTenantsSuccess = (tenants) => {
    return { type: types.GET_ALL_AVAILABLE_TENANTS_SUCCESS, tenants };
};

export const getCachedTenant = () => {
    const cachedTenantStr = localStorage.getItem("tenant");
    if (cachedTenantStr) {
        const tenant = JSON.parse(cachedTenantStr);
        tenant.fromCache = true;
        return tenant;
    }

    return null;
};

export const readTenant = (tenantId, cancelToken, isNewUser) => {
    return function(dispatch) {
        const token = parseJwt(store.getState().accessToken);
        if ((!token || !token.groups || token.groups.length === 0) && !isNewUser) {
            return Promise.resolve();
        }
       
        // 1. Make loading page faster by first loading a cached tenant
        const cachedTenant = getCachedTenant();
        if (cachedTenant) {
            if (cachedTenant.id === tenantId) {
                dispatch(readTenantSuccess(cachedTenant));
            }
        }
        
        // 2. Read tenant from the server to update its changes
        dispatch(beginApiCall());
        return tenantApi
            .readTenant(tenantId, cancelToken)
            .then((tenant) => {
                localStorage.setItem("tenant", JSON.stringify(tenant));
                dispatch(readTenantSuccess(tenant));
            })
            .catch((error) => {
                if (!!error.startsWith && error.startsWith("Access")) {
                    dispatch(readTenantAccessDenied(tenantId));
                }

                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notificationActions.toastMessage("ErrorGettingTenantId", "error"));
                }
            });
    };
};
 
export const getAllAvailableTenants = (filter, sort, page, cancelToken, tags) => {
    return function(dispatch) {
        dispatch(beginApiCall());
        return tenantApi
            .getAllAvailableTenants(filter, sort, page, cancelToken, tags)
            .then((tenants) => {
                dispatch(getAllAvailableTenantsSuccess(tenants));
                return tenants;
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                    !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notificationActions.toastMessage("ErrorGettingTenants", "error"));
                }
            });
    };
}; 


export const selectTenantSuccess = (tenantId) => {
    return function(dispatch) {
        const action = { type: types.SELECT_TENANT_SUCCESS, tenantId };
        dispatch(action);
    };
};

export const selectTenant = (tenantId) => {
    return function (dispatch) {
        if (!!tenantId) {
            dispatch(readTenant(tenantId));
            dispatch(selectTenantSuccess(tenantId));
        }
    };
};

export const selectTenantByName = (tenantName, availableTenants) => {
    return function (dispatch) {
        if (!!tenantName) {
            // 1. Make loading page faster by first loading a cached tenant
            const cachedTenant = getCachedTenant();
            if (cachedTenant) {
                if (cachedTenant.name === tenantName) {
                    dispatch(readTenantSuccess(cachedTenant));
                }
            }

            // 2. Look if there is a tenant with that name available
            if (availableTenants.length > 0) {
                const availableTenant = availableTenants.find((tenant) => tenant.name === tenantName);
                if (availableTenant) {
                    dispatch(selectTenant(availableTenant.id));
                }
            }
        }
    };
}; 

export const selectDefaultTenant = (availableTenants) => {
    return function (dispatch) {
        if (!availableTenants || availableTenants.length === 0) {
            dispatch(notificationActions.toastMessage("ErrorGettingTenants", "error"));
            return;
        }

        // Select the previously selected tenant if set in localstorage
        var tenantId = localStorage.getItem("tenant_id" + store.getState().oidc.user.profile.sub);
        if (!tenantId) {
            // Fist available tenant is selected as the default tenant
            tenantId = availableTenants[0].id;
        }
        else if (!!tenantId && 
            !availableTenants.find((tenant) => tenant.id === tenantId)) {
            // If the previously selected tenant is not available any more
            // select the first available tenant.
            tenantId = availableTenants[0].id;
        }

        dispatch(readTenant(tenantId));
    };
};

export const createTenantSuccess = (tenant) => {
    return { type: types.UPDATE_TENANT_SUCCESS, tenant };
};

export const createTenant = (tenant) => {
    return function(dispatch) {
        dispatch(beginApiCall());
        return tenantApi
            .createtenant(tenant)  
            .then((result) => {
                dispatch(createTenantSuccess(result));
                return result;
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" &&
                error.message !== constants.cancelHttpRequest) {
                    dispatch(notificationActions.toastMessage("ErrorCreatingTenant", "error"));
                }
                else if (typeof(error.message) === "undefined") {
                    dispatch(notificationActions.toastMessage("ErrorUpdatingTenant", "error"));
                }

                throw new Error(error);
            });
    };
}; 

export const updateTenantSuccess = (tenant) => {
    return { type: types.UPDATE_TENANT_SUCCESS, tenant };
};

export const updateTenant = (id, tenant) => {
    return async function(dispatch) {
        dispatch(beginApiCall());
        
        await tenantApi
            .updatetenant(id, tenant)  
            .then((result) => {
                dispatch(updateTenantSuccess(result));
                return result;
            })
            .catch((error) => {
                if (error.message && error.message.toLowerCase().includes("preconditionfailed")) {
                    return constants.PreconditionFailed;   
                }
                else if (!error.toString().includes("PreconditionFailed")) {
                    dispatch(notificationActions.toastMessage("ErrorupdatingProductUnit", "error"));
                }

                throw new Error(error);
            });
    };
}; 

export const deleteTenantSuccess = (tenant) => {
    return { type: types.UPDATE_TENANT_SUCCESS, tenant };
};

export const deleteTenant = (id) => {
    return function(dispatch) {
        dispatch(beginApiCall());
        return tenantApi
            .deletetenant(id)  
            .then((result) => {
                dispatch(updateTenantSuccess(result));
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" &&
                error.message !== constants.cancelHttpRequest) {
                    dispatch(notificationActions.toastMessage("ErrorDeletingTenant", "error"));
                }

                throw new Error(error);
            });
    };
};

export const readTenantEditableSuccess = (tenant) => {
    return { type: types.READ_TENANT_EDITABLE_SUCCESS, tenant };
};

export const readTenantEditabled = (tenantId, cancelToken) => {
    return function(dispatch) {
        dispatch(beginApiCall());
        return tenantApi
            .readTenant(tenantId, cancelToken)
            .then((tenant) => {
                dispatch(readTenantEditableSuccess(tenant));
                return tenant;
            })
            .catch((error) => {
                if (error.startsWith("Access")) {
                    dispatch(readTenantAccessDenied(tenantId));
                }
                else if (!error.toString().includes("PreconditionFailed")) {
                    dispatch(notificationActions.toastMessage("ErrorGettingTenantId", "error"));
                }
                
                throw error;
            });
    };
};
