import React from "react";
import { withTranslation } from "react-i18next";
import {DialogContentText } from "@material-ui/core";
import { DialogModal } from "./common/dialogModal";

export class ErrorModal extends React.Component {
    constructor(props){
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }
   
    reloadApp = () => {
        window.location.href = "/";
    }

    renderContent = () => {
        const { t } = this.props;
        return (
            <DialogContentText>{t("UnexpectedErrorDescription")}</DialogContentText>
        );
    }

    render() {
        const { t } = this.props;
        return(
            <DialogModal
                id="ErrorModal"
                fullWidth={true} 
                maxWidth={"sm"}
                scroll={"body"}
                open={this.props.open !== false}
                title={"ErrorModalTitle"}
                t={t}
                content={this.renderContent}
                okLabel={"ContinueUsing"}
                hideCloseButton={true}
                handleReply={this.reloadApp}
            />
        );
    }
}
export default withTranslation("common")(ErrorModal);
