import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from "./authorizationUtils";
import axios from "axios";
import * as constants from "../components/common/constants";
import FilterHelper from "../helpers/filterHelper"; 

// azure functions can not handle ambigous routes
// we need explicit defined those

export const ReadWorkSites = (filter, sort, page, customerId, tags, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    if (customerId === "") {
        customerId = 0;
    }
   
    var data = {
        filter: filter,
        sort: sort,
        page: page,
        customerId: customerId,
        searchFields: FilterHelper.getSearchfilter(constants.workSiteFilter),
        filterTags: tags
    };
 
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/WorkSite/GetAll`, data, options)
        .then(handleResponse)
        .catch(handleError);
};

export const ReadWorkSite = (id, cancelToken, sharedToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.default) + `/api/Aggregate/WorkSite/Get/${id}?sharedToken=${sharedToken}`, options)
        .then(handleResponse)
        .catch(handleError);
};

export const updateWorkSite = (model) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/WorkSite/POST`, model, options)
        .then(handleResponse)
        .catch(handleError);
};


export const ReadWorkSiteBatch = (batch, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/WorkSite/GetBatch`, batch, options)
        .then(handleResponse)
        .catch(handleError);
};

export const getSitesMass = (cancelToken) => {
    const options = getRequestOptions(cancelToken);
  
    return axios.get(getApiUrl(serviceKeys.default) + `/api/Aggregate/WorkSite/GetMass`, options)
        .then(handleResponse)
        .catch(handleError);
};


