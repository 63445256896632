import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function chatChannelReducer(state = initialState.chatChannel, action) {
    if (action.type === types.READ_CHAT_CHANNEL_SUCCESS) {
        return action.chatChannel;
    }
    else if (action.type === types.INCREMENT_TOTAL_NUMBER_OF_MESSAGES) {
        if (state.id === action.channelId) {
            return {
                ...state,
                totalNumberOfMessages: state.totalNumberOfMessages + 1
            };
        }
        else {
            return state;
        }
    }
    else {
        return state;
    }
}