import * as blobsApi from "../../api/blobsApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notificationActions from "./notificationsActions";
import { BlobServiceClient } from "@azure/storage-blob";
import * as constants from "../../components/common/constants";
import * as workOrderDiaryEntryActions from "./workOrderDiaryEntryActions";
import * as workOrderTemplateActions from "./workOrderTemplateActions";
import * as chatMessageActions from "./chatMessagesActions";
import * as types from "./actionTypes";
import { store } from "../store";

export const updateBlobProgress = (blobProgress) => {
    return { type: types.UPDATE_BLOB_PROGRESS, blobProgress };
};

export const updateBlobsProgress = (blobsProgress) => {
    return { type: types.UPDATE_BLOBS_PROGRESS, blobsProgress };
};

export const setBlobsProgress = (blobsProgress) => {
    return { type: types.SET_BLOBS_PROGRESS, blobsProgress };
};

export const abortBlobsProgress = (blobsAbortProgress) => {
    return { type: types.ABORT_BLOBS_PROGRESS, blobsAbortProgress };
};


export const writeSharedBlob = (blobName, permission, blob, abortSignal, showSuccess = true, idx = -1, sharedToken) => {
    return async function(dispatch) {
        dispatch(beginApiCall());

        var limit = 0; 
        if (store.getState().tenant && store.getState().tenant.licenseInfo) {
            limit = store.getState().tenant.licenseInfo.maxFileStorageBytes;
        }

        var token = undefined;
        await blobsApi
            .GetSASToken(blobName, permission, blob ? blob.size : 0, limit, null, sharedToken)  
            .then((result) => { 
                token = result; 
            })
            .catch((error) => {
                if (error.message !== "Upgrade") {
                    dispatch(notificationActions.toastMessage("ErrorGettingBlobToken", "error"));
                }

                throw new Error(error.message ? error.message : error);
            });

        if (token) {
            var blobServiceClient = new BlobServiceClient(token.uri);
                
            if (token.permission === constants.blobPermissions.Read) {
                const readPromise = Promise.resolve(token.uri);
                return readPromise
                    .then((result) => {
                        return result;
                    })
                    .catch((error) => {
                        dispatch(apiCallError(error));
                        dispatch(notificationActions.toastMessage("ErrorReadingBlob", "error"));
                        throw new Error(error);
                    });
            }
            else if (token.permission === constants.blobPermissions.Write && blob) {
                await uploadFile(blobServiceClient, token, blob, dispatch, abortSignal, showSuccess, idx);
            }
            else if (token.permission === constants.blobPermissions.Delete) {
                let toastMessage = blob && blob.notAToastMessage ? false : true;
                await deleteFile(blobServiceClient, token, dispatch, showSuccess, toastMessage);
            }  
        }

        return true;
    };
};


export const ReadSharedBlob = (blobName, shareKey) => {
    return async function(dispatch) {
        dispatch(beginApiCall());

        var token = undefined;
        await blobsApi
            .GetSharedReadonlySASToken(blobName, shareKey)  
            .then((result) => { 
                token = result; 
            })
            .catch((error) => {
                throw new Error(error.message ? error.message : error);
            });

        if (token) {
            if (token.permission === constants.blobPermissions.Read) {
                const readPromise = Promise.resolve(token.uri);
                return readPromise
                    .then((result) => {
                        return result;
                    })
                    .catch((error) => {
                        dispatch(apiCallError(error));
                        dispatch(notificationActions.toastMessage("ErrorReadingBlob", "error"));
                        throw new Error(error);
                    });
            }
        }

        return true;
    };
};

export const Blobs = (blobName, permission, blob, abortSignal, showSuccess = true, idx = -1, sharedToken = "") => {
    return async function(dispatch) {
        dispatch(beginApiCall());
        var limit = 0; 
        if (store.getState().tenant && store.getState().tenant.licenseInfo) {
            limit = store.getState().tenant.licenseInfo.maxFileStorageBytes;
        }

        var token = undefined;
        await blobsApi
            .GetSASToken(blobName, permission, blob ? blob.size : 0, limit, null, sharedToken)  
            .then((result) => { 
                token = result; 
            })
            .catch((error) => {
                if (error.message !== "Upgrade") {
                    dispatch(notificationActions.toastMessage("ErrorGettingBlobToken", "error"));
                }

                throw new Error(error.message ? error.message : error);
            });

        if (token) {
            var blobServiceClient = new BlobServiceClient(token.uri);
            
            if (token.permission === constants.blobPermissions.Read) {
                const readPromise = Promise.resolve(token.uri);
                return readPromise
                    .then((result) => {
                        return result;
                    })
                    .catch((error) => {
                        dispatch(apiCallError(error));
                        dispatch(notificationActions.toastMessage("ErrorReadingBlob", "error"));
                        throw new Error(error);
                    });
            }
            else if (token.permission === constants.blobPermissions.Write && blob) {
                await uploadFile(blobServiceClient, token, blob, dispatch, abortSignal, showSuccess, idx);
            }
            else if (token.permission === constants.blobPermissions.Delete) {
                let toastMessage = blob && blob.notAToastMessage ? false : true;
                await deleteFile(blobServiceClient, token, dispatch, showSuccess, toastMessage);
            }  
        }

        return true;
    };
}; 

const uploadFile = async(blobServiceClient, token, blob, dispatch, abortSignal, showSuccess, idx = -1) => {
    const containerClient = blobServiceClient.getContainerClient(token.container);
    const blockBlobClient = containerClient.getBlockBlobClient(token.blobName);
    await blockBlobClient.upload(blob, blob.size, { onProgress: (ev) => {
        if (idx === -1) {
            dispatch(updateBlobProgress({progress: ev.loadedBytes, size: blob.size}));
        }
        else {
            dispatch(updateBlobsProgress({progress: ev.loadedBytes, size: blob.size, idx: idx}));
        }
    },
    blobHTTPHeaders: {blobContentType: blob.type},
    abortSignal
    })
        .then((result) => {
            dispatch(updateBlobProgress({progress: 0, size: 0}));
          
            if(!!showSuccess){
                dispatch(notificationActions.toastMessage("BlobOperationSuccessfull", "success"));
            }
        })
        .catch((error) => {
            if (error.name === "AbortError") {
                dispatch(notificationActions.toastMessage("BlobUploadCanceled", "error"));
            }
            else{
                dispatch(apiCallError(error));
                dispatch(notificationActions.toastMessage("ErrorWritingBlob", "error"));
            }

            throw new Error(error);
        });
};

export const safeDelete = (documentId, workOrderId, workBoardId) => {
    // check that document id has no references.
    
    return async function(dispatch) {
        var stopDelete = false;

        stopDelete = await dispatch(workOrderDiaryEntryActions.readWorkOrderDiaryEntryByDocumentId(documentId));
        stopDelete = stopDelete || await dispatch(workOrderTemplateActions.documentExistsInTemplates(workBoardId, documentId));
        if (workOrderId !== undefined) {
            stopDelete = stopDelete || await dispatch(chatMessageActions.documentExistsInMessages(workOrderId, documentId));
        }
        
        return stopDelete;
    };
};

const deleteFile = async(blobServiceClient, token, dispatch, showSuccess, toastMessage) => {
    const containerClient = blobServiceClient.getContainerClient(token.container);
    const blockBlobClient = containerClient.getBlockBlobClient(token.blobName);
    await blockBlobClient.delete()
        .then((result) => {
            if(!!showSuccess && toastMessage){
                dispatch(notificationActions.toastMessage("BlobOperationSuccessfull", "success"));
            }
        })
        .catch((error) => {
            dispatch(apiCallError(error));
            dispatch(notificationActions.toastMessage("ErrorDeletingBlob", "error"));
            throw new Error(error);
        });
};