import * as types from "./actionTypes";
import * as workOrderTemplatesApi from "../../api/workOrderTemplatesApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";
import * as constants from "../../components/common/constants";
import * as historyEntryActions from "./historyEntryActions";

export const readWorkOrderTemplatesSuccess = (templates) => {
    return { type: types.LOAD_WORKORDER_TEMPLATES_SUCCESS, templates };
};

export const readWorkOrderTemplates = (workBoardId, cancelToken, documentId) => {
    return async function(dispatch) {
        await workOrderTemplatesApi
            .readWorkOrderTemplates(workBoardId, cancelToken, documentId)  
            .then((templates) => {
                dispatch(readWorkOrderTemplatesSuccess(templates));
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notifications.toastMessage("ErrorloadingWorkOrderTemplates", "error"));
                    throw new Error(error);
                }
            });
    };
}; 

export const readWorkOrderTemplateSuccess = (template) => {
    return { type: types.READ_WORKORDER_TEMPLATE_SUCCESS, template };
};

export const readWorkOrderTemplate = (id, cancelToken) => {
    return function(dispatch) {
        dispatch(beginApiCall());
        return workOrderTemplatesApi
            .readWorkOrderTemplate(id, cancelToken)  
            .then((template) => {
                dispatch(readWorkOrderTemplateSuccess(template));
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notifications.toastMessage("ErrorloadingWorkOrderDiaryEntry", "error"));
                    throw new Error(error);
                }
            });
    };
}; 

export const updateWorkOrderTemplateSuccess = (template) => {
    return { type: types.UPDATE_WORKORDER_TEMPLATE_SUCCESS, template };
};

export const updateWorkOrderTemplate = (template, cancelToken) => {
    return function(dispatch) {
        dispatch(beginApiCall());
        return workOrderTemplatesApi
            .updateWorkOrderTemplate(template, cancelToken)  
            .then((result) => {
                dispatch(updateWorkOrderTemplateSuccess(result));
                dispatch(historyEntryActions.updateHistoryEntry(template, constants.collections.workOrderTemplate));
                return result;
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                dispatch(notifications.toastMessage("ErrorupdatingWorkOrderTemplate", "error"));
                throw new Error(error);
            });
    };
}; 

export const documentExistsInTemplates = (workBoardId, documentId) => {
    return function(dispatch) {
        return workOrderTemplatesApi
            .documentExistsInTemplates(workBoardId, documentId, null)  
            .then((result) => {
                return result;
            })
            .catch((error) => {
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                    !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notifications.toastMessage("ErrorChekingIfExistDocumentInMessages", "error"));
                    throw new Error(error);
                }
            });
    };
}; 
