import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function blobAbortProgressReducer(state = initialState.blobsAbortProgress, action) {
    if (action.type === types.ABORT_BLOBS_PROGRESS) { 
        return action.blobsAbortProgress;
    }
    else {
        return state;
    }
}