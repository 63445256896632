/* /src/components/auth/logout.js */
import React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import BackDrop from "../Backdrop/Backdrop";
import * as sessionActions from "../../redux/actions/sessionActions";

export class LogoutPage extends React.Component {
    componentDidMount() {
        this.props.actions.sessionDeactivated();
        this.props.actions.logout();
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                {t("SigningOut")}
                <BackDrop fullscreen />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            sessionDeactivated: bindActionCreators(sessionActions.sessionDeactivated, dispatch),
            logout: bindActionCreators(sessionActions.logout, dispatch),
        }
    };
};

export const Logout = compose(
    withTranslation("common"), 
    connect(
        mapStateToProps, 
        mapDispatchToProps
    ))(LogoutPage);
