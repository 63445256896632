/* /src/routes/privateRoute.js */
import React, { Fragment, useEffect, lazy } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";     
import { Route, useRouteMatch, withRouter } from "react-router-dom";
import LayoutSpinner from "../components/layoutSpinner";         
import LoginInitiator from "../components/auth/loginInitiator";
import * as tenantActions from "../redux/actions/tenantActions";
import AppInsightsHelper from "../helpers/appInsightsHelper";

const PrivacyPolicyModal = lazy(() => import("../components/common/welcome/privacyPolicyModal"));

const RequireTenantComponent = ({ component, history, actions, ...rest }) => {
    const match = useRouteMatch("/:tenantName");
    const [userId, setUserId] = React.useState(null);
    const [activeTenantName, setActiveTenantName] = React.useState(null);
    const [showContent, setShowContent] = React.useState(false);
    const {tenant, user, currentUser, availableTenants, accessToken} = rest;

    useEffect(() => {
        if (!!user && !!user.profile && !userId && !!accessToken) {
            if (user.profile.sub !== userId) {
                setUserId(user.profile.sub);
                if (availableTenants.length <= 0) {
                    actions.getAllAvailableTenants(match ? match.params.tenantName : "").then((tenants) => {
                        if (tenants.length === 0) {
                            AppInsightsHelper.trackException("Tenant not found");
                            history.replace("/notfound");
                        }
                    }).catch(() => {

                    });
                }
            }
        }
    }, [history, match, user, currentUser, accessToken, userId, actions, availableTenants]);

    useEffect(() => {
        if (!!user && !!user.profile && user.profile.sub === userId && match && match.params.tenantName && 
            (!tenant || match.params.tenantName !== tenant.name)) {
            if (activeTenantName !== match.params.tenantName && availableTenants.length > 0) {
                setActiveTenantName(match.params.tenantName);
                actions.selectTenantByName(match.params.tenantName, availableTenants);
            }
        }
    }, [match, actions, tenant, availableTenants, user, userId, activeTenantName]);

    useEffect(() => {
        const show = !!user && !user.expired && !!currentUser && currentUser.id === user.profile.sub && !!accessToken && !!tenant.id;
        if (showContent !== show) {
            setShowContent(show);
        }
    }, [user, currentUser, accessToken, tenant, showContent]);

    const renderFn = (Component, props) => () => {
        return <Fragment>
            {!!Component && showContent && 
                <Fragment>
                    <Component key={props.path + props.refreshKey} {...props} />
                    <PrivacyPolicyModal />
                </Fragment>
            }
            <LayoutSpinner show={!showContent} />
            <LoginInitiator />
        </Fragment>;
    };

    return <Route {...rest} render={renderFn(component, rest)} />;
};

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        currentUser: state.currentUser,
        accessToken: state.accessToken,
        tenant: state.tenant,
        availableTenants: state.availableTenants
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        actions: {
            selectTenantByName: bindActionCreators(tenantActions.selectTenantByName, dispatch),
            getAllAvailableTenants: bindActionCreators(tenantActions.getAllAvailableTenants, dispatch),
        }
    };
};

export const RequireTenant = connect(mapStateToProps, mapDispatchToProps)(withRouter(RequireTenantComponent));