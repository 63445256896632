import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function touchesWorkboardReducer(
    state = initialState.touchesWorkboard,
    action
) {
    if (action.type === types.SET_TOUCHES_WORKBOARD) {
        return action.touchesWorkboard;
    }
  
    return state;
}