import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function workOrderMembersTeamReducer(state = initialState.membersTeam, action) {
    if (action.type === types.MEMBERS_TEAM_LIST_SUCCESS) {
        return action.membersTeam;
    }
    else {
        return state;
    }
}