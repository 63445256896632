import React, { Component, Fragment } from "react";
import * as constants from "../constants";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as catalogsActions from "../../../redux/actions/catalogsActions";

export class CatalogsAll extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentWillUnmount() {
        if (this.props.cancelPromises) {
            this.props.cancelPromises(this.state.promiseId);
        }
    }

    componentDidMount() {
        this.loadCatalogs();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tenant.id !== this.props.tenant.id || (!!this.props.accessToken && !prevProps.accessToken)) {
            this.loadCatalogs();
        }

        if (prevProps.realTimeMessage !== this.props.realTimeMessage) {
            if (this.props.realTimeMessage.collectionId === constants.collections.generalCatalogs) {
                this.loadCatalogs();
            }
        }
    }

    loadCatalogs = () => {
        if (!!this.props.oidc.user && !this.props.oidc.user.expired) {
            if (!!this.props.tenant.id && !!this.props.accessToken) {
                const suppressErrors = true;
                this.props.actions.readCatalogs(suppressErrors);
            }
        }
    }

    render() {
        return (
            <Fragment></Fragment>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        tenant: state.tenant,
        oidc: state.oidc,
        accessToken: state.accessToken,
        realTimeMessage: state.realTimeMessage,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            readCatalogs: bindActionCreators(catalogsActions.readCatalogs, dispatch),
            readCatalog: bindActionCreators(catalogsActions.readCatalog, dispatch),
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CatalogsAll);


