import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function workCardsReducer(state = initialState.workCards, action) {
    switch (action.type) {
    case types.LOAD_WORKCARDS_SUCCESS:
        return action.workCards;
    default:
        return state;
    }
}

