import React, {Fragment} from "react";
import { LayoutContext } from "./layout";


export default class LayoutSpinner extends React.PureComponent {
    static contextType = LayoutContext;

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        if (this.props.show) {
            this.setState({ show: true });
            this.context.showSpinner();
        }
        else if (!this.props.show) {
            this.setState({ show: false });
        }
    }

    componentWillUnmount() {
        if (this.state.show) {
            this.setState({ show: false });
            this.context.hideSpinner();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.show && !this.state.show) {
            this.setState({ show: true });
            this.context.showSpinner();
        }
        else if (!this.props.show && this.state.show) {
            this.setState({ show: false });
            this.context.hideSpinner();
        }
    }

    render() {
        return (<Fragment />);
    }
}

