import React, { Fragment } from "react";
import "./NavBar.css";
import NestedList from "./nestedList";
import Logo from "../../components/Navbar/Logo";
import { connect } from "react-redux";
import * as navBarStyles from "./styles.js";
import { bindActionCreators, compose } from "redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {Typography, Grid, Tooltip} from "@material-ui/core";
import * as navigationActions from "../../redux/actions/navigationActions";
import navitemsCore from "../../utils/navItemsCore.json";
import ModalHelper from "../../helpers/modalHelper";
import { DialogModal } from "../common/dialogModal";
import { tenantRoutePrefix } from "../../helpers/tenantHelper";
import * as workBoardActions from "../../redux/actions/workBoardActions";
import * as pendingRouterActions from "../../redux/actions/pendingRouteActions";
import TenantSelectorList from "./tenantSelectorList";
import axios from "axios";

export const maximizedWidth = 212;
export class SideNavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            wideNav: true,
            focus: null,
            navWidth: props.menuWidth,
            highlighted: this.props.navigation.module ? this.props.navigation.module.name : "",
            tenantSelectorModalGuid: ModalHelper.newGuid()
        };
    }

    componentDidMount() {
        if (this.props.highlighted !== "") {
            this.setHighlighted();
        }
    }

    componentDidUpdate = (prevProps) => {
        if (!!this.props.oidc.user && !!this.props.oidc.user.profile) {   
            if(this.props.currentWorkBoardId && this.props.currentWorkBoardId !== prevProps.currentWorkBoardId) {
                const cancelToken = axios.CancelToken.source();
                this.props.actions.readWorkBoard(this.props.currentWorkBoardId, cancelToken.token, true);
            }
        }

        if (this.props.highlighted !== prevProps.highlighted && this.props.highlighted !== "") {
            this.setHighlighted();   
        }
    }

    setHighlighted = () => {
        this.highlight(this.props.highlighted);  
        this.props.cleanHighlighted();     
    }

    highlight = (name) => {
        this.setState({highlighted: name});
    }

    openModal = () => {
        ModalHelper.openModal(this.state.tenantSelectorModalGuid, this.props.history);
    }

    closeModal = (tenant = null) => {
        if(tenant){
            const { navigation } = this.props;
            const path = tenantRoutePrefix(tenant) + "/" + navigation.module.name;
            this.props.history.replace(path);
        }
        else{
            ModalHelper.closeModal(this.state.tenantSelectorModalGuid, this.props.history);
        }
    }


    renderTenantsList = () => {
        return(
            <TenantSelectorList close={this.closeModal}></TenantSelectorList>
        );
    }

    renderTenantSelector = () => {
        return(
            <Grid id="SideNavTenantSelector" style={navBarStyles.boardSelector(Boolean(true))} 
                onMouseEnter={(e) => this.setState({focus: e.currentTarget})} 
                onMouseLeave={(e) => this.setState({focus: null})} 
                onClick={() => this.openModal()}>
                <Tooltip title={this.props.t("SelectTenant")}>
                    <Typography>{this.props.tenant.name}</Typography>
                </Tooltip>
            </Grid>
        );
    }

    renderTenantSelectorModal = () => {
        return(
            <DialogModal
                id="TenantSelectModal"
                fullWidth={true} 
                maxWidth={"sm"}
                scroll={"body"}
                open={true}
                title={"SelectTenant"}
                t={this.props.t}
                content={this.renderTenantsList}
                notActions={true}
                backButton={true}
                clickAction={() => this.closeModal()}
            />);
    }

    renderNestedListed = (nestedClasses) => {
        const { t } = this.props;
        return (
            <div id="main-menu" style={{height: window.innerHeight - (this.props.show ? 0 : 242)}} className={nestedClasses}>
                <NestedList
                    userPermissions={this.props.userPermissions}
                    close={this.props.close}
                    t={t}
                    list={navitemsCore}
                    tenant={this.props.tenant}
                    actions={this.props.actions}
                    history={this.props.history}
                    moduleInfo={this.props.navigation.module}
                    select={this.highlight}
                    selectedId={this.state.highlighted}
                    pendingRouter={this.props.pendingRouter}>
                </NestedList>
            </div>
        );
    }

    render() {
        const { staticMenu, show} = this.props;
        let sideBarClasses = "side-nav-bar";
        let nestedClasses = "side-nav-bar-list";
        if (!!show || !!staticMenu) {
            sideBarClasses = "side-nav-bar open";
        }

        return (
            <Fragment>
                <nav className={sideBarClasses} style={{ width: maximizedWidth, 
                    display: staticMenu ? "block" : null,
                    transform: staticMenu ? "translateX(0)" : null }}
                >
                    <div style={{marginTop: 10, display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Logo 
                            tenant={this.props.tenant} 
                            history={this.props.history} 
                            actions={this.props.actions}
                            close={this.props.close}
                            dimensions={{width: 150, height: "auto"}}
                            highlight={this.highlight}
                            path={{route: "/workBoards", name: "WorkBoards"}}
                            type={"gray"}>
                        </Logo>
                    </div>
                   
                    {!this.props.isSharedToken &&
                        <Fragment>
                            {this.renderTenantSelector()}
                            {ModalHelper.isOpen(this.state.tenantSelectorModalGuid, this.props.history) && this.renderTenantSelectorModal()}
                            {this.renderNestedListed(nestedClasses)}
                        </Fragment>
                    }
                </nav>            
            </Fragment>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        tenant: state.tenant,
        open: state.open,
        navigation: state.navigation,
        userPermissions: state.userPermissions,
        pendingRouter: state.pendingRouter,
        isSharedToken: state.isSharedToken,
        workBoard: state.workBoardAggregate.workBoard,
        currentWorkBoardId: state.currentWorkBoardId,
        oidc: state.oidc
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            setModule: bindActionCreators(navigationActions.setModule, dispatch),
            setCurrentWorkBoard: bindActionCreators(workBoardActions.setCurrentWorkBoard, dispatch),
            readWorkBoard: bindActionCreators(workBoardActions.readWorkBoard, dispatch),
            pendingRouterProgress: bindActionCreators(pendingRouterActions.pendingRouterProgress, dispatch)
        }
    };
};

export default compose(withTranslation("common"), connect(
    mapStateToProps,
    mapDispatchToProps
))(withRouter(SideNavBar));
