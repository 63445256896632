export const Added = "Added";
export const Modified = "Modified";
export const Done = "Done";
export const InProgress = "InProgress";
export const Deleted = "Deleted";
export const New = "New";
export const Inserted = "Inserted";
export const ReOrder = "ReOrder";
export const UniqueKeyConstraintViolation = "Unique Key Constraint Violation";
export const productRegisterFilter = "productRegisterFilter";
export const productBuilderFilter = "productBuilderFilter";
export const productGroupFilter = "productGroupFilter";
export const formTemplateFilter = "formTemplateFilter";
export const sharedTasksFilter = "sharedTasksFilter";
export const productUnitFilter = "productUnitFilter";
export const contactFilter = "contactFilter";
export const contactsWorkSiteFilter = "contactsWorkSiteFilter";
export const contactsCustomerFilter = "contactsCustomerFilter";
export const customerFilter = "customerFilter";
export const isVisible = "isVisible";
export const isSort = "isSort";
export const cancelHttpRequest = "cancelHttpRequest";
export const ProductsRegisterList = "ProductsRegisterList";
export const ProductsBuilderList = "ProductsBuilderList";
export const ContactsWorkSiteList = "ContactsWorkSiteList";
export const productRegister = "productRegister";
export const productGroup = "productGroup";
export const productUnit = "productUnit";
export const customers = "customers";
export const contacts = "contacts";
export const workSites = "workSites";
export const notifications = "notifications";
export const minWidthScreen = 960;
export const catalogs = {
    PaymentType: "PaymentType",
    AccountGroup: "AccountGroup",
    Title: "Title",
    Defaults: "Defaults",
    TimesheetItemTypes: "TimesheetItemTypes"
};
export const workSiteFilter = "workSiteFilter";
export const worOrderFilter = "worOrderFilter";
export const workOrders = "workOrders";
export const SystemErrorNotFound = "SystemError400";
export const EmptyGuid = "00000000-0000-0000-0000-000000000000";
export const workOrderProductsFilter = "workOrderProductsFilter";
export const ProductWorkOrderList = "ProductWorkOrderList";
export const ProductsRegisterWorkOrderList = "ProductsRegisterWorkOrderList";
export const AddProductFromWorkOrder = "AddProductFromWorkOrder";
export const EditProductFromWorkOrder = "EditProductFromWorkOrder";
export const fileType = {
    video: "video",
    image: "image",
    audio: "audio",
    file: "file",
    formTemplate: "formTemplate"
};
export const blobPermissions = {
    Read: "Read",
    Write: "Write",
    Delete: "Delete",
};
export const textAlign = {
    left: "left",
    right: "right"
};
export const diaryEntryFilter = "diaryEntryFilter";
export const customerAttachmentsFilter = "customerAttachmentsFilter";
export const workSiteAttachmentsFilter = "workSiteAttachmentsFilter";
export const collections = {
    workOrder: "workOrder",
    workOrderDiaryEntry: "workOrderDiaryEntry",
    workOrderTemplate: "workOrderTemplate",
    workQueue: "workQueue",
    workBoards: "workQueuesSettings",
    workSite: "workSite",
    productGroup: "productGroup",
    productUnit: "productUnit",
    product: "product",
    contact: "contact",
    role: "role",
    tenant: "tenant",
    customer: "customer",
    user: "user",
    generalCatalogs: "generalCatalogs",
    channel: "channel",
    channelMembers: "channelMembers",
    formTemplate: "formTemplate",
    customersAttachment: "customersAttachment",
    WorkSiteAttachments: "WorkSiteAttachments",
    WorkOrderQueueChange: "WorkOrderQueueChange"
};
export const PreconditionFailed = "PreconditionFailed";
export const workBoardsFilter = "workQueuesSettingsFilter";
export const workQueue = "workQueue";
export const users = "users";
export const usersFilter = "usersFilter";
export const usersManagementFilter = "usersManagementFilter";
export const roleFilter = "roleFilter";
export const rolesUserFilter = "rolesUserFilter";
export const roleItems = {
    ProductUnits: "ProductUnits",
    ProductGroups: "ProductGroups",
    Contacts: "Contacts",
    Customers: "Customers",
    ProductsRegister: "ProductsRegister",
    WorkSites: "WorkSites",
    UserManagement: "UserManagement",
    Roles: "Roles",
    WorkQueuesSettings: "WorkQueuesSettings",
    WorkOrder: "WorkOrder",
    Tenants: "Tenants",
    TimeSheets: "TimeSheets",
    FormTemplates: "FormTemplates",
    SharedTasks: "SharedTasks"
};
export const roleRights = {
    List: "List",
    Read: "Read",
    Create: "Create",
    Update: "Update",
    Delete: "Delete",
    Admin: "Admin",
    ListOwn: "ListOwn",
    ReadOwn: "ReadOwn",
    CreateOwn: "CreateOwn",
    UpdateOwn: "UpdateOwn",
    DeleteOwn: "DeleteOwn",
};

export const Sent = "Sent";
export const Received = "Received";

export const Invitation = "invitation";
export const AnonymousInvitation = "AnonymousInvitation";
export const TenantFilter = "TenantFilter";
export const TenantsList = "TenantsList";
export const contactsWorkOrderFilter = "contactsWorkOrderFilter";
export const WAIT_INTERVAL = 500;
export const ENTER_KEY = 13;
export const WAIT_INTERVAL_SAVE = 2000;
export const elementActive = "BODY";

export const LicenseOptions = {
    MaxNumberOfTeams: "MaxNumberOfTeams",
    MaxNumberOfUsers: "MaxNumberOfUsers",
    MaxFileStorageBytes: "MaxFileStorageBytes"
};

export const workOrderStatus = [
    { 
        id: "New",
        name: "New",
        color: "#6666ff"
    },
    { 
        id: "Accepted",
        name: "Accepted",
        color: "#e2e132"
    },
    { 
        id: "Rejected",
        name: "Rejected",
        color: "red"
    },
    {
        id: "Blocked",
        name: "Blocked",
        color: "red"
    },
    { 
        id: "InProgress",
        name: "In-progress",
        color: "orange"
    },
    { 
        id: "Done",
        name: "Done",
        color: "#33ae33"
    }
];

export const availableLocales = [
    {value: "fi", name: "Finnish"},
    {value: "en", name: "English"},
];

export const SharingState = {
    Pending: "Pending",
    Accepted: "Accepted",
    Rejected: "Rejected",
};

export const workQueueDefaultStatus = [
    {
        id: "noStatus",
        name: "NoStatus",
    },
    { 
        id: "New",
        name: "New",
        color: "#6666ff"
    },
    { 
        id: "Accepted",
        name: "Accepted",
        color: "#e2e132"
    },
    { 
        id: "Rejected",
        name: "Rejected",
        color: "red"
    },
    {
        id: "Blocked",
        name: "Blocked",
        color: "red"
    },
    { 
        id: "InProgress",
        name: "In-progress",
        color: "orange"
    },
    { 
        id: "Done",
        name: "Done",
        color: "#33ae33"
    }
];

export const timeToSaveLastTimeReadMessage = 2000; 
export const routeShared = {
    workOrderShared: "workOrderShared"
};
export const tokensFilter = "tokensFilter";
export const historyEntryFilter = "historyEntryFilter";
export const eventType = {
    WorkOrderCreated: "WorkOrderCreated",
    WorkOrderUpdated: "WorkOrderUpdated",
    WorkOrderDeleted: "WorkOrderDeleted",
    OwnerChanged: "OwnerChanged",
    StateChanged: "StateChanged",
    WorkQueueChanged: "WorkQueueChanged",
    AttachmentAdded: "AttachmentAdded",
    AttachmentUpdated: "AttachmentUpdated",
    AttachmentDeleted: "AttachmentDeleted",
    ChatChannelCreated: "ChatChannelCreated",
    ChatMemberAdded: "ChatMemberAdded"
};
export const OnlyChatMembers = "OnlyChatMembers";
export const timeSheetFilter = "timeSheetFilter";
export const recordsInQuery = 30;
