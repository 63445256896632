import * as types from "./actionTypes";
import * as workQueueSettingsApi from "../../api/workQueueSettingsApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notificationActions from "./notificationsActions";
import * as workQueueActions from "./workQueueActions";
import * as constants from "../../components/common/constants";
import * as usersApi from "../../api/usersApi";
import * as WorkOrderApi from "../../api/workOrderApi";
import * as WorkSitesApi from "../../api/workSiteApi";
import * as WorkQueuesApi from "../../api/workQueueApi";
import axios from "axios";
import FilterHelper from "../../helpers/filterHelper"; 
import * as chatChannelActions from "./chatChannelActions";
import { store } from "../store";
import * as workOrderDiaryEntryActions from "../../redux/actions/workOrderDiaryEntryActions";

export const setCurrentWorkBoardSuccess = (currentWorkBoardId) => {
    return { type: types.SET_CURRENT_WORKBOARD, currentWorkBoardId};
};

export const setCurrentWorkBoard = (id, userId, tenantid) => {
    if(id === undefined || id === "null" ){
        id = null;
    }

    return async function(dispatch) {
        var currentWorkBoardId = null;
        if (id === null && userId !== null) {
            currentWorkBoardId = await getWorkBoardId(id, userId, tenantid, dispatch);
        }
        else {
            currentWorkBoardId = id;
            localStorage.setItem("currentWorkBoardId" + userId + tenantid, currentWorkBoardId);
        }

        dispatch(setCurrentWorkBoardSuccess(currentWorkBoardId));
    };
};

const getWorkBoardId = async(id, userId, tenantid, dispatch) => {
    var current = localStorage.getItem("currentWorkBoardId" + userId + tenantid);
    var currentWorkBoardId = false;
    const cancelToken = axios.CancelToken.source();
    var data = FilterHelper.getfilterOptions(constants.workBoardsFilter);
    await workQueueSettingsApi
        .ReadWorkQueuesSettings("", data.sort, 1, userId, cancelToken.token).then(
            (result) => {
                // check if user has any boards
                if(result.length > 0){
                    for(var i = 0; i < result.length; i++){
                        if (current !== null && current === result[i].id) {
                            currentWorkBoardId = current;
                            break;
                        }
                        else {
                            currentWorkBoardId = result[i].id;
                            if (current === null) {
                                break;
                            }
                        }
                    }
                }  

                localStorage.setItem("currentWorkBoardId" + userId + tenantid, currentWorkBoardId);
            }
        )
        .catch((error) => {
            dispatch(apiCallError(error));
            if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                    !error.message.includes(constants.cancelHttpRequest)) {
                currentWorkBoardId = null;
                localStorage.setItem("currentWorkBoardId" + userId + tenantid, currentWorkBoardId);
            }
        });

    return currentWorkBoardId;    
};


export const readWorkBoardsSuccess = (workBoards) => {
    return { type: types.LOAD_WORKBOARDS_SUCCESS, workBoards };
};

export const readWorkBoards = (filter, sort, page, ownerId, cancelToken, tags) => {
    return async function(dispatch) {
        var settings = [];
        await workQueueSettingsApi
            .ReadWorkQueuesSettings(filter, sort, page, ownerId, cancelToken, tags)  
            .then((result) => {
                settings = result;
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notificationActions.toastMessage("ErrorloadingworkQueuesSettings", "error"));
                    throw new Error(error);
                }
            });

        var dict = {};
        settings.map((element) => {
            if (element.ownerId !== "") {
                dict[element.ownerId] = "";
            }

            return element;
        });

        var batch = [];
        for(var key in dict) {
            batch.push(key);
        }

        var sendData = {
            batch: batch
        };

        await getUserBatch(sendData, cancelToken, settings, dispatch);
    };
}; 

const getUserBatch = async(sendData, cancelToken, settings, dispatch) => {
    await usersApi
        .ReadUserBatch(sendData, cancelToken)  
        .then((result) => {
            for (var x = 0; x < settings.length; x++) {
                var element = findElement(result, settings[x].ownerId);
                if (element) {
                    settings[x].ownerNameTmp = element.name;
                }
                else {
                    settings[x].ownerNameTmp = "";
                }
            }

            dispatch(readWorkBoardsSuccess(settings));
        })
        .catch((error) => {
            dispatch(apiCallError(error));
            if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
            !error.message.includes(constants.cancelHttpRequest)) {
                dispatch(notificationActions.toastMessage("ErrorLoadingUsers", "error"));
                throw new Error(error);
            }
        });
};

const findElement = (items, item) => {
    return items.find((e) => e.id === item);
};

export const readWorkBoardSuccess = (workBoard) => {
    return { type: types.READ_WORKBOARD_SUCCESS, workBoard };
};

export const readWorkQueuesSuccess = (workQueues) => {
    return { type: types.READ_WORKQUEUES_SUCCESS, workQueues };
};

export const readWorkOrdersSuccess = (workOrders) => {
    return { type: types.READ_WORKORDERS_SUCCESS, workOrders };
};

export const readWorkSitesSuccess = (workSites) => {
    return { type: types.READ_WORKSITES_SUCCESS, workSites };
};

const reduceToIdHashMap = (map, item) => {
    map[item.id] = item;
    return map;
};

export const readWorkBoard = (id, cancelToken, onlyWorkSettings, roles, userId) => {
    return async function(dispatch) {
        let workBoardSettings = await getWorkBoard(id, dispatch, cancelToken).then((settings) => {
            if (!!settings) {
                dispatch(readWorkBoardSuccess(settings));

                getWorkQueueBatch(settings.workQueues, cancelToken, dispatch, settings.ownerId, roles)
                    .then((workqueues) => {
                        if (workqueues) {
                            dispatch(readWorkQueuesSuccess(workqueues.reduce(reduceToIdHashMap, {})));
                        }
                    });
            }

            return settings;
        });

        if (!onlyWorkSettings) {
            const membersFilter = getMembersFilter(userId, workBoardSettings);
            const workorders = await getWorkOrdersByWorkBoard(id, membersFilter, cancelToken, dispatch);
            dispatch(readWorkOrdersSuccess(workorders.reduce(reduceToIdHashMap, {})));

            getWorkSitesByWorkOrders(workorders, cancelToken, dispatch).then((worksites) => {
                if (worksites) {
                    dispatch(readWorkSitesSuccess(worksites.reduce(reduceToIdHashMap, {})));
                }
            });

            await getMessageCounter(cancelToken, dispatch);

            var ids = [];
            for (var x = 0; x < workorders.length; x++) {
                ids.push(workorders[x].id);
            }

            await attachmentsCounter(ids, cancelToken, dispatch);
        }
    };
};

const getMembersFilter = (userId, workBoardSettings) => {
    const workOrderVisibility = !!workBoardSettings.workGroupSettings ? workBoardSettings.workGroupSettings.workOrderVisibility : "all";
    if (workBoardSettings.ownerId === userId || workOrderVisibility === "all") {
        return null;
    }
    else if (workOrderVisibility === "own+free") {
        return [userId, null];
    }

    return [userId];
};

export const moveWorkOrderOnBoard = (workOrderId, toWorkQueueId, fromWorkBoardId, fromWorkQueueId, toWorkBoardId, beforeWorkOrderId, 
    position, defaultStatus) => {
    return function(dispatch) {
        let status = defaultStatus;
        if (fromWorkQueueId === toWorkQueueId || status === "NoStatus") {
            status = null;
        }
        
        if(fromWorkBoardId === toWorkBoardId){
        // MOVE_WORKORDER_ON_WORKBOARD updates the redux state to show the result of the move immediately without waiting for a server response
            const reduxStateMoveAction = {
                type: types.MOVE_WORKORDER_ON_WORKBOARD, 
                move: { 
                    workOrderId: parseInt(workOrderId), 
                    fromWorkQueueId,
                    toWorkQueueId,
                    beforeWorkOrderId,
                    position,
                    status
                }
            };
            dispatch(reduxStateMoveAction);
        }

        // After updating the redux state, do the actual save to the azure functions
        const serverSideMoveAction = workQueueActions.moveWorkOrderInQueues(
            workOrderId.toString(), toWorkQueueId, fromWorkBoardId, fromWorkQueueId, toWorkBoardId, position, status);
        dispatch(serverSideMoveAction);
    };
};

export const deleteWorkOrderFromBoard = (workOrderId, fromWorkQueueId, fromWorkBoardId) => {
    return function(dispatch) {
        // DELETE_WORKORDER_FROM_WORKBOARD updates the redux state to show the result of the move immediately without waiting for a server response
        const reduxStateDeleteAction = { type: types.DELETE_WORKORDER_FROM_WORKBOARD, deletion: { workOrderId, fromWorkQueueId } };
        dispatch(reduxStateDeleteAction);
        
        // After updating the redux state, do the actual delete to the azure functions
        const serverSideDeleteAction = workQueueActions.deleteWorkOrderInQueues(workOrderId, fromWorkQueueId, fromWorkBoardId);
        dispatch(serverSideDeleteAction);
    };
};

export const getMessageCounter = (cancelToken, dispatch) => {
    var userId = store.getState().oidc.user.profile.sub;
    dispatch(chatChannelActions.GetMessagesCounter(userId, cancelToken));
};


export const attachmentsCounter = (ids, cancelToken, dispatch) => {
    dispatch(workOrderDiaryEntryActions.GetAttachmentsCounter(ids, cancelToken));
};

export const getWorkQueueSettings = (id, cancelToken) => {
    return async function(dispatch) {
        return workQueueSettingsApi
            .ReadWorkQueueSettings(id, cancelToken)  
            .then((result) => {
                return result;
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
            !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notificationActions.toastMessage("ErrorLoadingWorkBoard", "error"));
                    throw new Error(error);
                }
            });
    };
};

const getWorkBoard = async(id, dispatch, cancelToken) => {
    let settings = await workQueueSettingsApi
        .ReadWorkQueueSettings(id, cancelToken)  
        .then((result) => {
            return result;
        })
        .catch((error) => {
            dispatch(apiCallError(error));
            if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
            !error.message.includes(constants.cancelHttpRequest)) {
                dispatch(notificationActions.toastMessage("ErrorLoadingWorkBoard", "error"));
                throw new Error(error);
            }
        });

    return settings;   
};

const getWorkQueueBatch = async (batch, cancelToken, dispatch, ownerId, roles) => {
    let workqueues = await WorkQueuesApi
        .ReadWorkQueueBatch(batch, cancelToken, ownerId, roles)
        .then((result) => {
            return result;
        })
        .catch((error) => {
            dispatch(apiCallError(error));
            if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
            !error.message.includes(constants.cancelHttpRequest)) {
                dispatch(notificationActions.toastMessage("ErrorloadingWorkQueues", "error"));
                throw new Error(error);
            }
        });

    return workqueues;
};

const getWorkOrderBatch = async(sendData, cancelToken, dispatch) => {
    let workorders = await WorkOrderApi
        .ReadWorkOrderBatch(sendData, cancelToken)  
        .then((result) => {
            return result;
        })
        .catch((error) => {
            dispatch(apiCallError(error));
            if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
            !error.message.includes(constants.cancelHttpRequest)) {
                dispatch(notificationActions.toastMessage("ErrorloadingWorkOrders", "error"));
                throw new Error(error);
            }
        });

    return workorders;   
};

const getWorkSiteBatch = async(sendData, cancelToken, dispatch) => {
    let worksites = await WorkSitesApi
        .ReadWorkSiteBatch(sendData, cancelToken)  
        .then((result) => {
            return result;
        })
        .catch((error) => {
            dispatch(apiCallError(error));
            if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
            !error.message.includes(constants.cancelHttpRequest)) {
                dispatch(notificationActions.toastMessage("ErrorloadingWorkSites", "error"));
                throw new Error(error);
            }
        });

    return worksites;  
};

export const startUpdateWorkBoard = (workBoard) => {
    return { type: types.START_UPDATE_WORKBOARD, workBoard };
};

export const updateWorkBoardSuccess = (workBoard) => {
    return { type: types.UPDATE_WORKBOARD_SUCCESS, workBoard };
};

export const updateWorkBoard = (model) => {
    return function(dispatch) {
        if (model.workQueueSettings.state !== constants.Deleted && !!model.workQueueSettings) {
            dispatch(startUpdateWorkBoard(model.workQueueSettings));
        }
        
        dispatch(beginApiCall());
        return workQueueSettingsApi
            .updateWorkQueueSettings(model)  
            .then((result) => {
                if (model.workQueueSettings.state !== constants.Deleted && !!model.workQueueSettings) {
                    dispatch(updateWorkBoardSuccess(model.workQueueSettings));
                }
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                dispatch(notificationActions.toastMessage("ErrorupdatingworkBoard", "error"));
                throw new Error(error);
            });
    };
}; 


const getWorkSitesByWorkOrders = async (workorders, cancelToken, dispatch) => {
    let workSitesDict = {};
    workorders.map((element) => {
        if (parseInt(element.workSiteId) > 0) {
            workSitesDict[element.workSiteId] = "";
        }

        return element;
    });

    let workSiteIds = [];
    for (let key in workSitesDict) {
        workSiteIds.push(key);
    }

    const workSitesRequest = {
        batch: workSiteIds
    };

    let worksites = await getWorkSiteBatch(workSitesRequest, cancelToken, dispatch);
    return worksites;
};

const getWorkOrdersByWorkBoard = async (workBoardId, membersFilter, cancelToken, dispatch) => {
    let workOrdersRequest = {
        workQueueSettingsId: workBoardId,
        members: membersFilter || null,
    };

    let workorders = await getWorkOrderBatch(workOrdersRequest, cancelToken, dispatch);
    return workorders;
};

export const GetWorkQueuesSettingsCount = (model) => {
    return function(dispatch) {
        dispatch(beginApiCall());
        return workQueueSettingsApi
            .GetWorkQueuesSettingsCount(model)  
            .then((result) => {
                return result;
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                dispatch(notificationActions.toastMessage("ErrorGetWorkBoardCount", "error"));
                throw new Error(error);
            });
    };
}; 

export const setFilterWorkBoard = (filterWorkBoard) => {
    return { type: types.SET_FILTER_WORKBOARD, filterWorkBoard};
};