import React from "react";
import { compose } from "redux";
import AppInsightsHelper from "../helpers/appInsightsHelper";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { DialogContentText } from "@material-ui/core";
import { DialogModal } from "./common/dialogModal";

export class NotFoundPage extends React.Component {
    componentDidMount() {
        AppInsightsHelper.trackEvent("Page not found");
    }

    componentDidUpdate(prevProps) {
    }

    reloadApp = () => {
        this.props.history.replace("/");
    }

    renderContent = () => {
        const { t } = this.props;
        return (
            <DialogContentText>{t("PageNotFoundDescription")}</DialogContentText>
        );
    }

    render() {
        const { t } = this.props;
        return (
            <DialogModal
                id="ErrorModal"
                scroll={"body"}
                open={this.props.open !== false}
                title={"PageNotFound"}
                t={t}
                content={this.renderContent}
                okLabel={"GoToFrontPage"}
                hideCloseButton={true}
                handleReply={this.reloadApp}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
        }
    };
};

export const NotFound = compose(
    withTranslation("common"), 
    connect(
        mapStateToProps, 
        mapDispatchToProps
    ))(withRouter(NotFoundPage));
