export default class FilterHelper {
    static createFilterOption = (id, isVisible, isSort, sm, textAlign, canSort = true, overrideLabel = null, nameField = "None", group = 0, groupName = "") => {
        var direction = "NONE";
        if (isSort) {
            direction = "DESC";
        }
        
        return {
            id: id,
            label: !!overrideLabel ? overrideLabel : id.charAt(0).toUpperCase() + id.substring(1),
            isVisible: isVisible,
            isSort: isSort,
            sm: sm,
            direction: direction,
            textAlign: textAlign,
            canSort: canSort,
            nameField: nameField,
            group: group,
            groupName: groupName
        };
    }

    static setFilter = (id, filterOptions, isRowStyle) => {
        var local = JSON.parse(localStorage.getItem(id));
        if (local === null) {
            local = filterOptions;
        }
        else {
            if (local.filterOptions && local.filterOptions !== null) {
                if (local.isRowStyle && local.isRowStyle !== null) {
                    isRowStyle = local.isRowStyle;
                }

                local = local.filterOptions;
            }

            var options = [];
            var option;
            this.mergeFilter(local, filterOptions, option, options);
            this.pushOptions(filterOptions, option, local, options);
            local = options;
        }
    
        var sort = this.savefilterOptions(id, local, isRowStyle);
       
        return {
            filter: local,
            sort: sort,
            isRowStyle: isRowStyle
        };
    }

    static mergeFilter = (local, filterOptions, option, options) => {
        for (var x = 0; x < local.length; x++) {
            if (local[x] !== null) {
                option = this.validFilterOptions(filterOptions, local[x].id);
                if (option) {
                    var nameField = option.nameField;
                    var group = option.group;
                    var groupName = option.groupName;
                    var label = option.label;
                    option = Object.assign(option, local[x]);
                    option.nameField = nameField;
                    option.label = label;
                    if (option.group === undefined && group !== undefined) {
                        option.group = group;
                        option.groupName = groupName;
                    }

                    options.push(option);
                }
            }
        }
    }

    static pushOptions = (filterOptions, option, local, options) => {
        for (var y = 0; y < filterOptions.length; y++) {
            option = this.validFilterOptions(local, filterOptions[y].id);
            if (!option) {
                options.push(filterOptions[y]);
            }
        }
    }

    static validFilterOptions = (filter, id) => {
        return filter.find((o) => o !== null && o.id === id);
    }

    static savefilterOptions = (id, local, isRowStyle) => {
        var data = {
            filterOptions: local,
            isRowStyle: isRowStyle
        };
 

        localStorage.setItem(id, JSON.stringify(data));
        
        return this.getSort(data.filterOptions);
    }

    static getSort = (local) => {
        var sort = "";
        var direction = "";
        for (var x = 0; x < local.length; x++) {
            if (local[x].isSort) {
                direction = local[x].direction;
                if (direction === undefined || direction === "NONE") {
                    direction = "DESC";
                }

                if (local[x].nameField !== "None") {
                    sort += local[x].nameField + " " + direction + ",";
                }
                else {
                    sort += local[x].id + " " + direction + ",";
                }

                break;
            }
        }

        
        if (local.length > 0) {
            sort = sort.slice(0, -1);
        }

        if (sort === "" && local.length > 1) {
            direction = local[0].direction;
            if (direction === undefined || direction === "NONE") {
                direction = "DESC";
            }

            sort += local[0].id + " " + direction; 
        }

        return sort;
    }

    static getfilterOptions = (id) => {
        var local = JSON.parse(localStorage.getItem(id));
        var filter = [];
        var sort = "";
        var isRowStyle = false;
        if (local !== null) {
            if (local.filterOptions && local.filterOptions !== null) {
                filter = local.filterOptions;
                if (local.isRowStyle && local.isRowStyle !== null) {
                    isRowStyle = local.isRowStyle;
                }

                sort = this.getSort(filter);
            }
            else {
                filter = local;
                sort = this.getSort(local);
            }
        }
   
        this.autoFitOptionWidths(filter);
       
        return {
            filter: filter,
            sort: sort,
            isRowStyle: isRowStyle
        };
    }

    static autoFitOptionWidths = (filterOptions) => {
        let sumBefore = 0;
        for (let i = 0; i < filterOptions.length; i++) {
            if (filterOptions[i].isVisible) {
                sumBefore += filterOptions[i].sm;
            }
        }

        if (sumBefore === 0) {
            return;
        }

        let sumAfter = 0;
        let diffs = [];
        sumAfter = this.autoWidth(filterOptions, sumBefore, diffs, sumAfter);
        if (sumAfter > 12) {
            diffs.sort(this.compareDiffs).reverse();
            for (let n = 0; n < Math.min(diffs.length, sumAfter - 12); n++) {
                filterOptions[diffs[n].index].sm -= 1;
            }
        }
        else if (sumAfter < 12) {
            diffs.sort(this.compareDiffs);
            for (let n = 0; n < Math.min(diffs.length, 12 - sumAfter); n++) {
                filterOptions[diffs[n].index].sm += 1;
            }
        }
    }

    static autoWidth = (filterOptions, sumBefore, diffs, sumAfter) => {
        for (let i = 0; i < filterOptions.length; i++) {
            if (filterOptions[i].isVisible) {
                let smOptimal = Math.min(filterOptions[i].sm, 
                    Math.max(1, 12 * filterOptions[i].sm / sumBefore));
                let smRound = Math.round(smOptimal);
                diffs.push({
                    index: i,
                    diff: smRound - smOptimal
                });
  
                sumAfter += smRound;
                filterOptions[i].sm = smRound;
            }
        }

        return sumAfter;        
    }

    static compareDiffs = ( a, b ) => {
        if ( a.diff < b.diff ){
            return -1;
        }

        if ( a.diff > b.diff ){
            return 1;
        }

        return 0;
    };

    static getSearchfilter = (id, fallbackFields) => {
        var local = JSON.parse(localStorage.getItem(id));
        var filter = [];
        if (local !== null) {
            if (local.filterOptions && local.filterOptions !== null) {
                for (var x = 0; x < local.filterOptions.length; x++) {
                    if (local.filterOptions[x].nameField !== "None") {
                        filter.push(local.filterOptions[x].nameField);
                    }
                }
            }
        }
        else {
            filter = fallbackFields;
        }
        
        return filter;
    }

    static setFieldVisibility = (filter, field, isHide ) => {
        var local = JSON.parse(localStorage.getItem("uplistfieldvisibility"));
        if (local === null) {
            local = {};
        }

        local[filter + field] = isHide;
        
        localStorage.setItem("uplistfieldvisibility", JSON.stringify(local));
    }

    static getFieldVisibility = (filter, field) => {
        var local = JSON.parse(localStorage.getItem("uplistfieldvisibility"));
        var isHide = false;
        if (local !== null) {
            isHide = local[filter + field];
        }

        return isHide;    
    }
}