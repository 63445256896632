import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { CallbackComponent } from "redux-oidc";
import userManager from "../../services/userManager";
import { getRedirectUri, clearRedirectUri } from "../../routes/redirectStorage";
import { store } from "../../redux/store";
import { loadUser } from "redux-oidc";
import * as notificationActions from "../../redux/actions/notificationsActions";
import * as sessionActions from "../../redux/actions/sessionActions";
import AppInsightsHelper from "../../helpers/appInsightsHelper";

export class CallbackPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectUri: null
        };
    }

    componentDidMount() {
        this.setState({
            redirectUri: getRedirectUri()
        });
    }

    render() {
        return (
            <CallbackComponent
                userManager={userManager}
                successCallback={(user) => {
                    AppInsightsHelper.trackEvent("Login success");
                    loadUser(store, userManager);
                    clearRedirectUri();
                    this.props.actions.sessionAcessTokenActive(user.access_token);
                    this.props.actions.sessionActivated(user);
                    this.props.history.replace(this.state.redirectUri);
                }}
                errorCallback={(error) => {
                    AppInsightsHelper.trackEvent("Login error");
                    if (!!this.props.oidc && !!this.props.oidc.user && !this.props.oidc.user.expired) {
                        clearRedirectUri();
                        this.props.history.replace(this.state.redirectUri);
                    }
                    else {
                        this.props.actions.toastMessage(error.message, "error");
                        AppInsightsHelper.trackException(error);
                        this.props.history.replace("/error");
                    }
                }}
            >
                <div />
            </CallbackComponent>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        oidc: state.oidc
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            toastMessage: bindActionCreators(notificationActions.toastMessage, dispatch),
            sessionActivated: bindActionCreators(sessionActions.sessionActivated, dispatch),
            sessionAcessTokenActive: bindActionCreators(sessionActions.sessionAcessTokenActive, dispatch),
        }
    };
};

export const Callback = connect(mapStateToProps, mapDispatchToProps)(CallbackPage);