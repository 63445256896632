import {SeverityLevel} from "@microsoft/applicationinsights-web";

export default class AppInsightsHelper {
    static appInsights = null;
    
    static trackException = (error) => {
        if (this.appInsights !== null) {
            const typedError = typeof error === "string" ? new Error(error) : error;
            const exception = { error: typedError, severityLevel: SeverityLevel.Error };
            this.appInsights.trackException(exception);
        }
    };

    static trackCustomException = (name, message, stack) => {
        if (this.appInsights !== null) {
            const renderError = {
                error: { name, message, stack },
                severityLevel: SeverityLevel.Error
            };

            this.appInsights.trackException(renderError);
        }
    };
    
    static trackTrace = (data) => {
        if (this.appInsights !== null) {
            this.appInsights.trackTrace({ message: data, severityLevel: SeverityLevel.Information });
        }
    };
    
    static trackEvent = (nameEvent) => {
        if (this.appInsights !== null) {
            this.appInsights.trackEvent({ name: nameEvent });
        }
    };

    static trackPageView = (namePage) => {
        if (this.appInsights !== null) {
            this.appInsights.trackPageView({ name: namePage });
        }
    };
};