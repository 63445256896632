import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function productUnitsReducer(state = initialState.productUnits, action) {
    if (action.type === types.LOAD_PRODUCTUNIT_SUCCESS) {
        return action.productUnits;
    }
    else {
        return state;
    }
}
