import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import * as tenantActions from "../../../redux/actions/tenantActions";
import LayoutSpinner from "../../layoutSpinner";
import { parseJwt } from "../../../helpers/jwtHelpers";

export class FrontPageRedirect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cachedTenant: null,
            tenantId: null,
            redirected: false
        };
    }

    async componentDidMount() {
        this.setState({ 
            cachedTenant: tenantActions.getCachedTenant() 
        });

        this.props.actions.getAllAvailableTenants();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.cachedTenant !== this.state.cachedTenant && this.state.cachedTenant) {
            if (this.hasTenant(this.state.cachedTenant.rootGroup)) {
                this.setState({
                    tenantId: this.state.cachedTenant.id
                });
            }
        }

        if (prevProps.availableTenants !== this.props.availableTenants) {
            if (this.props.availableTenants.length === 0) {
                this.setState({ redirected: true });
                this.props.history.replace("/welcome");
            }
            else if (this.props.availableTenants.length > 0 && !this.state.tenantId) {
                this.setState({
                    tenantId: this.props.availableTenants[0].id
                });
            }
        }

        if (this.state.tenantId !== prevState.tenantId) {
            this.props.actions.selectTenant(this.state.tenantId);
        }

        if (this.props.tenant.id === this.state.tenantId && !!this.props.tenant.name &&
            !!this.state.tenantId && !this.state.redirected) {
            this.setState({ redirected: true });
            this.props.history.replace("/" + this.props.tenant.name);
        }
    }

    hasTenant = (tenantRootGroup) => {
        if (!this.props.user || !this.props.user.profile || !this.props.accessToken) {
            return false;
        }
      
        if (!!tenantRootGroup) {
            // Check if the user has the specified tenant
            return parseJwt(this.props.accessToken).groups.includes(tenantRootGroup);
        }
        
        var hasTenant = false;
        
        for (var x = 0; x < parseJwt(this.props.accessToken).groups.length; x++) {
            // Check if the user has any tenant
            if (parseJwt(this.props.accessToken).groups[x].includes("tenants")) {
                hasTenant = true;
                break;
            }
        }
      
        return hasTenant;
    };

    render(){
        return (
            <Fragment>
                <LayoutSpinner show={true}/>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        accessToken: state.accessToken,
        availableTenants: state.availableTenants,
        tenant: state.tenant
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            getAllAvailableTenants: bindActionCreators(tenantActions.getAllAvailableTenants, dispatch),
            selectTenant: bindActionCreators(tenantActions.selectTenant, dispatch),
        }
    };
};

export default compose(withTranslation("common"),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(withRouter(FrontPageRedirect));

