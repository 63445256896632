import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function workOrderTemplatesReducer(state = initialState.workOrderTemplates, action) {
    if (action.type === types.LOAD_WORKORDER_TEMPLATES_SUCCESS) {
        return action.templates;
    }
    else {
        return state;
    }
}
