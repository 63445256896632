import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function workCardDocumentReducer(state = initialState.workCardDocument, action) {
    if (action.type === types.UPDATE_TEMPLATES_SUCCESS) {
        return {
            ...state,
            templateLoaded: true
        };
    }
    else if (action.type === types.RESET_PDF_WORKCARD_SUCCESS) {
        return {
            ...initialState.workCardDocument,
            templateLoaded: state.templateLoaded
        };
    }
    else if (action.type === types.PREVIEW_PDF_WORKCARD_SUCCESS) {
        return {
            ...state,
            blob: action.pdf
        };
    }
    else if (action.type === types.RENDER_PDF_WORKCARD_DOCUMENT_SUCCESS) {
        return {
            ...state,
            rendered: true
        };
    }
    else if (action.type === types.SEND_PDF_WORKCARD_DOCUMENT_LINK_SUCCESS) {
        return {
            ...state,
            sent: true
        };
    }
    else if (action.type === types.SELECT_TENANT_SUCCESS) {
        // Changing the tenant invalidates the workcard templates
        return {
            ...state,
            templateLoaded: false
        };
    }
    else {
        return state;
    }
}
