import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from "./authorizationUtils";
import axios from "axios";

// azure functions can not handle ambigous routes
// we need explicit defined those

export const ReadChannel = (id, sharedToken, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.default) + `/api/Aggregate/ChatChannel/Get/${id.toString()}?sharedToken=${sharedToken}`, options)
        .then(handleResponse)
        .catch(handleError);
};

export const updateChannel = (data, added, deleted, sharedToken) => {
    var model = {
        channel: data,
        added: added,
        deleted: deleted,
        sharedToken: sharedToken
    };
    
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/ChatChannel/POST`, model, options)
        .then(handleResponse)
        .catch(handleError);
};

export const GetChannels = (model, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/ChatChannel/GetChannels`, model, options)
        .then(handleResponse)
        .catch(handleError);
};

export const SetMessagesCounter = (ChannelId, sharedToken) => {
    var model = {
        ChannelId: ChannelId,
        sharedToken: sharedToken,
        LastDateTime: new Date()
    };
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/ChatChannel/SetMessagesCounter`, model, options)
        .then(handleResponse)
        .catch(handleError);
};


export const GetMessagesCounter = (id, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.default) + `/api/Aggregate/ChatChannel/GetMessagesCounter/${id}`, options)
        .then(handleResponse)
        .catch(handleError);
};

export const GetMessagesCounterByTenant = (cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.default) + `/api/Aggregate/ChatChannel/GetMessagesCounterByTenant`, options)
        .then(handleResponse)
        .catch(handleError);
};
