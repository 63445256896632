import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function isSharedTokenReducer(state = initialState.isSharedToken, action) {
    if (action.type === types.IS_SHARED_TOKEN) {
        return action.isSharedToken;
    }
    else {
        return state;
    }
}