import * as types from "./actionTypes";
import * as historyEntryApi from "../../api/historyEntryApi";
import * as notifications from "./notificationsActions";
import * as constants from "../../components/common/constants";
import * as userApi from "../../api/usersApi";
import { store } from "../store";

export const readHistoryEntriesSuccess = (historyEntries) => {
    return { type: types.LOAD_HISTORY_ENTRIES_SUCCESS, historyEntries };
};

export const readHistoryEntries = (filter, sort, page, idType, tags, cancelToken) => {
    return async function(dispatch) {
        var value = await historyEntryApi
            .ReadHistoryEntries(filter, sort, page, idType, tags, cancelToken)  
            .then((result) => {
                return result;
            })
            .catch((error) => {
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notifications.toastMessage("ErrorloadingHistoryEntries", "error"));
                    throw new Error(error);
                }
            });

        var dict = {};
        value.map((element) => {
            if (element.createdBy !== "") {
                dict[element.createdBy] = "";
            }
    
            return element;
        });
   
        var batch = [];
        for(var key in dict) {
            batch.push(key);
        }

        var sendData = {
            batch: batch
        };
        await getUsersBatch(sendData, cancelToken, value, dispatch);
    };
}; 

const getUsersBatch = async(sendData, cancelToken, value, dispatch) => {
    await userApi
        .ReadUserBatch(sendData, cancelToken)  
        .then((result) => {
            for (var x = 0; x < value.length; x++) {
                var element = findElement(result, value[x].createdBy);
                if (element) {
                    value[x].createdByName = element.name;
                }
                else {
                    value[x].createdByName = "";
                }
            }

            dispatch(readHistoryEntriesSuccess(value));
        })
        .catch((error) => {
            if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                dispatch(notifications.toastMessage("ErrorLoadingUsers", "error"));
                throw new Error(error);
            }
        });
};

const findElement = (elements, id) => {
    return elements.find((e) => e.id === id);
};

export const setDataTypeHistorySuccess = (historyDataType) => {
    return { type: types.SET_DATA_TYPE_HISTORY_SUCCESS, historyDataType };
};

export const setDataTypeHistory = (type) => {
    return function(dispatch) {
        dispatch(setDataTypeHistorySuccess(type));
    };
}; 

export const updateHistoryEntry = (model, type) => {
    return function(dispatch) {
        if (store.getState().historyDataType !== "") {
            var historyModel = createModelHistory(model, type);
            if (historyModel) {
                return historyEntryApi
                    .updateHistoryEntry(historyModel)  
                    .then((result) => {
                    })
                    .catch((error) => {
                        if (!error.toString().includes("PreconditionFailed")) {
                            dispatch(notifications.toastMessage("ErrorupdatingHistoryEntry", "error"));
                        }
                   
                        throw new Error(error);
                    });
            }
        }
 
        return null;
    };
}; 

export const createModelHistory = (model, type) => {
    var eventype = "";
    if (type === constants.collections.workOrder) {
        eventype = createEventypeWorkOrder(model);
    }
    else if (type === constants.collections.channel) {
        eventype = createEventypeChannel(model);
    }
    else if (type === constants.collections.channelMembers) {
        eventype = createEventypeChannelMembers(model);
    }
    else if (type === constants.collections.workOrderDiaryEntry) {
        eventype = createEventypeAttachments(model);
    }

    var modelHistory = undefined;

    if (eventype !== "") {
        modelHistory = {
            idType: model.workOrderId ? model.workOrderId : model.id,
            dataType: store.getState().historyDataType,
            eventType: eventype,
            payload: model,
            state: "Added"
        };
    }
    
    return modelHistory;
};

const createEventypeWorkOrder = (model) => {
    var eventype = constants.eventType.WorkOrderCreated;
    if (model.state === constants.Modified) {
        if (store.getState().workOrder.assigned !== model.assigned) {
            eventype = constants.eventType.OwnerChanged;
        }
        else if (store.getState().workOrder.status !== model.status) {
            eventype = constants.eventType.StateChanged;
        }
        else if (store.getState().workOrder.workQueueId !== model.workQueueId) {
            eventype = constants.eventType.WorkQueueChanged;
        }
        else if (model.state === constants.Modified) {
            eventype = constants.eventType.WorkOrderUpdated;
        } 
    }
    else if (model.state === constants.Deleted) {
        eventype = constants.eventType.WorkOrderDeleted;
    } 
    
    return eventype;
};

const createEventypeChannel = (model) => {
    var eventype = "";
    if (model.state === constants.Added) {
        eventype = constants.eventType.ChatChannelCreated;
    } 
    
    return eventype;
};

const createEventypeChannelMembers = (model) => {
    var eventype = "";
    
    if (!!model.membersAdded && model.membersAdded.length > 0) {
        eventype = constants.eventType.ChatMemberAdded;
    }

    return eventype;
};

const createEventypeAttachments = (model) => {
    var eventype = constants.eventType.AttachmentAdded;
    if (model.state === constants.Modified) {
        eventype = constants.eventType.AttachmentUpdated;
    } 
    else if (model.state === constants.Deleted) {
        eventype = constants.eventType.AttachmentDeleted;
    }
    
    return eventype;
};
