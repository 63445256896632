import * as types from "./actionTypes";
import * as workOrderApi from "../../api/workOrderApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";
import * as constants from "../../components/common/constants";
import * as WorkQueuesApi from "../../api/workQueueApi";
import * as WorkQueueSettingsApi from "../../api/workQueueSettingsApi";
import * as CustomerApi from "../../api/customersApi";
import * as ContactApi from "../../api/contactsApi";
import * as historyEntryActions from "./historyEntryActions";
import SortArrayHelper from "../../helpers/sortArrayHelper";
import * as realTimeActions from "./realTimeActions";


export const readWorkOrdersSuccess = (workOrders) => {
    return { type: types.LOAD_WORKORDER_SUCCESS, workOrders };
};

export const readWorkOrders = (filter, sort, page, workSiteId, parentId, cancelToken, tags, filterSharingStates, filterSentStatus) => {
    return async function(dispatch) {
        dispatch(beginApiCall());
        let workorders = await workOrderApi
            .ReadWorkOrders(filter, sort, page, workSiteId, parentId, cancelToken, tags, filterSharingStates, filterSentStatus)  
            .then((result) => {
                return result;
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notifications.toastMessage("ErrorloadingWorkOrders", "error"));
                    throw new Error(error);
                }
            });
        var dict = {};
        var dictSettings = {};
        workorders.map((element) => {
            if (element.workQueueId !== constants.EmptyGuid) {
                dict[element.workQueueId] = "";
            }

            if (element.workQueueSettingsId !== constants.EmptyGuid) {
                dictSettings[element.workQueueSettingsId] = "";
            }
    
            return element;
        });
      
        var workqueues = await getWorkqueuesBatch(dict, cancelToken, dispatch,);
        var workBoard = await getWorkBoardBatch(dictSettings, cancelToken, dispatch);

        endReads(workorders, workqueues, workBoard, dispatch);
    };
}; 

const endReads = (workorders, workqueues, workqueueSettings, dispatch) => {
    for (var x = 0; x < workorders.length; x++) {
        var element = findElement(workqueues, workorders[x].workQueueId);
        if (element) {
            workorders[x].workQueueTitle = element.title;
        }
        else {
            workorders[x].workQueueTitle = "";
        }

        element = findElement(workqueueSettings, workorders[x].workQueueSettingsId);
        if (element) {
            workorders[x].workQueueSettingsName = element.name;
        }
        else {
            workorders[x].workQueueSettingsName = "";
        }
    }

    dispatch(readWorkOrdersSuccess(workorders));
};

const findElement = (element, id) => {
    return element.find((e) => e.id === id);
};

const getWorkqueuesBatch = async(dict, cancelToken, dispatch, ownerId, roles) => {
    var batch = [];
    for(var key in dict) {
        if(key !== null && key !== "null") {
            batch.push(key);
        }
    }

    if(batch.length > 0){
        return await WorkQueuesApi
            .ReadWorkQueueBatch(batch, cancelToken, ownerId, roles)  
            .then((result) => {
                return result;
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
            !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notifications.toastMessage("ErrorloadingWorkQueues", "error"));
                    throw new Error(error);
                }
            });
    }
    else{
        return [];
    }
};

const getWorkBoardBatch = async(dict, cancelToken, dispatch) => {
    var batch = [];
    for(var key in dict) {
        batch.push(key);
    }

    var sendData = {
        batch: batch
    };    
    return await WorkQueueSettingsApi
        .ReadWorkQueueSettingsBatch(sendData, cancelToken)  
        .then((result) => {
            return result;
        })
        .catch((error) => {
            dispatch(apiCallError(error));
            if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
            !error.message.includes(constants.cancelHttpRequest)) {
                dispatch(notifications.toastMessage("ErrorloadingworkQueuesSettings", "error"));
                throw new Error(error);
            }
        });
};

export const readWorkOrderSuccess = (workOrder) => {
    return { type: types.READ_WORKORDER_SUCCESS, workOrder };
};

const workOrderCache = {};

export const cacheWorkOrder = (workOrder) => {
    return async function(dispatch) {
        workOrderCache[workOrder.id] = workOrder;
    };
};

export const readCachedWorkOrder = (id) => {
    return async function(dispatch) {
        if (!!workOrderCache[id] && workOrderCache[id].id === id) {
            dispatch(readWorkOrderSuccess(workOrderCache[id]));
        }
    };
};

export const readWorkOrder = (id, cancelToken, onlyWorkOrder = false, sharedToken = "") => {
    return async function(dispatch) {
        dispatch(beginApiCall());
        dispatch(readCachedWorkOrder(id));
        var value = await workOrderApi
            .ReadWorkOrder(id, cancelToken, sharedToken)  
            .then((result) => {
                return result;
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notifications.toastMessage("ErrorloadingWorkOrder", "error"));
                    throw new Error(error);
                }
            });

        if(!onlyWorkOrder){
            await getContactsBatch(value, dispatch);  
            await getCustomerBatch(cancelToken, value, dispatch);
            await getTaskSorted(value, dispatch);
            dispatch(readWorkOrderSuccess(value));
            dispatch(cacheWorkOrder(value));
        }
            
        return value;  
    };
}; 

export const readSharedWorkOrder = (id, cancelToken, sharedToken) => {
    return async function(dispatch) {
        dispatch(beginApiCall());
        var value = await workOrderApi
            .ReadWorkOrder(id, cancelToken, sharedToken)  
            .then((result) => {
                return result;
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notifications.toastMessage("ErrorloadingWorkOrder", "error"));
                    throw new Error(error);
                }
            });

        await getContactsBatch(value, dispatch, sharedToken);  
        await getCustomerBatch(cancelToken, value, dispatch);
            
        return value;  
    };
};

const getContactsBatch = async(value, dispatch) => {
    var batch = [];
    for (let x = 0; x < value.contacts.length; x++) {
        batch.push(value.contacts[x].id);
    }

    var queryTenants = [];
    var sharedToken = "";
    if(!!value.isShared){
        sharedToken = value.sharingData.sharedToken;
        queryTenants.push(value.sharingData.sender.tenantId);
        queryTenants.push(value.sharingData.receiver.tenantId);
    }

    var sendData = { batch: batch, sharedToken: sharedToken, queryTenants: queryTenants};
    await ContactApi
        .ReadContactBatchInfo(sendData)  
        .then((result) => {
            for (let x = 0; x < value.contacts.length; x++) {
                var item = findElement(result, value.contacts[x].id);
                if (item) {
                    item.title = value.contacts[x].title;
                    value.contacts[x] = item;
                }
                else {
                    value.contacts.splice(x, 1);
                }
            }
        })
        .catch((error) => {
            dispatch(apiCallError(error));
            if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                dispatch(notifications.toastMessage("ErrorloadingContacts", "error"));
                throw new Error(error);
            }
        });
};

const getCustomerBatch = async(cancelToken, value, dispatch) => {
    let customerNumbers = [];
    for (const contacts of value.contacts) {
        if (contacts.customerNumber !== "") {
            customerNumbers.push(contacts.customerNumber);
        }
    }
    
    const sendData = {
        batch: customerNumbers
    };

    if(sendData.batch.length > 0){
        await CustomerApi
            .ReadCustomerBatch(sendData, cancelToken)  
            .then((result) => {
                for (var x = 0; x < value.contacts.length; x++) {
                    var name = result[value.contacts[x].customerNumber];
                    if (name) {
                        value.contacts[x].customer = name;
                    }
                    else {
                        value.contacts[x].customer = "";
                    }
                }
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notifications.toastMessage("ErrorloadingCustomers", "error"));
                    throw new Error(error);
                }
            });
    }
};

const getTaskSorted = async(value, dispatch) => {
    value.sortedQueueTasks = { workOrders: [] };
    if (value.workQueueTasksId) {
        await WorkQueuesApi
            .ReadWorkQueue(value.workQueueTasksId)  
            .then((result) => {
                value.sortedQueueTasks = result;
            })
            .catch((error) => {
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest) && !error.message.includes("Not Found")) {
                    dispatch(notifications.toastMessage("ErrorloadingContacts", "error"));
                    throw new Error(error);
                }
            });
    }
};

export const updateWorkOrderSuccess = (workOrder) => {
    return { type: types.UPDATE_WORKORDER_SUCCESS, workOrder };
};

export const updateWorkOrder = (data) => {
    return function(dispatch) {
        dispatch(beginApiCall());
        return workOrderApi
            .updateWorkOrder(data)  
            .then((result) => {
                let model = JSON.parse(JSON.stringify(data));
                if(model.state === constants.Added){
                    model.workOrderNumber = parseInt(result);
                    model.id = parseInt(result);
                }
                else{

                }

                dispatch(updateWorkOrderSuccess(model));
                if(!!model.isShared && model.sharingData.state !== constants.SharingState.Rejected){
                    dispatch(historyEntryActions.updateHistoryEntry(model, constants.collections.workOrder));
                }

                return result;
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                dispatch(notifications.toastMessage("ErrorupdatingWorkOrder", "error"));
                throw new Error(error);
            });
    };
}; 

export const buildMemberListSuccess = (membersTeam) => {
    return { type: types.MEMBERS_TEAM_LIST_SUCCESS, membersTeam };
};

export const buildMemberList = (model, usersGeneralInfo) => {
    return function(dispatch) {
        var membersTeam = [];
        if (model && usersGeneralInfo) {
            var membersTemp = [...model.members];
            for (var x = 0; x < membersTemp.length; x++) {
                var item = findElement(usersGeneralInfo, membersTemp[x]);
                if (item) {
                    membersTeam.push(item);
                }
            }
    
            membersTeam = membersTeam.sort(SortArrayHelper.fieldSorter(["name"]));
        }
       
        dispatch(buildMemberListSuccess(membersTeam));
    };
};

export const switchQueueSuccess = (switchModel) => {
    return {type: types.SWITCH_WORKORDER_QUEUE_SUCCESS, switch: switchModel };
};

export const switchQueue = (workOrderId, workQueueSettingsId, workQueueId, status, cancelToken) => {
    return function(dispatch) {
        var switchModel = {
            workOrderId: workOrderId, 
            workQueueSettingsId: workQueueSettingsId, 
            workQueueId: workQueueId,
            status: status === "NoStatus" ? null : status
        };

        return workOrderApi
            .switchQueue(switchModel, cancelToken)  
            .then((result) => {
                dispatch(switchQueueSuccess(switchModel));
                var message = {
                    collectionId: constants.collections.workOrder,
                    documentId: workOrderId,
                    isEdit: true,
                    workQueueSettingsId: workQueueSettingsId,
                }; 
        
                dispatch(realTimeActions.sendRealTimeMessage(message));
                if(result !== "" && result !== null){
                    var parentMessage = {
                        collectionId: "WorkOrder",
                        documentId: result.toString(),
                        isEdit: true,
                        workQueueSettingsId: workQueueSettingsId,
                    };
                    dispatch(realTimeActions.sendRealTimeMessage(parentMessage));
                }
            })
            .catch((error) => {
                dispatch(notifications.toastMessage("ErrorupdatingWorkOrder", "error"));
                throw new Error(error);
            });
    };
}; 
