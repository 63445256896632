import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Slide from "@material-ui/core/Slide";
import Badge from "@material-ui/core/Badge";
import Clear from "@material-ui/icons/Clear";
import Settings from "@material-ui/icons/Settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons/faExclamation";
import { faComments } from "@fortawesome/free-regular-svg-icons/faComments";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import ModalHelper from "../../helpers/modalHelper";

export class NotificationsPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            openPassword: false,
        };
        
        this.notificationRef = React.createRef();
    }
    
    Transition = React.forwardRef((props, ref) => {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    selectIcon = (type) => {
        if(type === "messageNotification"){
            return <FontAwesomeIcon style={{margin: 8, fontSize: "1.5rem"}} icon={faComments} />;
        }
        else{
            return <InfoOutlined style={{margin: 8, fontSize: "1.5rem"}} />;
        }
    }

    handleRoute = (message, word, idx) => {
        var link = message.payload[word].link;
        if(link !== "" && link !== undefined){
            this.props.actions.selectTenant(message.tenantId);

            if(message.type === "SharedTaskRejected"){
                var tenant = this.props.availableTenants.find((t) => t.id === message.tenantId);
                link = "/" + tenant.name + link;
            }

            // this.deleteNotification(message, idx);
            this.props.history.replace(link);
        }
    }

    markRead = (idx) => { 
        let tempNotifications = [...this.props.notifications];
        if(idx === "All"){
            tempNotifications.forEach((item) => {
                item.readStatus = true;
            });
        }
        else{
            tempNotifications[idx].readStatus = true;
        }

        this.props.updateNotificationsList(tempNotifications);
    };

    deleteNotification = (notification, idx) => {
        var tempNotifications = [...this.props.notifications];
        if(idx === "All"){
            tempNotifications = [];
            notification.type = "deleteAll";
        }
        else{
            tempNotifications.splice(idx, 1);
        }

        notification.state = "Deleted";
        this.setState({deleting: idx}, async() => {
            await this.props.actions.updateNotifications(notification, idx);
            await new Promise((r) => setTimeout(r, 500));
            this.setState({deleting: null}, () => {
                this.props.updateNotificationsList(tempNotifications);
            });
        });
    }

    unReadNotifications = () => {
        let unread = this.props.notifications.filter((item) => (item.readStatus === false)).length;
        return unread;
    }

    header = () => {
        return(
            <Grid container alignItems={this.props.notifications.length > 0 ? "flex-end" : "center"} direction="row" style={{height: 80}}>     
                <Grid xs={10} style={{marginTop: -10}} item >
                    <Badge style={{padding: 8, margin: "0px 20px"}} badgeContent={this.props.notifications.length} color="primary">
                        <FontAwesomeIcon style={{flex: 1}} icon={faExclamation} />
                    </Badge>
                    <Typography style={{display: "inline-flex", fontSize: "1.2rem", fontWeight: 600}}>{this.props.t("Notifications")}</Typography>
                </Grid>
                <Grid sm={2} item>
                    {/* <Tooltip title={this.props.t("MarkAllRead")}> */}
                    {/* <PlaylistAddCheck style={{cursor: "pointer", marginLeft: 6, padding: 6}} onClick={() => this.markRead("All")}></PlaylistAddCheck> */}
                    {/* </Tooltip> */}
                    <Tooltip title={this.props.t("NotificationSettings")}>       
                        <Settings 
                            style={{cursor: "pointer", marginLeft: 6, padding: 6}} 
                            onClick={() => this.props.openUserSettingsModal("Notifications")}></Settings>
                    </Tooltip>
                </Grid>
                {this.props.notifications.length > 0 &&
                <div style={{cursor: "pointer", marginTop: -12, marginLeft: "auto"}}>
                    <Typography 
                        style={{cursor: "pointer", float: "right", color: "#7d7d7d", marginRight: 2, textDecoration: "underline"}} 
                        onClick={(e) => this.deleteNotification({}, "All")}>
                        {this.props.t("clearAllNotifications")}
                    </Typography>
                </div>
                }
            </Grid>
        );
    }

    closeModal = () => {
        ModalHelper.closeModal(this.props.settingsModalGuid, this.props.history);
    }

    formNotificationMessage = (message, messageIndex) => {
        var regex = /{(.*?)}/;
        var words = message.description.trim().split(" ");
        return (
            <div>
                <Typography style={{fontWeight: 600}}>
                    {words.map((word, idx) => 
                        (!!word.match(regex) && !!message.payload[word] ?
                            <span key={word} style={{
                                textDecoration: message.payload[word].link ? "underline" : "none", 
                                cursor: message.payload[word].link ? "pointer" : "initial"
                            }} 
                            onClick={(e) => this.handleRoute(message, word, messageIndex)}>{message.payload[word].title}
                            </span>
                            : 
                            <span key={word} >{(idx > 0 ? (words[idx - 1].match(regex) ? " " : "") : "") + word + " "}</span>
                        )
                    )}
                </Typography>
            </div>
        );
    }

    notificationsList = () => {
        return( 
            <Grid container direction="column" justifyContent="center" alignItems="center" className="profileDrawerList" >
                {[...this.props.notifications].map((item, idx) => {
                    return(
                        <Grid container 
                            alignItems="center" 
                            direction="row" 
                            key={item.id} 
                            style={{
                                width: "100%", 
                                minHeight: 60, 
                                padding: 8, 
                                transition: "all ease-out 500ms",
                                backgroundColor: this.state.deleting === idx ? 
                                    "#e09d9d" : 
                                    (idx % 2) === 0 ? "#E4E4E4" : "white",
                            }}>
                            <Grid xs={1} item style={{marginRight: 10}}>
                                <div>
                                    {/* {!item.readStatus && <Badge style={{margin: "0px", top: -25}} variant="dot" color="primary"></Badge>} */}
                                    {this.selectIcon(item.type)}
                                </div>
                            </Grid>
                            <Grid xs={8} item style={{marginLeft: 6}}>
                                <Typography style={{color: "gray"}}>{item.title}</Typography>
                                <div style={{color: "black", wordBreak: "break-word"}}>{this.formNotificationMessage(item, idx)}</div>
                            </Grid>
                            <Grid xs={3} item style={
                                {marginLeft: -20, display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end"}
                            }>
                                <Clear style={{float: "right", cursor: "pointer"}} onClick={(e) => this.deleteNotification({...item}, idx)}></Clear>
                                <Typography style={{color: "gray", textAlign: "center"}}>
                                    {item.time}
                                </Typography>
                               
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        );
    }

    emptyNotifications = () => {
        return (
            <Grid style={{margin: "auto", padding: 12}}>
                <Typography style={{color: "black"}}>{this.props.t("NotificationsEmpty")}</Typography>
            </Grid>
        );
    }
    
    render() {
        return (
            <div id="NotificationsPanel" style={{marginBottom: "1px"}}>
                {this.header()}
                {this.props.notifications.length > 0 ? this.notificationsList() : this.emptyNotifications()}
                { this.state.openPassword && null} 
                {/* {ModalHelper.isOpen(this.props.userSettingsModalGuid, this.props.history) && 
                    <UserSettingsModal {...this.props} openedPanel={"Notifications"} handleClose={this.handleCloseUserSettingsModal}/>
                } */}
            </div>
        );
    }
}

export default (NotificationsPanel);