import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function blobProgressReducer(state = initialState.blobsProgress, action) {
    if (action.type === types.UPDATE_BLOBS_PROGRESS) {
        var data = [...state];
        data[action.blobsProgress.idx] = (action.blobsProgress.progress / action.blobsProgress.size) * 100;
        return data;
    }
    else if (action.type === types.SET_BLOBS_PROGRESS) {
        return action.blobsProgress;
    }
    else {
        return state;
    }
}
