import React, {Fragment} from "react";
import CircleButton from "../common/CircleButton";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import * as navBarStyles from "./styles.js";
import Badge from "@material-ui/core/Badge";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import PersonIcon from "@material-ui/icons/Person";
import Clear from "@material-ui/icons/Clear";
import NotificationsOff from "@material-ui/icons/NotificationsOff";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCog } from "@fortawesome/free-solid-svg-icons/faUserCog";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import NotificationsPanel from "../notifications/NotificationsPanel";
import ModalHelper from "../../helpers/modalHelper";
import UserSettingsModal from "../userManagement/userSettingsModal";

export class ProfileDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openPassword: false,
            modalKey: "profileDrawerOpen",
            notifications: this.props.notifications,
            subscriptionRenewed: false,
            openedPanel: "",
            userSettingsModalGuid: ModalHelper.newGuid()
        };
    }

    componentDidMount() {
        this.setState({notifications: this.props.notifications});
        this.props.actions.getAllAvailableTenants();
        if (this.props.user && this.props.availableTenants.length > 0) {
            this.props.actions.readNotificationSettings(this.props.user.profile, this.props.availableTenants);
        }
        
        this.props.actions.getUser(this.props.user.profile.sub);
        this.props.actions.readSubscriptionState();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.notifications !== this.props.notifications) {
            this.setState({notifications: this.props.notifications});
        }

        if (this.props.user && (!prevProps.user || this.props.user.profile.sub !== prevProps.user.profile.sub
            || this.props.availableTenants.length !== prevProps.availableTenants.length)) {
            this.props.actions.readNotificationSettings(this.props.user.profile, this.props.availableTenants);
        }

        if (!this.state.subscriptionRenewed && !!this.props.user
            && this.props.webPush.supported && !this.props.webPush.subscribed
            && !!this.props.notificationSettings.channels
            && this.props.notificationSettings.channels.webPush.enabled) {
            // Web push subscription has expired, renew it
            this.setState({ subscriptionRenewed: true });
            this.props.actions.subscribe(this.props.user.profile.sub);
        }
    }

    handleOpenUserSettingsModal = (openedPanel) => {
        this.setState({openedPanel: openedPanel}, () => {
            ModalHelper.openModal(this.state.userSettingsModalGuid, this.props.history);
        });
    };

    handleCloseUserSettingsModal = () => {
        ModalHelper.closeModal(this.state.userSettingsModalGuid, this.props.history);
    };

    handleRedirect = (link) => {
        window.location.href = link;
        this.toggleDrawer(false);
    }

    updateNotificationsList = (notifications) => {
        this.setState({notifications: notifications});
    }

    toggleDrawer = (open) => async (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }

        if(!!open){
            ModalHelper.openModal(this.state.modalKey, this.props.history);
        }
        else{
            ModalHelper.closeModal(this.state.modalKey, this.props.history);
        }
    };

    shortName = (title) => {
        let name = "";

        if (title) {
            const names = title.trim().split(" ");
            if (names.length > 0) {
                name = names[0].substring(0, 1);
                if (names.length > 1) {
                    name += names[1].substring(0, 1);
                }
            }
        } 

        return name;
    }

    profileActions = () => {
        const {t} = this.props;
        return(
            <Grid container direction="row" justifyContent="space-evenly" alignItems="flex-start" className="profileDrawerList">
                <Grid item xs={4} style={navBarStyles.profileAction}
                    id="top-menu-personal-info"
                    onClick={() => this.handleOpenUserSettingsModal("User")}>
                    <Button style={navBarStyles.profileActionButton} ><FontAwesomeIcon style={{width: "1.5rem", height: "1.5rem"}} icon={faUserCog} /></Button>
                    <Typography style={navBarStyles.profileActionText}>{t("OwnInfo")}</Typography>
                </Grid>
    
                <Grid item xs={4} style={navBarStyles.profileAction}
                    onClick={() => this.handleRedirect("/logout")}
                    id="top-menu-logout">
                    <Button style={navBarStyles.profileActionButton}>
                        <FontAwesomeIcon style={{width: "1.5rem", height: "1.5rem"}} icon={faSignOutAlt} />
                    </Button>
                    <Typography style={navBarStyles.profileActionText}>{t("Logout")}</Typography>
                
            
                </Grid>
            </Grid>
        );
    }

    renderUserSettings = () => {
        return(
            <UserSettingsModal {...this.props} openedPanel={this.state.openedPanel} handleClose={this.handleCloseUserSettingsModal}/>
        );
    }

    profileContent = () => {
        const { currentUser} = this.props;
        return(
            <div id="ProfileDrawerDiv">
                <Grid style={{display: "inline-flex", margin: "auto", padding: "10px 10px 20px 10px", alignItems: "center"}}>
                    <Clear id="profileDrawerCloseButton" 
                        onClick={this.toggleDrawer(false)} 
                        style={{position: "absolute", top: 10, right: 10}}>
                    </Clear>
                    <CircleButton
                        key={"MyAvatar"}
                        toolTipMessage={""}
                        color={"default"}
                        style={{padding: 7, margin: 10, flex: 1, width: 40, height: 40, backgroundColor: "green", color: "white"}} 
                        iconButton={<Fragment>{this.shortName(currentUser.firstName + " " + currentUser.lastName )}</Fragment>}>
                    </CircleButton>
                    <div>
                        <Typography style={{fontSize: "1.3rem", fontWeight: 600, display: "block"}}>
                            {currentUser.firstName + " " + currentUser.lastName || ""}
                        </Typography>
                        <Typography style={{fontSize: "1rem", color: "gray"}}>{currentUser.email || ""}</Typography>      
                    </div>       
                </Grid>
                {this.profileActions()}
            </div>
        );
    }

    NotificationsPanel = () => {
        return(
            <NotificationsPanel {...this.props}
                userSettingsModalGuid={this.state.userSettingsModalGuid}
                openUserSettingsModal={this.handleOpenUserSettingsModal}
                notifications={this.state.notifications}
                updateNotificationsList={this.updateNotificationsList}>
            </NotificationsPanel>
        );
    }

    render() {
        var showbadge = true;
        if (this.props.notificationSettings.settings) {
            showbadge = this.props.notificationSettings.channels.app.enabled;
        }

        return (
            <div id="ProfileDrawerRoot" style={{flex: 1, float: "right", margin: "0px 6px"}}>
                <Fragment key={"right"}>
                    <IconButton id="profileIcon" onClick={this.toggleDrawer(true)}>  
                        {!!showbadge ? 
                            <Badge style={{top: -16, right: -24}} badgeContent={this.state.notifications.length} color="primary"></Badge>
                            :
                            <Badge style={{background: "unset", top: -16, right: -26}} 
                                badgeContent={<NotificationsOff style={{color: "white", fontSize: 16}}/>} />
                        }
                       
                        <PersonIcon
                            style={{ 
                                background: "transparent", 
                                color: "white",
                                boxShadow: "none",
                                padding: 0}}
                            aria-controls="profile-menu"
                            aria-haspopup="true">
                        </PersonIcon>
                    </IconButton>
                    <Drawer id="ProfileDrawer" anchor={"right"} 
                        ModalProps={{
                            keepMounted: true
                        }}
                        disableEnforceFocus={true}
                        open={ModalHelper.isOpen(this.state.modalKey, this.props.history)} onClose={this.toggleDrawer(false)}>
                        {this.profileContent()}
                        {this.NotificationsPanel()}
                    </Drawer>
                </Fragment>
                {ModalHelper.isOpen(this.state.userSettingsModalGuid, this.props.history) && this.renderUserSettings()} 
            </div>
        );
    }
}

export default (ProfileDrawer);