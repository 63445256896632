import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function currentWorkBoardReducer(state = initialState.currentWorkBoardId, action) {
    if (action.type === types.SET_CURRENT_WORKBOARD){
        return action.currentWorkBoardId;
    }
    else {
        return state;
    }
}


