import * as types from "./actionTypes";
import * as realTimeApi from "../../api/realTimeApi";
import uuid from "uuid/v4";

// Unique identifier for the current app instance.
// This should be used to filter only those real time messages that are sent by other app instances.
export const instanceGuid = uuid();

export const realTimeMessageSuccess = (realTimeMessage) => {
    return function(dispatch) {
        if (realTimeMessage.senderId !== instanceGuid || realTimeMessage.collectionId === "WorkOrderQueueChange") {
            // Add only real time messages sent by other app instances to the redux state
            const action = { type: types.READ_RALTIME_MESSAGE_SUCCESS, realTimeMessage };
            dispatch(action);
        }
    };
};

export const realTimeConnectionLost = () => {
    return { type: types.RALTIME_CONNECTION_LOST };
};

export const realTimeConnectionSingalSuccess = (isConnectionSignalActive) => {
    return { type: types.RALTIME_CONNECTION_SIGNAL_ACTIVE, isConnectionSignalActive };
};

export const sendRealTimeMessage = (message) => {
    return function(dispatch) {
        return realTimeApi
            .sendRealTimeMessage({...message, senderId: instanceGuid})  
            .then((result) => {})
            .catch((error) => {});
    };
};
