import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function usersReducer(state = initialState.usersGeneralInfo, action) {
    if (action.type === types.LOAD_USERS_SUCCESS) {   
        return action.usersGeneralInfo;
    }
    else {
        return state;
    }
}
