import React, {Component, Fragment} from "react";
import { LayoutContext } from "../components/layout";
import { withRouter } from "react-router-dom";

class LayoutNavigationFixer extends Component {
    static contextType = LayoutContext;

    constructor(props) {
        super(props);
        this.scrollbarMeasurerRef = React.createRef();
        this.state = {
            scrollbarMeasured: false,
        };
    }

    componentDidMount() {
        const width = this.measureScrollbar();
        if (width > 0) {
            this.context.setScrollbarWidth(width);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            if (!this.state.scrollbarMeasured) {
                const width = this.measureScrollbar();
                this.context.setScrollbarWidth(width);
            }

            window.scrollTo(0, 0);
        }
    }

    measureScrollbar = () => {
        if (this.scrollbarMeasurerRef.current) {
            var scrollbarWidth = this.scrollbarMeasurerRef.current.offsetWidth - this.scrollbarMeasurerRef.current.clientWidth;
            this.setState({scrollbarMeasured: true});
            return scrollbarWidth;
        }

        return 0;
    }

    scrollbarWidthMeasurer = () => {
        return !this.state.scrollbarMeasured && (
            <div ref={this.scrollbarMeasurerRef}
                style={{
                    width: "100px",
                    height: "100px",
                    overflow: "scroll",
                    position: "absolute",
                    top: "9999px"
                }}>
            </div>);
    }
  
    render() {
        return <Fragment>
            {this.props.children}
            {this.scrollbarWidthMeasurer()}
        </Fragment>;
    }
}
  
export default withRouter(LayoutNavigationFixer);