import React, { Component, Fragment } from "react";
import {
    Grid,
    Typography,
    TextField,
    Button

} from "@material-ui/core";
import "../../index.css";
import { withTranslation } from "react-i18next";
import * as styles from "./styles.js";
import Spinner from "./CircularSpinner";
import * as usersActions from "../../redux/actions/usersActions";
import * as notificationActions from "../../redux/actions/notificationsActions";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

export class PasswordEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: this.props.userId,
            password: "",
            repeatPassword: "",
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.userId !== this.props.userId) {
            this.setState({
                userId: this.props.userId,
            });
        }
    }

    handleChange = (event) => {
        const { id, value } = event.target;
     
        this.setState((prevState) => ({
            ...prevState,
            [id]: value
        }));
    }

    handleChangePassword = async () => {
        if (!this.props.isLoading) {
            this.setState({
                isLoading: true
            });
        }

        var record = {
            id: this.props.userId,
            password: this.state.password,
            repeatPassword: this.state.repeatPassword
        };

        var isChanged = false;
        await this.props.actions.resetpasswordUser(this.state.userId, record)
            .then(() => {
                isChanged = true;
            })
            .catch(() => {})
            .finally(() =>{
                this.setState({
                    isLoading: false
                });
            });
      
        if (isChanged) {
            this.props.actions.toastMessage(this.props.t("PasswordChanged"), "success", true);
        }
    }

    content = () => {
        const { t } = this.props;
        var disabled = this.state.password.trim() === "" || (this.state.password !== this.state.repeatPassword);
        return(
            <Grid container alignItems="center" justifyContent="center" style={{margin: 8}}>
                <Grid item xs={10} >
                    <TextField
                        fullWidth={true}
                        id="password"
                        label={t("Password")}
                        type="password"
                        value={this.state.password}
                        onChange={this.handleChange}
                    />
                </Grid>
                {this.repeatPassword()}
                <Grid item xs={10}>
                    <Button 
                        style={!!disabled ? styles.disabledButton : styles.saveButton(null, null, false)}
                        disabled={!!disabled}
                        onClick={() => this.handleChangePassword()}>
                        {this.props.t("ChangePassword")}
                    </Button>
                </Grid>
            </Grid>
        );
    }

    repeatPassword = () => {
        const { t } = this.props;
        return(
            <Grid item xs={10}>
                <TextField
                    fullWidth={true}
                    id="repeatPassword"
                    label={t("RepeatPassword")}
                    type="password"
                    value={this.state.RepeatPassword}
                    onChange={this.handleChange}
                />
                <Typography id="password-helper-text" 
                    style={(this.state.password !== this.state.repeatPassword) ? 
                        { color: "rgba(255, 1, 1, 0.54)" } : {color: "transparent"}
                    }>
                    {t("PasswordDontMatch")}
                </Typography>
            </Grid>
        );
    }

    render() {
        return (
            <Fragment>
                {this.content()}
                {this.state.isLoading &&
                    <Spinner open={true}/>
                }
            </Fragment>
            
        );
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            resetpasswordUser: bindActionCreators(usersActions.resetpasswordUser, dispatch),
            toastMessage: bindActionCreators(notificationActions.toastMessage, dispatch),
        }
    };
};

export default compose(withTranslation("common"),
    connect(
        null,
        mapDispatchToProps
    ))(withRouter(PasswordEditor));

