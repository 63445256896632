/* /src/routes/privateRoute.js */
import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import LayoutSpinner from "../components/layoutSpinner";    
import LoginInitiator from "../components/auth/loginInitiator";

const RequireUserComponent = ({ component, ...rest }) => {
    const renderFn = (Component, props) => () => {
        const { user } = props;
        const ready = !!Component && !!user && !user.expired && props.accessToken;
        return <div>
            {ready &&
                <Component key={props.path + props.refreshKey} {...props} />
            }
            <LayoutSpinner show={!ready} />
            <LoginInitiator />
        </div>;
    };

    return <Route {...rest} render={renderFn(component, rest)} />;
};

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        accessToken: state.accessToken
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    };
};

export const RequireUser = connect(mapStateToProps, mapDispatchToProps)(RequireUserComponent);