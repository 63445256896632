import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function currentUserReducer(state = initialState.user, action) {
    if (action.type === types.READ_CURRENT_USER_SUCCESS) {
        var user = action.user;
        user.attributes.displayName = action.user.attributes.displayName ? action.user.attributes.displayName[0] : "" ;
        user.attributes.displayEmail = action.user.attributes.displayEmail ? action.user.attributes.displayEmail[0] : "" ;
        user.attributes.displayPhoneNumber = action.user.attributes.displayPhoneNumber ? action.user.attributes.displayPhoneNumber[0] : "" ;
        user.attributes.locale = action.user.attributes.locale ? action.user.attributes.locale[0] : "" ;
        return user;
    }
    else {
        return state;
    }
}
