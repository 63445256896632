export default class checkPermissionsHelper {
    static validatePermission = (userpermission, roleItem, permission, defaultValue) => {
        return !!userpermission && !!userpermission[roleItem] && !!userpermission[roleItem][permission];
    }

    
    static validaPermisionProps = (userpermission, roleItem, permission, defaultValue, prop) => {
        var value = true;
        if (prop !== undefined) {
            if (prop) {
                value = checkPermissionsHelper.validatePermission(userpermission, roleItem, permission, defaultValue);
            }
            else {
                value = false;
            }
        }
        else {
            value = checkPermissionsHelper.validatePermission(userpermission, roleItem, permission, defaultValue); 
        }

        return value;
    }
    
    static getRoles = (realmRoles, uniqueKey) => {
        var roles = [];
        for (var x = 0; x < realmRoles.length; x++) {
            var id = realmRoles[x];
            var prefix = uniqueKey + "_";
            if (id.includes(prefix)) {
                var name = id.replace(prefix, "");
                roles.push(name);
            }
            else {
                roles.push(id);
            }
        }  
        
        return roles;
    }
}