import React from "react";
import {Typography, Snackbar, Slide, Grid} from "@material-ui/core";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { bindActionCreators, compose } from "redux";
import * as tenantActions from "../../redux/actions/tenantActions";
import * as notificationActions from "../../redux/actions/notificationsActions";
import * as constants from "../../components/common/constants";
import axios from "axios";
import Clear from "@material-ui/icons/Clear";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-regular-svg-icons/faComments";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import { withRouter } from "react-router-dom";
import * as styles from "../common/styles";

export class Notifications extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            notification: null,
            show: false,
        };
    }

    componentDidMount = () => {
        if (!!this.props.notificationSettings.tenants) {
            this.readNotifications();
        }
    }
    
    componentDidUpdate = async (prevProps) => {
        if (this.props.notificationSettings.tenants !== prevProps.notificationSettings.tenants) {
            this.readNotifications();
        }

        if (!!this.props.tenant.id) {
            if (this.props.realTimeMessage !== prevProps.realTimeMessage && 
                !!this.props.realTimeMessage.notification &&
                this.props.notificationSettings.channels.app.enabled === true && 
                this.props.realTimeMessage.collectionId === constants.notifications &&
                this.props.realTimeMessage.notification.state === constants.Added
            ){
                if(this.props.realTimeMessage.notification.contextKey === this.props.notificationContextKey){
                    this.deleteNotification({...this.props.realTimeMessage.notification});
                }
                else{
                    var show = false;
                    [...this.props.notificationSettings.settings].every((setting) => {
                        if(setting.type === this.props.realTimeMessage.notification.type && !!setting.enabled){ 
                            show = true;
                            return false;
                        }
                        else{
                            return true;
                        }
                    });

                    if(!!show){
                        if(this.props.history.location.state && !!this.props.history.location.state.profileDrawerOpen) {
                            return;
                        }
                        
                        this.setState({notification: {...this.props.realTimeMessage.notification}, show: true}, () => {
                            this.closeTimer(this.props.realTimeMessage.notification.payload.timeout);
                        });
                    }
                }
            }
        }
    }

    readNotifications = () => {
        const cancelToken = axios.CancelToken.source();
        this.props.actions.readNotifications(
            "", 
            "time DESC", 
            0, 
            this.props.notificationSettings.tenants,
            cancelToken.token).then((result) => this.markNotificationsSent());
    }

    markNotificationsSent = () => {
        if(document.visibilityState === "visible") {
            [...this.props.notifications].forEach((notification) => {
                if(notification.channels.email.sent === false || notification.channels.webPush.sent === false){
                    var temp = JSON.parse(JSON.stringify({...notification}));
                    temp.channels.webPush.sent = true;
                    temp.channels.email.sent = true;
                    temp.state = "Modified";
                    this.props.actions.updateNotifications(temp, [notification.userId]);
                }
            });
        }
    }

    selectIcon = (type) => {
        if(type === "messageNotification"){
            return <FontAwesomeIcon style={{margin: 8, fontSize: "1.5rem"}} icon={faComments} />;
        }
        else{
            return <InfoOutlined style={{margin: 8, fontSize: "1.5rem"}} />;
        }
    }

    handleRoute = (link, message, absolute) => {
        if(link !== "" && link !== undefined){
            if(!!absolute){
                var url = new URL(link);
            }

            this.props.actions.selectTenant(message.tenantId);
            this.props.history.push(!absolute ? link : url.pathname + url.search);
            this.setState({show: false});
        }
    }

    closeTimer = async(timeout) => {
        await new Promise((r) => setTimeout(r, !!timeout ? timeout : 100000));
        this.setState({
            show: false
        });
    }

    deleteNotification = (notification) => {
        const cancelToken = axios.CancelToken.source();
        notification.state = "Deleted";
        this.setState({show: false}, async() => {
            this.props.actions.updateNotifications(notification, 0).then(() =>{
                this.props.actions.readNotifications(
                    "", 
                    "time DESC", 
                    0, 
                    this.props.notificationSettings.tenants,
                    cancelToken.token);
            });
        });
    }


    formNotificationMessage = (message) => {
        var regex = /{(.*?)}/;
        var words = message.description.trim().split(" ");
        return (
            <div>
                <Typography style={{fontWeight: 600}}>
                    {words.map((word, idx) => 
                        (!!word.match(regex) && !!message.payload[word] ?
                            <span key={word} style={{
                                textDecoration: message.payload[word].link ? "underline" : "none", 
                                cursor: message.payload[word].link ? "pointer" : "initial"
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                this.handleRoute(message.payload[word].link, message, false);
                            } 
                            }>{message.payload[word].title}
                        
                            </span>
                            : 
                            <span style={{cursor: "default"}} key={word} >{(idx > 0 ? (words[idx - 1].match(regex) ? " " : "") : "") + word + " "}</span>)
                    )}
                </Typography>
            </div>
        );
    }

    Transition = React.forwardRef((props, ref) => {
        return <Slide direction="down" ref={ref} {...props} />;
    });
    
    closeNotification = () => {
        this.setState({show: false});
    }

    render = () => {
        const {notification, show} = this.state;
        return (
            <div>
                {(notification &&
                        <div style={{marginBottom: "5px", cursor: "default"}} key={notification.type}>
                            <Snackbar
                                anchorOrigin={{vertical: "top", horizontal: "right"}}
                                open={show}
                                onClose={this.closeNotification}
                                TransitionComponent={this.Transition}
                                message={""}
                                onClick={() => this.handleRoute(notification.uri, notification, true)}
                                key={"idx"}
                            >
                                <Grid container alignItems="center" direction="row" key={notification.id} style={styles.notificationToast}>
                                    <div style={{
                                        background: "blue", 
                                        height: 62, 
                                        width: 6, 
                                        borderRadius: 6, 
                                        display: "inline-block", 
                                        verticalAlign: "middle"
                                    }}>
                                    </div>
                                    <Grid xs={2} sm={1} item style={{cursor: "pointer", marginRight: 8}}>
                                        <div style={{display: "inline-block", margin: 6, marginLeft: 1, verticalAlign: "middle"}}>
                                            {this.selectIcon(notification.type)}
                                        </div>
                                    </Grid>
                                    <Grid xs={9} sm={10} item style={{marginRight: -20, marginLeft: 4}}>
                                        <Typography style={{color: "gray"}}>{notification.title}</Typography>
                                        <div style={{color: "black", wordBreak: "break-word"}}>{this.formNotificationMessage(notification)}</div>
                                    </Grid>
                                    <Grid xs={1} item style={{textAlign: "right", marginRight: -6}}>
                                        <Clear  
                                            style={{cursor: "pointer"}} 
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.deleteNotification({...notification});
                                            }}></Clear>
                                    </Grid>
                                </Grid>
                            </Snackbar>
                        </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        notifications: state.notifications,
        tenant: state.tenant,
        user: state.user,
        realTimeMessage: state.realTimeMessage,
        notificationSettings: state.notificationSettings,
        notificationContextKey: state.notificationContextKey
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            selectTenant: bindActionCreators(tenantActions.selectTenant, dispatch),
            clearToasts: bindActionCreators(notificationActions.clearToasts, dispatch),
            readNotifications: bindActionCreators(notificationActions.readNotifications, dispatch),
            updateNotifications: bindActionCreators(notificationActions.updateNotifications, dispatch),
        }
    };
};


export default compose(
    withTranslation("common"),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(withRouter(Notifications));

