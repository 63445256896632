import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function catalogDefaultsReducer(state = initialState.catalogDefaults, action) {
    if (action.type === types.READ_CATALOG_DEFAULTS_SUCCESS) {
        return action.catalogDefaults;
    }
    else {
        return state;
    }
}
