import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function catalogTitleReducer(state = initialState.catalogTitle, action) {
    if (action.type === types.READ_CATALOG_TITLE_SUCCESS) {
        return action.catalogTitle;
    }
    else {
        return state;
    }
}
