import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function historyDataTypeReducer(state = initialState.historyDataType, action) {
    if (action.type === types.SET_DATA_TYPE_HISTORY_SUCCESS) {
        return action.historyDataType;
    }
    else {
        return state;
    }
}
