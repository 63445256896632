import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from "./authorizationUtils";
import axios from "axios";

export const readNotifications = (filter, sort, page, tenants, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    var data = {
        filter: filter,
        tenants: tenants ? tenants : [],
        page: page,
        sort: sort ? sort : "Time ASC",
    };
  
    return axios.post(getApiUrl(serviceKeys.default) + "/api/Aggregate/Notifications/GetAll", data, options)
        .then(handleResponse)
        .catch(handleError);
};

export const updateNotifications = (model) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.default) + "/api/Aggregate/Notifications/POST", model, options)
        .then(handleResponse)
        .catch(handleError);
};

export const updateNotificationSettings = (model) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.default) + "/api/Aggregate/NotificationSettings/POST", model, options)
        .then(handleResponse)
        .catch(handleError);
};

export const readSettings = (id) => {
    const options = getRequestOptions();
    return axios.get(getApiUrl(serviceKeys.default) + `/api/Aggregate/NotificationSettings/${id}`, options)
        .then(handleResponse)
        .catch(handleError);
};

export const confirmNotification = (model) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.default) + "/api/Aggregate/Notifications/Confirm", model, options)
        .then(handleResponse)
        .catch(handleError);
};

export const updateWebPushSubscription = (settingsId, subscription) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/NotificationSettings/${settingsId}/UpdateWebPushSubscription`, subscription, options)
        .then(handleResponse)
        .catch(handleError);
};
