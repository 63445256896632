import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Container } from "@material-ui/core";
import TopNavBar from "./Navbar/TopNavBar";
import SideNavBar from "./Navbar/SideNavBar";
import {maximizedWidth} from "./Navbar/SideNavBar";
import BackDrop from "./Backdrop/Backdrop";
import { ProtectedContent } from "./auth/protectedContent";
import Toasts from "./Toasts";
import Notifications from "./notifications/Notifications";
import "../index.css";
import "normalize.css";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { RealTimeMessages } from "./realTimeMessages";
import { getAppInsights } from "../services/TelemetryService";
import TelemetryProvider from "../components/telemetry-provider";
import AppInsightsHelper from "../helpers/appInsightsHelper";
import { isTouchDevice } from "../helpers/touchHelpers";
import Spinner from "./common/CircularSpinner";
import CatalogsAll from "./common/catalogs/catalogsAll";
import ErrorModal from "./errorModal";
import { withRouter } from "react-router";
import navitemsCore from "../utils/navItemsCore.json";

// This is the default height of the top navigation bar
const topNavBarHeight = 56;

export const MenuStyles = {
    Hamburger: "Hamburger",
    Static: "Static"
};

const initialMeasures = {
    width: window.innerWidth,
    height: window.innerHeight,
    topNavBarHeight: topNavBarHeight,
    menuWidth: maximizedWidth,
    bodyHeight: (window.innerHeight - topNavBarHeight),
    contentHeight: window.innerHeight - topNavBarHeight,
    contentWidth: window.innerWidth - maximizedWidth,
    touchDevice: isTouchDevice(),
    scrollbarPlaceholder: "0px",
    sideBarAvailable: false
};

export const LayoutContext = React.createContext({
    ...initialMeasures,
    showSpinner: () => {}, 
    hideSpinner: () => {},
    setScrollbarWidth: () => {},
    scrollbarPlaceholder: "0px",
});
export class Layout extends Component {
    bhistory= null;
    lastLocationPathName = "";
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            menuStyle: MenuStyles.Static,
            sideDrawerOpen: false,
            measures: initialMeasures,
            spinnerCounter: 0,
            hasError: false,
            error: null,
            info: null,
            scrollbarWidth: 0,
            scrollbarPlaceholder: "0px",
            highlighted: "",
            sideBarAvailable: false
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    drawerClickHandler = () => {
        this.setState((prevState) => {
            return { 
                sideDrawerOpen: !prevState.sideDrawerOpen
            };
        });
    };

    showSpinner = () => {
        this.setState((prevState) => ({ 
            spinnerCounter: prevState.spinnerCounter + 1 
        }));
    }

    hideSpinner = () => {
        this.setState((prevState) => ({ 
            spinnerCounter: prevState.spinnerCounter - 1 
        }));
    }


    updateWindowDimensions(singleTry) {
        // Use hambuger menu always in touch devices
        const touchDevice = isTouchDevice();
        const menuStyle = (!!touchDevice) ? MenuStyles.Hamburger : MenuStyles.Static;
        const contentHeight = window.innerHeight - topNavBarHeight;
        const menuWidth = menuStyle === MenuStyles.Static ? maximizedWidth : 0;

        const measures = {
            width: window.innerWidth,
            height: window.innerHeight,
            topNavBarHeight: topNavBarHeight,
            menuWidth: !!this.state.sideBarAvailable ? menuWidth : 0,
            bodyHeight: (window.innerHeight - topNavBarHeight),
            contentHeight: contentHeight,
            contentWidth: window.innerWidth - menuWidth,
            touchDevice
        };

        this.setState({ 
            measures,
            menuStyle
        });

        // Update dimensions again in a while, because in iphone dimensions are really updated only after resize event
        if (!singleTry) {
            setTimeout(() => {
                this.updateWindowDimensions(true);
                setTimeout(() => {
                    this.updateWindowDimensions(true);
                    setTimeout(() => {
                        this.updateWindowDimensions(true);
                    }, 2000);
                }, 1000);
            }, 200);
        }
    }
    
    componentDidMount() {
        this.updateWindowDimensions(true);
        window.addEventListener("resize", this.updateWindowDimensions); 
        this.bhistory = this.props.history.listen(this.onRouteChange);
        this.setHighlighted();
        this.setSideNavAvailability(this.props.history.location.pathname.toLowerCase());  
    }

    componentDidUpdate(prevProps) {
        if(prevProps.location.pathname !== this.props.location.pathname){
            this.setSideNavAvailability(this.props.history.location.pathname.toLowerCase());
        } 
    }

    componentWillUnmount() {
        if (this.bhistory) {
            this.bhistory();
        }

        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    onRouteChange = () => {
        if (this.props.history.action === "POP") {
            if (this.lastLocationPathName !== this.props.history.location.pathname) {
                this.setHighlighted();
            }
        }

        this.lastLocationPathName = this.props.history.location.pathname;    
        this.setSideNavAvailability(this.props.history.location.pathname.toLowerCase());  
    }

    setHighlighted = () => {
        let pathname = this.props.history.location.pathname;
        let link = navitemsCore.items.find((e) => (e.Link !== "/" && pathname.includes(e.Link)));
        if (link) {
            this.setState({
                highlighted: pathname.includes("workBoards") ? "WorkBoard" : link.id
            });
        }

        this.lastLocationPathName = this.props.history.location.pathname;  
    }

    cleanHighlighted = () => {
        this.setState({
            highlighted: ""
        });
    }

    componentDidCatch(error, info) {
        this.setState({ 
            hasError: true,
            error: error,
            info: info,
        });

        AppInsightsHelper.trackEvent("Error modal shown");
        AppInsightsHelper.trackException(error);

        if (info.componentStack) {
            AppInsightsHelper.trackCustomException("render_error", error.message, info.componentStack);
        }
    }

    backdropClickHandler = () => {
        this.setState({ sideDrawerOpen: false });
    };

    setScrollbarWidth = (width) => {
        if (width !== this.state.scrollbarWidth) {
            this.setState({
                scrollbarWidth: width,
                scrollbarPlaceholder: width === 0 ? "0px" : "calc(" + width + "px + 100% - 100vw)"
            });
        }
    };

    setSideNavAvailability = (route) => {
        const routesArray = [
            "/formtemplates", 
            "/timesheets", 
            "/customers", 
            "/workboards", 
            "/contacts", 
            "/sharedtasks", 
            "/worksites", 
            "/productsregister", 
            "/productgroups", 
            "/productunits",
            "/usersmanagement",
            "/roles"
        ];
        let isValidRoute = false;
        isValidRoute = routesArray.some((element) => {
            return route.endsWith(element);
        });

        this.setState((prevState) => ({
            sideBarAvailable: isValidRoute, 
            measures: {...prevState.measures, menuWidth: isValidRoute && !isTouchDevice() ? 212 : 0}
        }));
    };

    realTime = () => {
        return (
            <Fragment>
                {!!this.props.tenant.id && <RealTimeMessages 
                    isSessionActive={this.props.isSessionActive}
                    tenant={this.props.tenant}
                    accessToken={this.props.accessToken }
                    userExpired={this.props.accessToken }>
                </RealTimeMessages>}
            </Fragment>
        );
    }

    catalogs = () => {
        return (
            <ProtectedContent requireTenant={true}>
                <CatalogsAll />
            </ProtectedContent>
        );
    }

    renderTelemtry = () => {
        return (
            <Fragment>
                <TelemetryProvider user={this.props.oidc.user} tenant={this.props.tenant} after={() => { 
                    AppInsightsHelper.appInsights = getAppInsights(); 
                }}>
                    {this.state.hasError ? (
                        <ErrorModal error={this.state.error} info={this.state.info} />
                    ) : this.props.children}
                </TelemetryProvider>
                
            </Fragment>
        );
    }

    renderTopBar = () => {
        return(
            <ProtectedContent>
                <TopNavBar 
                    staticMenu={this.state.menuStyle === MenuStyles.Static}
                    drawerClickHandler={this.drawerClickHandler} 
                    close={this.backdropClickHandler}
                    width={this.state.measures.width}
                    height={this.state.measures.topNavBarHeight}
                    sideBarAvailable={this.state.sideBarAvailable}
                    showBackButton={!this.state.sideBarAvailable}
                    scrollbarPlaceholder={this.state.scrollbarPlaceholder} />
                {!!this.state.sideBarAvailable && <SideNavBar
                    staticMenu={this.state.menuStyle === MenuStyles.Static}
                    width={this.state.measures.width}
                    history={this.props.history}
                    menuWidth={this.state.menuWidth}
                    show={this.state.sideDrawerOpen}
                    close={this.backdropClickHandler}
                    highlighted={this.state.highlighted}
                    cleanHighlighted={this.cleanHighlighted} />
                }
            </ProtectedContent>
        );
    }

    render() {
        let backdrop;
        if (this.state.sideDrawerOpen) {
            backdrop = <BackDrop click={this.backdropClickHandler} />;
        }

        return (
            <LayoutContext.Provider value={{
                ...this.state.measures,
                showSpinner: this.showSpinner,
                hideSpinner: this.hideSpinner,
                forceUpdate: this.updateWindowDimensions,
                setScrollbarWidth: this.setScrollbarWidth,
                scrollbarWidth: this.state.scrollbarWidth,
                scrollbarPlaceholder: this.state.scrollbarPlaceholder
            }}>
                {this.renderTopBar()}
                {backdrop}
                <Spinner open={this.state.spinnerCounter > 0 && !this.state.hasError} />
                <Container maxWidth={false} 
                    style={{ 
                        position: "absolute",
                        height: "auto",
                        width: "auto",
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        overflow: "visible", 
                        padding: "0 0",
                        paddingTop: this.state.measures.topNavBarHeight,
                    }}
                    disableGutters={this.state.menuStyle === MenuStyles.Static ? false : true}
                >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        {this.renderTelemtry()}
                    </MuiPickersUtilsProvider>
                    <Notifications />
                    <Toasts />
                    {this.realTime()}
                    {this.catalogs()}
                </Container>
            </LayoutContext.Provider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isSessionActive: state.isSessionActive,
        tenant: state.tenant,
        oidc: state.oidc,
        currentUser: state.currentUser,
        tenantId: state.tenant,
        accessToken: state.accessToken,
        navigation: state.navigation
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Layout));
