import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function customersReducer(state = initialState.customers, action) {
    if (action.type === types.LOAD_CUSTOMER_SUCCESS) {
        return action.customers;
    }
    else {
        return state;
    }
}
