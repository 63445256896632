import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function tenantEditableReducer(state = initialState.tenant, action) {
    if (action.type === types.READ_TENANT_EDITABLE_SUCCESS) {
        return action.tenant;
    }
    else {
        return state;
    }
}
