import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from "./authorizationUtils";
import axios from "axios";
import * as constants from "../components/common/constants";
import FilterHelper from "../helpers/filterHelper"; 

// azure functions can not handle ambigous routes
// we need explicit defined those

export const ReadRoles = (filter, sort, page, cancelToken, tags) => {
    const options = getRequestOptions(cancelToken);
    var data = {
        filter: filter,
        sort: sort,
        page: page,
        searchFields: FilterHelper.getSearchfilter(constants.roleFilter),
        filterTags: tags,
    };

    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/Roles/GetAll`, data, options)
        .then(handleResponse)
        .catch(handleError);
};

export const ReadRolesBatch = (batch, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/Roles/GetBatch`, batch, options)
        .then(handleResponse)
        .catch(handleError);
};

export const ReadRole = (id, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.default) + `/api/Aggregate/Roles/Get/${id}`, options)
        .then(handleResponse)
        .catch(handleError);
};

export const updateRole = (role) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/Roles/POST`, role, options)
        .then(handleResponse)
        .catch(handleError);
};
