import * as types from "./actionTypes";
import * as WorkQueuesApi from "../../api/workQueueApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notificationActions from "./notificationsActions";
import * as constants from "../../components/common/constants";
import * as workOrderActions from "./workOrderActions";

export const setCurrentWorkQueue = (currentWorkQueueIndex) => {
    localStorage.setItem("currentWorkQueueIndex", currentWorkQueueIndex);
    return { type: types.SET_CURRENT_WORKQUEUE, currentWorkQueueIndex };
};

export const readWorkQueueSuccess = (workQueue) => {
    return { type: types.READ_WORKQUEUE_SUCCESS, workQueue };
};

export const readWorkQueueBatchSuccess = (workQueues) => {
    return { type: types.READ_WORKQUEUES_SUCCESS, workQueues };
};

const reduceToIdHashMap = (map, item) => {
    map[item.id] = item;
    return map;
};

export const readWorkQueue = (id, cancelToken) => {
    return function(dispatch) {
        dispatch(beginApiCall());
        return WorkQueuesApi
            .ReadWorkQueue(id, cancelToken)  
            .then((result) => {
                dispatch(readWorkQueueSuccess(result));
                return result;
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notificationActions.toastMessage("ErrorloadingWorkQueue", "error"));
                    throw new Error(error);
                }
            });
    };
}; 

export const createWorkQueue = (model, workQueueId, workBoardId) => {
    return function(dispatch) {
        const state = constants.Added;

        var workQueueModel = {
            id: workQueueId,
            title: model.title,
            workBoardId: workBoardId,
            workQueueSettingsId: workBoardId,
            ownerId: model.ownerId,
            workOrders: [],
            roles: model.roles,
            defaultStatus: model.defaultStatus,
            cardColor: model.cardColor,
            allowCreateCards: model.allowCreateCards,
            state: state,
        };

        dispatch(updateWorkQueue(workQueueModel, (workBoardId ? true : false)));
    };
};

export const startUpdateWorkQueue = (workQueue) => {
    return { type: types.START_UPDATE_WORKQUEUE, workQueue };
};

export const updateWorkQueueSuccess = (workQueue) => {
    return { type: types.UPDATE_WORKQUEUE_SUCCESS, workQueue };
};

export const updateWorkQueue = (model, notification) => {
    return function(dispatch) {
        dispatch(startUpdateWorkQueue(model));
        dispatch(beginApiCall());
        return WorkQueuesApi
            .updateWorkQueue(model)  
            .then((result) => {
                dispatch(updateWorkQueueSuccess(result));
                if(!!notification){
                    dispatch(notificationActions.toastMessage("NewQueueCreated", "success", false, 3000, model.title));
                }
                
                return result;
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                dispatch(notificationActions.toastMessage("ErrorUpdatingWorkQueue", "error"));
                throw new Error(error);
            });
    };
}; 

export const ReadWorkQueueBatch = (batch, cancelToken, ownerId, roles) => {
    return function(dispatch) {
        dispatch(beginApiCall());
        return WorkQueuesApi
            .ReadWorkQueueBatch(batch, cancelToken, ownerId, roles)  
            .then((result) => { 
                if (result) {
                    dispatch(readWorkQueueBatchSuccess(result.reduce(reduceToIdHashMap, {})));
                }

                return result;
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notificationActions.toastMessage("ErrorloadingWorkQueues", "error"));
                    throw new Error(error);
                }
            });
    };
}; 

export const editWorkQueue = (model) => {
    return function(dispatch) {
        dispatch(updateWorkQueues({ queue: model, state: constants.Inserted }));
    };
}; 

export const updateWorkQueues = (model) => {
    return function(dispatch) {
        dispatch(beginApiCall());
        return WorkQueuesApi
            .UpdateWorkqueues(model)  
            .then((result) => {
                if (model.queue) {
                    dispatch(readWorkQueue(model.queue.id));
                }

                return result;
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                dispatch(notificationActions.toastMessage("ErrorUpdatingWorkQueue", "error"));
                throw new Error(error);
            });
    };
}; 

export const moveWorkOrderInQueues = (workOrderId, toWorkQueueId, fromWorkBoardId, fromWorkQueueId, toWorkBoardId, position, status) => {
    return async function(dispatch) {
        var state = constants.Inserted;
        if (fromWorkQueueId === toWorkQueueId) {
            state = constants.ReOrder;
        }

        var data = {
            workQueueIdTo: toWorkQueueId,
            workOrderNumber: workOrderId,
            position: position,
            workQueueSettingsIdTo: toWorkBoardId,
            workQueueSettingsIdCrossFrom: fromWorkBoardId,
            queue: null,
            state: state
        }; 
      
        var updateAction = updateWorkQueues(data);
        var orderSwitchQueue = workOrderActions.switchQueue(workOrderId, toWorkBoardId, toWorkQueueId, status);
       
        dispatch(orderSwitchQueue);
        return dispatch(updateAction);
    };
};

export const deleteWorkOrderInQueues = (workOrderId, fromWorkQueueId, fromWorkBoardId) => {
    return async function(dispatch) {
        const state = constants.Deleted;

        var data = {
            workQueueIdTo: fromWorkQueueId,
            workOrderNumber: workOrderId,
            position: -1,
            workQueueSettingsIdTo: fromWorkBoardId,
            workQueueSettingsIdCrossFrom: "00000000-0000-0000-0000-000000000000",
            queue: null,
            state: state
        }; 
       
        const updateAction = updateWorkQueues(data);
        dispatch(updateAction);
    };
};


export const updateWorkQueuesData = (model) => {
    return function(dispatch) {
        dispatch(beginApiCall());
        return WorkQueuesApi
            .UpdateWorkqueuesData(model)  
            .then((result) => {
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                dispatch(notificationActions.toastMessage("ErrorUpdatingWorkQueueData", "error"));
                throw new Error(error);
            });
    };
};

