
import React from "react";
import { connect } from "react-redux";
import BackDrop from "../Backdrop/Backdrop";
import { SignoutCallbackComponent } from "redux-oidc";
import userManager from "../../services/userManager";
import { bindActionCreators } from "redux";
import * as sessionActions from "../../redux/actions/sessionActions";

export class LogoutCallbackPage extends React.Component {
    handleLogout() {
        this.props.actions.cleanAfterLogout();
        this.props.history.replace("/");
    }

    render() {
        return (
            <SignoutCallbackComponent
                userManager={userManager}
                successCallback={() => {
                    this.handleLogout();
                }}
                errorCallback={(error) => {
                    this.props.history.push("/error");
                }}>
                <BackDrop fullscreen />
            </SignoutCallbackComponent>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tenant: state.tenant
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            cleanAfterLogout: bindActionCreators(sessionActions.cleanAfterLogout, dispatch),
        }
    };
};

export const LogoutCallback = connect(mapStateToProps, mapDispatchToProps)(LogoutCallbackPage);