import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function pendingRouterReducer(state = initialState.pendingRouter, action) {
    if (action.type === types.PENDING_ROUTER_SUCCESS) { 
        return action.pendingRouter;
    }
    else {
        return state;
    }
}