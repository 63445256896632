import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function sessionMetadataReducer(state = initialState.sessionMetadata, action) {
    if (action.type === types.BEGIN_LOGIN) {
        return {
            ...state,
            loginInProgress: true,
        };
    }
    else if (action.type === types.LOGIN_SUCCESS) {
        return {
            ...state,
            loginInProgress: false,
        };
    }
    else if (action.type === types.BEGIN_LOGOUT) {
        return {
            ...state,
            logoutInProgress: true,
        };
    }
    else if (action.type === types.LOGOUT_SUCCESS) {
        return {
            ...state,
            logoutInProgress: false,
        };
    }
    else {
        return state;
    }
}
