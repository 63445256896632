import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from "./authorizationUtils";
import axios from "axios";
import * as constants from "../components/common/constants";
import FilterHelper from "../helpers/filterHelper"; 

// azure functions can not handle ambigous routes
// we need explicit defined those

export const ReadUsersGeneralInfo = (filter, sort, page, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.users) + `/api/Aggregate/User/GetAllGeneralInfo?filter=${filter}&sort=${sort}&page=${page}`, options)
        .then(handleResponse)
        .catch(handleError);
};

export const ReadUserBatch = (batch, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.post(getApiUrl(serviceKeys.users) + `/api/Aggregate/User/GetBatch`, batch, options)
        .then(handleResponse)
        .catch(handleError);
};

export const ReadUserDisplayInfoBatch = (batch, tenant, cancelToken) => {
    var dto = {
        batch: batch,
        tenant: tenant
    };
    
    const options = getRequestOptions(cancelToken);
    return axios.post(getApiUrl(serviceKeys.users) + `/api/Aggregate/User/GetDisplayInfoBatch`, dto, options)
        .then(handleResponse)
        .catch(handleError);
};

export const ReadUsersGetAll = (filter, sort, page, cancelToken) => {
    var data = {
        filter: filter,
        sort: sort,
        page: page,
        searchFields: FilterHelper.getSearchfilter(constants.usersManagementFilter)
    };

    const options = getRequestOptions(cancelToken);
    return axios.post(getApiUrl(serviceKeys.users) + `/api/Aggregate/User/GetAll`, data, options)
        .then(handleResponse)
        .catch(handleError);
};

export const GetUser = (id, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.users) + `/api/Aggregate/User/Get/${id}`, options)
        .then(handleResponse)
        .catch(handleError);
};

export const CheckUsername = (username) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.users) + `/api/Aggregate/User/checkusername?username=${username}`, null, options)
        .then(handleResponse)
        .catch(handleError);
};

export const createUser = (user) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.users) + `/api/Aggregate/User/createuser`, user, options)
        .then(handleResponse)
        .catch(handleError);
};

export const updateUser = (id, user) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.users) + `/api/Aggregate/User/updateuser/${id}`, user, options)
        .then(handleResponse)
        .catch(handleError);
};

export const deleteUser = (id) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.users) + `/api/Aggregate/User/deleteuser/${id}`, null, options)
        .then(handleResponse)
        .catch(handleError);
};

export const resetpassword = (id, data) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.users) + `/api/Aggregate/User/resetpassword/${id}`, data, options)
        .then(handleResponse)
        .catch(handleError);
};

export const updateRole = (role) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.users) + `/api/Aggregate/User/updateRole`, role, options)
        .then(handleResponse)
        .catch(handleError);
};

export const updateUserRoles = (roles) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.users) + `/api/Aggregate/User/UpdateUserRoles`, roles, options)
        .then(handleResponse)
        .catch(handleError);
};

export const createPersonalRootGroup = () => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.users) + `/api/Aggregate/User/createPersonalRootGroup`, null, options)
        .then(handleResponse)
        .catch(handleError);
};

export const GetEmailUserById = (id, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.users) + `/api/Aggregate/User/GetEmailUserById/${id}`, options)
        .then(handleResponse)
        .catch(handleError);
};

export const GetUserByEmail = (username, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.users) + `/api/Aggregate/User/GetUserByEmail/${username}`, options)
        .then(handleResponse)
        .catch(handleError);
};

export const CreatePersonalInvitationToken = (tenantRootGroup, invitedEmail, anonymousId, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.users) 
    + `/api/Aggregate/User/CreatePersonalInvitationToken?tenantRootGroup=${tenantRootGroup}&invitedEmail=${invitedEmail}&anonymousId=${anonymousId}`, options)
        .then(handleResponse)
        .catch(handleError);
};

export const JoinWithToken = (tenantRootGroup, token, anonymousId, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    const data = {
        groupPath: tenantRootGroup,
        token,
        anonymousId
    };
    return axios.post(getApiUrl(serviceKeys.users) + `/api/Aggregate/User/JoinWithToken`, data, options)
        .then(handleResponse)
        .catch(handleError);
};

export const AddNewRootGroup = (userid, rootGroupPath, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    var model = {
        userid: userid,
        rootGroupPath: rootGroupPath
    };

    return axios.post(getApiUrl(serviceKeys.users) + `/api/Aggregate/User/AddNewRootGroup`, model, options)
        .then(handleResponse)
        .catch(handleError);
};

export const GetUserCount = (cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.users) + `/api/Aggregate/User/GetCount`, options)
        .then(handleResponse)
        .catch(handleError);
};