import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function formTemplatesReducer(state = initialState.formTemplates, action) {
    if (action.type === types.LOAD_FORM_TEMPLATES_SUCCESS) {
        return action.formTemplates;
    }
    else {
        return state;
    }
}
