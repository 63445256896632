import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function filterWorkBoardReducer(state = initialState.filterWorkBoard, action) {
    if (action.type === types.SET_FILTER_WORKBOARD) {
        return action.filterWorkBoard;
    }
    else {
        return state;
    }
}