import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from "./authorizationUtils";
import axios from "axios";
import * as constants from "../components/common/constants";
import FilterHelper from "../helpers/filterHelper"; 
import { store } from "../redux/store";

export const ReadFormTemplates = (filter, sort, page, tags, cancelToken) => {
    const userid = store.getState().oidc.user ? store.getState().oidc.user.profile.sub : "";
    const options = getRequestOptions(cancelToken);
    var data = {
        filter: filter,
        sort: sort,
        page: page,
        searchFields: FilterHelper.getSearchfilter(constants.formTemplateFilter + userid),
        filterTags: tags
    };
    
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/FormTemplates/GetAll`, data, options)
        .then(handleResponse)
        .catch(handleError);
};

export const ReadFormTemplate = (id, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.default) + `/api/Aggregate/FormTemplates/Get/${id}`, options)
        .then(handleResponse)
        .catch(handleError);
};

export const updateFormTemplate = (productGroup) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/FormTemplates/POST`, productGroup, options)
        .then(handleResponse)
        .catch(handleError);
};

export const ReadFormTemplateBatch = (batch, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/FormTemplates/GetBatch`, batch, options)
        .then(handleResponse)
        .catch(handleError);
};

