import React, { Fragment } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import "../../components/Navbar/NavBar.css";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { withStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { compose } from "redux";
import { v4 as uuidv4 } from "uuid";
import env from "../../utils/env";
import checkPermissions from "../../helpers/checkPermissions";
import * as constants from "../common/constants";
import { tenantRoutePrefix } from "../../helpers/tenantHelper";

const colorActive = "#2E2B2B";
const colorInactive = "#2E2B2B";
const backgroundActive = "#efefef";
const backgroundInactive = "unset";

const styles = (theme) => ({
    root: {
        "-webkit-box-sizing": "unset  !important",
        "-moz-box-sizing": "unset  !important",
        "box-sizing": "unset !important",
        width: "100%",
        maxWidth: 260,
        colorPrimary: colorInactive,
        colorSecondary: colorInactive,
        fontFamily: "\"Open Sans\", \"Roboto\", \"Oxygen\", sans-serif",
        marginLeft: "-4rem",
        maxHeight: 200,
        fontSize: 16,
        minHeight: "48px",
    },
    nested: {
        paddingLeft: theme.spacing(5),
        fontSize: 14
    },
    listIcon: {
        marginLeft: "-2rem",
        colorPrimary: colorInactive,
    },
    menu: {
        color: colorInactive,
        background: "#333333",
    }
});

export class NestedList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            result: " ",
            isLoading: false,
            anchorEl: null,
            currentPage: "",
            host: "",
            faSiconsLoaded: false,
        };

        this.faSicons = {};
    }

    componentDidUpdate(prevProps, prevState){ 
        if(this.props.tenant){
            const { tenant } = this.props;
            if (prevProps.tenant !== tenant) {
                if (tenant) {
                    this.setState({ host: tenant.host });
                }
            }
        }

        if (prevProps.pendingRouter !== this.props.pendingRouter && this.props.pendingRouter.goMenuOption) {
            this.handleRoute({}, {
                Link: this.props.pendingRouter.pendingRouter,
                name: this.props.pendingRouter.name
            });
        }
    }

    componentDidMount = () => {
        const faSiconsPromise = Promise.all([
            import("@fortawesome/free-solid-svg-icons/faColumns"),
            import("@fortawesome/free-solid-svg-icons/faMap"),
            import("@fortawesome/free-solid-svg-icons/faAddressBook"),
            import("@fortawesome/free-solid-svg-icons/faUser"),
            import("@fortawesome/free-solid-svg-icons/faBox"),
            import("@fortawesome/free-solid-svg-icons/faBoxes"),
            import("@fortawesome/free-solid-svg-icons/faObjectGroup"),
            import("@fortawesome/free-solid-svg-icons/faCalendarWeek"),
            import("@fortawesome/free-solid-svg-icons/faUsersCog"),
            import("@fortawesome/free-solid-svg-icons/faTable"),
            import("@fortawesome/free-solid-svg-icons/faFileInvoice"),
            import("@fortawesome/free-brands-svg-icons/faCreativeCommonsShare"),
            import("@fortawesome/free-solid-svg-icons/faUserClock"),
        ]);
        faSiconsPromise.then((faSicons) => {
            this.faSicons["faColumns"] = faSicons[0].definition;
            this.faSicons["faMap"] = faSicons[1].definition; 
            this.faSicons["faAddressBook"] = faSicons[2].definition;
            this.faSicons["faUser"] = faSicons[3].definition;
            this.faSicons["faBox"] = faSicons[4].definition;
            this.faSicons["faBoxes"] = faSicons[5].definition;
            this.faSicons["faObjectGroup"] = faSicons[6].definition;
            this.faSicons["faCalendarWeek"] = faSicons[7].definition; 
            this.faSicons["faUsersCog"] = faSicons[8].definition;     
            this.faSicons["faTable"] = faSicons[9].definition;     
            this.faSicons["faFileInvoice"] = faSicons[10].definition;       
            this.faSicons["faCreativeCommonsShare"] = faSicons[11].definition;      
            this.faSicons["faUserClock"] = faSicons[12].definition;                  
            this.setState({ faSiconsLoaded: true });
        });
    };
  
  handleClick = (e, item) => {
      this.setState({ [e]: !this.state[e]});
  }

  handleRoute = (e, item) => {
      if (!this.props.pendingRouter.stopRouter) {
          let pathName = item.id ? tenantRoutePrefix(this.props.tenant) + item.Link : item.Link;
          this.props.actions.setModule({ 
              url: pathName, 
              name: item.name}, this.props.tenant);

          this.props.history.push(pathName, {clickIndicator: uuidv4()});
          this.props.select(item.id ? item.id : item.name);
          this.props.close();
          this.props.actions.pendingRouterProgress({
              pendingRouter: "",
              name: "",
              stopRouter: false,
              continueSaving: false,
              goMenuOption: false
          });
      }
      else {
          this.props.actions.pendingRouterProgress({
              pendingRouter: tenantRoutePrefix(this.props.tenant) + item.Link,
              name: item.id,
              stopRouter: true,
              continueSaving: true,
              goMenuOption: false
          });
      }
  }

  handleMenuClick = (event, item) => {
      this.setState({ anchorEl: event.currentTarget});
      this.props.select(item.id);
  };

  handleMenuClose = () => {
      this.setState({ anchorEl: null });
  };

  filterEntries = (item) => {
      if (item.hidden) {
          return false;
      }
    
      if (this.props.userPermissions[item.module]) {
          var hasPermission = checkPermissions.validatePermission(
              this.props.userPermissions,
              item.module,
              constants.roleRights.List,
              false
          );
          
          var hasPermissionOwn = checkPermissions.validatePermission(
              this.props.userPermissions,
              item.module,
              constants.roleRights.ListOwn,
              false
          );
       
          hasPermission = hasPermission ? hasPermission : hasPermissionOwn;

          return hasPermission && !(item.experimental && !env.experimental);
      }

      return false;
  }
  
  renderAsLink = (item, baseurl) => {
      const { t} = this.props;  
      // If no subitems, render item as link 
      return (
          <Fragment>
              <ListItem
                  button
                  id={"side-nav-" + item.id}
                  href={!item.external ? null : baseurl + tenantRoutePrefix(this.props.tenant) + item.Link}
                  onClick={!item.external ? ((e) => this.handleRoute(e, item)) : (this.handleClick.bind(this, item.id))}
                  key={item.id}
                  style={{
                      color: (this.props.selectedId === item.id ? colorActive : colorInactive), 
                      background: (this.props.selectedId === item.id ? backgroundActive : backgroundInactive)
                  }}
              > 

                  <div style={{marginLeft: "0px", padding: 3, minWidth: "50px", fontSize: "20px"}} className="side-nav-bar-icon" >
                      <FontAwesomeIcon icon={this.faSicons[item.icon] || faSpinner} 
                          style={{color: this.props.selectedId === item.id ? colorActive : colorInactive}}/> 
                  </div>
                  <ListItemText
                      primary={t(item.name)}
                      className="side-nav-bar-list-parent"
                      style={{maxWidth: 120}}
                  />
              </ListItem>          
          </Fragment>
      );
  }

  renderCollapse = (item) => {
      const { t, classes, list} = this.props;
      return (
          <Collapse
              key={list.items.id}
              component="li"
              in={this.state[item.id]}
              timeout="auto"
              unmountOnExit
          >
              <List>
                  {item.subitems.filter((sitem) => this.filterEntries(sitem)).map(
                      (sitem) => {
                          return (
                              <ListItem
                                  style={{
                                      paddingRight: 6,
                                      background: (this.props.selectedId === item.id ? backgroundActive : backgroundInactive)
                                  }}
                                  button
                                  key={sitem.id}
                                  id={"side-nav-" + sitem.id}
                                  className={classes.nested}
                                  onClick={(e) => this.handleRoute(e, sitem)}
                              >
                                  <ListItemText
                                      key={sitem.id}
                                      primary={t(sitem.id)}
                                      style= {{
                                          maxWidth: 100, 
                                          color: (this.props.selectedId === sitem.id ? colorActive : colorInactive)
                                      }}
                                  />
                              </ListItem>
                          );
                      }
                  )}
              </List>
          </Collapse>
      );
  }

  renderMaximized = (item) => {
      const { t} = this.props;
      return(
          <ListItem
              button
              style={{paddingRight: 6}}
              key={item.id}
              id={"side-nav-" + item.id}
              aria-haspopup="true"
              onClick={this.handleClick.bind(this, item.id)}
          >
              <div style={{marginLeft: "3px", minWidth: "52px", fontSize: "18px"}} className="side-nav-bar-icon" >
                  <FontAwesomeIcon icon={this.faSicons[item.icon] || faSpinner} 
                      style={{ color: (item.subitems.some((e) => e.id === this.props.selectedId) ? colorActive : colorInactive) }} /> 
              </div>
              <ListItemText
                  primary={t(item.name)} 
                  style= {{maxWidth: 100, color: (item.subitems.some((e) => e.id === this.props.selectedId) ? colorActive : colorInactive) } }
                  className="side-nav-bar-list-parent"
              />
              {this.state[item.id] ? (
                  <ArrowDropDown color="primary" className="side-nav-bar-icon arrow" />
              ) : (
                  <ArrowRight className="side-nav-bar-icon arrow" />
              )}
          </ListItem>                        
      );
  };

  render() {
      const baseurl = "https://" + (this.props.tenant != null ? this.props.tenant.host : window.location.host);
      const {list} = this.props;
      return (
          <div>
              <List key={list.id}>
                  {/* Map through the main items of the json */}
                  {list.items.filter((item) => (this.filterEntries(item))).map((item) => {
                      return (
                          <div key={item.id}>
                              {/* Check if item has subitems, then check that user has access to those subitems */}
                              {(item.subitems != null) ? (
                                  item.subitems.filter((sitem) => this.filterEntries(sitem)).length !== 0 ? (
                                      <div key={item.id}>
                                          <Fragment>{this.renderMaximized(item)}</Fragment>
                                          {this.renderCollapse(item)}
                                      </div>
                                  ) : (null)
                              ) : (
                                  <Fragment>{this.renderAsLink(item, baseurl)}</Fragment>
                              )}
                          </div>
                      );
                  })}
              </List>
          </div>
      );
  }
}

export default compose(withStyles(styles))(NestedList);

