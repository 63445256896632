import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function formTemplateReducer(state = initialState.formTemplate, action) {
    if (action.type === types.READ_FORM_TEMPLATE_SUCCESS) {
        return action.formTemplate;
    }
    else {
        return state;
    }
}
