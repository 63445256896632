import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function customersAttachmentReducer(state = initialState.customersAttachment, action) {
    if (action.type === types.READ_CUSTOMERS_ATTACHMENTS_SUCCESS) {
        return action.customersAttachment;
    }
    else {
        return state;
    }
}
