import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function usersPermissionsReducer(state = initialState.userPermissions, action) {
    if (action.type === types.READ_USER_PERMISSIONS_SUCCESS) {
        return action.userPermissions;
    }
    else {
        return state;
    }
}
