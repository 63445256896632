import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function catalogTimeSheetTypeReducer(state = initialState.catalogTimeSheetType, action) {
    if (action.type === types.READ_CATALOG_TIMESHEET_TYPE_SUCCESS) {
        return action.catalogTimeSheetType;
    }
    else {
        return state;
    }
}
