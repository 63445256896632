import {
    PUSH_NOTIFICATIONS_STATUS_UPDATE,
    SUBSCRIBE_TO_PUSH_NOTIFICATIONS_SUCCESS,
    SUBSCRIBE_TO_PUSH_NOTIFICATIONS_ERROR, 
    UNSUBSCRIBE_FROM_PUSH_NOTIFICATIONS_SUCCESS, 
    UNSUBSCRIBE_FROM_PUSH_NOTIFICATIONS_ERROR
} from "../actions/actionTypes";
import initialState from "./initialState";

export default function serviceWorkerReducer(state = initialState.webPush, action) {
    if (action.type === PUSH_NOTIFICATIONS_STATUS_UPDATE) {
        return action.state;
    }
    else if (action.type === SUBSCRIBE_TO_PUSH_NOTIFICATIONS_SUCCESS) {
        return action.state;
    }
    else if (action.type === SUBSCRIBE_TO_PUSH_NOTIFICATIONS_ERROR) {
        return { supported: true, subscribed: false, expirationTime: undefined };
    }
    else if (action.type === UNSUBSCRIBE_FROM_PUSH_NOTIFICATIONS_SUCCESS) {
        return { supported: true, subscribed: false, expirationTime: undefined };
    }
    else if (action.type === UNSUBSCRIBE_FROM_PUSH_NOTIFICATIONS_ERROR) {
        return { supported: true, subscribed: false, expirationTime: undefined };
    }
    else {
        return state;
    }
}
