import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function productGroupReducer(state = initialState.productGroup, action) {
    if (action.type === types.READ_PRODUCTGROUP_SUCCESS) {
        return action.productGroup;
    }
    else {
        return state;
    }
}
