export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";
export const SERVICE_WORKER_UPDATE_WAITING = "SERVICE_WORKER_UPDATE_WAITING";
export const SUBSCRIBE_TO_PUSH_NOTIFICATIONS_SUCCESS = "SUBSCRIBE_TO_PUSH_NOTIFICATIONS_SUCCESS";
export const SUBSCRIBE_TO_PUSH_NOTIFICATIONS_ERROR = "SUBSCRIBE_TO_PUSH_NOTIFICATIONS_ERROR";
export const UNSUBSCRIBE_FROM_PUSH_NOTIFICATIONS_SUCCESS = "UNSUBSCRIBE_FROM_PUSH_NOTIFICATIONS_SUCCESS";
export const UNSUBSCRIBE_FROM_PUSH_NOTIFICATIONS_ERROR = "UNSUBSCRIBE_FROM_PUSH_NOTIFICATIONS_ERROR";
export const PUSH_NOTIFICATIONS_STATUS_UPDATE = "PUSH_NOTIFICATIONS_STATUS_UPDATE";
export const UPDATE_CURRENT_NOTIFICATION_CONTEXT_KEY = "UPDATE_CURRENT_NOTIFICATION_CONTEXT_KEY";
export const LOAD_LOGIN_PROPERTIES_SUCESS = "LOAD_LOGIN_PROPERTIES_SUCESS";
export const READ_TENANT_SUCCESS = "READ_TENANT_SUCCESS";
export const READ_TENANT_ACCESS_DENIED = "READ_TENANT_ACCESS_DENIED";
export const GET_ALL_AVAILABLE_TENANTS_SUCCESS = "GET_ALL_AVAILABLE_TENANTS_SUCCESS";
export const SELECT_TENANT_SUCCESS = "SELECT_TENANT_SUCCESS";
export const LOAD_WORKCARDS_SUCCESS = "LOAD_WORKCARDS_SUCCESS";
export const READ_WORKCARD_SUCCESS = "READ_WORKCARD_SUCCESS";
export const CREATE_WORKCARD_SUCCESS = "CREATE_WORKCARD_SUCCESS";
export const UPDATE_WORKCARD_SUCCESS = "UPDATE_WORKCARD_SUCCESS";
export const DELETE_WORKCARD_SUCCESS = "DELETE_WORKCARD_SUCCESS";
export const NEW_WORKCARD_SUCCESS = "NEW_WORKCARD_SUCCESS";
export const LOAD_TRANSLATIONS_SUCCESS = "LOAD_TRANSLATIONS_SUCCESS";
export const UPDATE_TEMPLATES_SUCCESS = "UPDATE_TEMPLATES_SUCCESS";
export const RESET_PDF_WORKCARD_SUCCESS = "RESET_PDF_WORKCARD_SUCCESS";
export const PREVIEW_PDF_WORKCARD_SUCCESS = "PREVIEW_PDF_WORKCARD_SUCCESS";
export const RENDER_PDF_WORKCARD_DOCUMENT_SUCCESS = "RENDER_PDF_WORKCARD_DOCUMENT_SUCCESS";
export const SEND_PDF_WORKCARD_DOCUMENT_LINK_SUCCESS = "SEND_PDF_WORKCARD_DOCUMENT_LINK_SUCCESS";
export const CLEAR_TOASTS = "CLEAR_TOASTS";
export const TOAST_MESSAGE = "TOAST_MESSAGE";
export const READ_NOTIFICATIONS_SUCCESS = "READ_NOTIFICATIONS_SUCCESS";
export const UPDATE_NOTIFICATIONS_SUCCESS = "UPDATE_NOTIFICATIONS_SUCCESS";
export const READ_NOTIFICATIONSETTINGS_SUCCESS = "READ_NOTIFICATIONSETTINGS_SUCCESS";
export const UPDATE_NOTIFICATIONSETTINGS_SUCCESS = "UPDATE_NOTIFICATIONSETTINGS_SUCCESS";
export const READ_PERSON_SUCCESS = "READ_PERSON_SUCCESS";
export const CREATE_PERSON_SUCCESS = "CREATE_PERSON_SUCCESS";
export const UPDATE_PERSON_SUCCESS = "UPDATE_PERSON_SUCCESS";
export const DELETE_PERSON_SUCCESS = "DELETE_PERSON_SUCCESS";
export const LOAD_PRODUCTREGISTER_SUCCESS = "LOAD_PRODUCTREGISTER_SUCCESS";
export const READ_PRODUCTREGISTER_SUCCESS = "READ_PRODUCTREGISTER_SUCCESS";
export const CREATE_PRODUCTREGISTER_SUCCESS = "CREATE_PRODUCTREGISTER_SUCCESS";
export const UPDATE_PRODUCTREGISTER_SUCCESS = "UPDATE_PRODUCTREGISTER_SUCCESS";
export const DELETE_PRODUCTREGISTER_SUCCESS = "DELETE_PRODUCTREGISTER_SUCCESS";
export const LOAD_PRODUCTGROUP_SUCCESS = "LOAD_PRODUCTGROUP_SUCCESS";
export const READ_PRODUCTGROUP_SUCCESS = "READ_PRODUCTGROUP_SUCCESS";
export const CREATE_PRODUCTGROUP_SUCCESS = "CREATE_PRODUCTGROUP_SUCCESS";
export const UPDATE_PRODUCTGROUP_SUCCESS = "UPDATE_PRODUCTGROUP_SUCCESS";
export const DELETE_PRODUCTGROUP_SUCCESS = "DELETE_PRODUCTGROUP_SUCCESS";
export const LOAD_PRODUCTUNIT_SUCCESS = "LOAD_PRODUCTUNIT_SUCCESS";
export const READ_PRODUCTUNIT_SUCCESS = "READ_PRODUCTUNIT_SUCCESS";
export const CREATE_PRODUCTUNIT_SUCCESS = "CREATE_PRODUCTUNIT_SUCCESS";
export const UPDATE_PRODUCTUNIT_SUCCESS = "UPDATE_PRODUCTUNIT_SUCCESS";
export const DELETE_PRODUCTUNIT_SUCCESS = "DELETE_PRODUCTUNIT_SUCCESS";
export const LOAD_PRODUCTUNITBATCH_SUCCESS = "LOAD_PRODUCTUNITBATCH_SUCCESS";
export const UI_RESIZE_WINDOW = "UI_RESIZE_WINDOW";
export const BEGIN_LOGIN = "BEGIN_LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const BEGIN_LOGOUT = "BEGIN_LOGOUT";
export const SILENT_RENEW_SUCCESS = "SILENT_RENEW_SUCCESS";
export const BEGIN_SILENT_RENEW = "BEGIN_SILENT_RENEW";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const SET_MODULE = "SET_MODULE";
export const SET_CONTEXT = "SET_CONTEXT";
export const LOAD_CONTACT_SUCCESS = "LOAD_CONTACT_SUCCESS";
export const READ_CONTACT_SUCCESS = "READ_CONTACT_SUCCESS";
export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const LOAD_CUSTOMER_SUCCESS = "LOAD_CUSTOMER_SUCCESS";
export const READ_CUSTOMER_SUCCESS = "READ_CUSTOMER_SUCCESS";
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const READ_CATALOG_SUCCESS = "READ_CATALOG_SUCCESS";
export const UPDATE_CATALOG_SUCCESS = "UPDATE_CATALOG_SUCCESS";
export const LOAD_WORKSITE_SUCCESS = "LOAD_WORKSITE_SUCCESS";
export const READ_WORKSITE_SUCCESS = "READ_WORKSITE_SUCCESS";
export const CREATE_WORKSITE_SUCCESS = "CREATE_WORKSITE_SUCCESS";
export const UPDATE_WORKSITE_SUCCESS = "UPDATE_WORKSITE_SUCCESS";
export const DELETE_WORKSITE_SUCCESS = "DELETE_WORKSITE_SUCCESS";
export const PRELOAD_WORKORDER_SUCCESS = "PRELOAD_WORKORDER_SUCCESS";
export const LOAD_WORKORDER_SUCCESS = "LOAD_WORKORDER_SUCCESS";
export const READ_WORKORDER_SUCCESS = "READ_WORKORDER_SUCCESS";
export const CREATE_WORKORDER_SUCCESS = "CREATE_WORKORDER_SUCCESS";
export const UPDATE_WORKORDER_SUCCESS = "UPDATE_WORKORDER_SUCCESS";
export const DELETE_WORKORDER_SUCCESS = "DELETE_WORKORDER_SUCCESS";
export const SWITCH_WORKORDER_QUEUE_SUCCESS = "SWITCH_WORKORDER_QUEUE_SUCCESS";
export const READ_RALTIME_MESSAGE_SUCCESS = ".READ_RALTIME_MESSAGE_SUCCESS";
export const LOAD_WORKORDER_DIARYENTRY_SUCCESS = "LOAD_WORKORDER_DIARYENTRY_SUCCESS";
export const READ_WORKORDER_DIARYENTRY_SUCCESS = "READ_WORKORDER_DIARYENTRY_SUCCESS";
export const CREATE_WORKORDER_DIARYENTRY_SUCCESS = "CREATE_WORKORDER_DIARYENTRY_SUCCESS";
export const UPDATE_WORKORDER_DIARYENTRY_SUCCESS = "UPDATE_WORKORDER_DIARYENTRY_SUCCESS";
export const DELETE_WORKORDER_DIARYENTRY_SUCCESS = "DELETE_WORKORDER_DIARYENTRY_SUCCESS";
export const COUNTER_WORKORDER_DIARYENTRY_SUCCESS = "COUNTER_WORKORDER_DIARYENTRY_SUCCESS";
export const UPDATE_BLOB_PROGRESS = "UPDATE_BLOB_PROGRESS";
export const UPDATE_BLOBS_PROGRESS = "UPDATE_BLOBS_PROGRESS";
export const SET_BLOBS_PROGRESS = "SET_BLOBS_PROGRESS";
export const ABORT_BLOBS_PROGRESS = "ABORT_BLOBS_PROGRESS";
export const LOAD_FORM_TEMPLATES_SUCCESS = "LOAD_FORM_TEMPLATES_SUCCESS";
export const READ_FORM_TEMPLATE_SUCCESS = "READ_FORM_TEMP";
export const CREATE_FORM_TEMPLATE_SUCCESS = "CREATE_FORM_TEMPLATE_SUCCESS";
export const UPDATE_FORM_TEMPLATE_SUCCESS = "UPDATE_FORM_TEMPLATE_SUCCESS";
export const DELETE_FORM_TEMPLATE_SUCCESS = "DELETE_FORM_TEMPLATE_SUCCESS";


export const LOAD_WORKQUEUES_SUCCESS = "LOAD_WORKQUEUES_SUCCESS";
export const SET_CURRENT_WORKQUEUE = "SET_CURRENT_WORKQUEUE";
export const READ_WORKQUEUE_SUCCESS = "READ_WORKQUEUE_SUCCESS";
export const CREATE_WORKQUEUE_SUCCESS = "CREATE_WORKQUEUE_SUCCESS";
export const START_UPDATE_WORKQUEUE = "START_UPDATE_WORKQUEUE";
export const UPDATE_WORKQUEUE_SUCCESS = "UPDATE_WORKQUEUE_SUCCESS";
export const DELETE_WORKQUEUE_SUCCESS = "DELETE_WORKQUEUE_SUCCESS";


export const LOAD_WORKBOARDS_SUCCESS = "LOAD_WORKBOARDS_SUCCESS";
export const READ_WORKBOARD_SUCCESS = "READ_WORKBOARD_SUCCESS";
export const CREATE_WORKBOARD_SUCCESS = "CREATE_WORKBOARD_SUCCESS";
export const UPDATE_WORKBOARD_SUCCESS = "UPDATE_WORKBOARD_SUCCESS";
export const START_UPDATE_WORKBOARD = "START_UPDATE_WORKBOARD";
export const DELETE_WORKBOARD_SUCCESS = "DELETE_WORKBOARD_SUCCESS";
export const SET_CURRENT_WORKBOARD = "SET_CURRENT_WORKBOARD";
export const MOVE_WORKORDER_ON_WORKBOARD = "MOVE_WORKORDER_ON_WORKBOARD";
export const DELETE_WORKORDER_FROM_WORKBOARD = "DELETE_WORKORDER_FROM_WORKBOARD";
export const READ_WORKQUEUES_SUCCESS = "READ_WORKQUEUES_SUCCESS";
export const READ_WORKORDERS_SUCCESS = "READ_WORKORDERS_SUCCESS";
export const READ_WORKSITES_SUCCESS = "READ_WORKSITES_SUCCESS";

export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";

export const SHOW_MODULE_TITLE = "SHOW_MODULE_TITLE";
export const SET_SEARCH_SETTINGS_OPEN = "SET_SEARCH_SETTINGS_OPEN";
export const ONLINE_DETECTED = "ONLINE_DETECTED";
export const OFFLINE_DETECTED = "OFFLINE_DETECTED";
export const SESSION_ACTIVE = "SESSION_ACTIVE";
export const READ_RALTIME_MESSAGE_SEND = "READ_RALTIME_MESSAGE_SEND";
export const RALTIME_CONNECTION_SIGNAL_ACTIVE = "RALTIME_CONNECTION_SIGNAL_ACTIVE";
export const RALTIME_CONNECTION_LOST = "RALTIME_CONNECTION_LOST";
export const LOAD_DATA_USERS_SUCCESS = "LOAD_DATA_USERS_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const CHECK_USER_NAME_SUCCESS = "CHECK_USER_NAME_SUCCESS";
export const READ_USER_SUCCESS = "READ_USER_SUCCESS";
export const READ_CURRENT_USER_SUCCESS = "READ_CURRENT_USER_SUCCESS";
export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";
export const LOAD_ROLES_SUCCESS = "LOAD_ROLES_SUCCESS";
export const READ_ROLES_SUCCESS = "READ_ROLES_SUCCESS";
export const UPDATE_ROLES_SUCCESS = "UPDATE_ROLES_SUCCESS";
export const READ_USER_PERMISSIONS_SUCCESS = "READ_USER_PERMISSIONS_SUCCESS";
export const LOAD_TAGS_SUCCESS = "LOAD_TAGS_SUCCESS";
export const UPDATE_TAGS_SUCCESS = "UPDATE_TAGS_SUCCESS";

export const UPDATE_TENANT_SUCCESS = "UPDATE_TENANT_SUCCESS";
export const READ_TENANT_EDITABLE_SUCCESS = "READ_TENANT_EDITABLE_SUCCESS";
export const CREATE_USERGROUP_SUCCESS = "CREATE_USERGROUP_SUCCESS";
export const PENDING_ROUTER_SUCCESS = "PENDING_ROUTER_SUCCESS";
export const UPDATE_CHAT_CHANNEL_SUCCESS = "UPDATE_CHAT_CHANNEL_SUCCESS";
export const READ_CHAT_CHANNEL_SUCCESS = "READ_CHAT_CHANNEL_SUCCESS";
export const INCREMENT_TOTAL_NUMBER_OF_MESSAGES = "INCREMENT_TOTAL_NUMBER_OF_MESSAGES";
export const POST_CHAT_MESSAGE_SUCCESS = "POST_CHAT_MESSAGE_SUCCESS";
export const LOAD_CHAT_MESSAGES_SUCCESS = "LOAD_CHAT_MESSAGES_SUCCESS";

export const OPEN_CHANNEL_SUCCESS = "OPEN_CHANNEL_SUCCESS";
export const CLOSE_CHANNEL_SUCCESS = "CLOSE_CHANNEL_SUCCESS";

export const READ_CATALOG_TITLE_SUCCESS = "READ_CATALOG_Title_SUCCESS";
export const READ_CATALOG_PAYMENT_TYPE_SUCCESS = "READ_CATALOG_PAYMENT_TYPE_SUCCESS";
export const READ_CATALOG_ACCOUNT_GROUP_SUCCESS = "READ_CATALOG_ACCOUNT_GROUP_SUCCESS";
export const READ_CATALOG_DEFAULTS_SUCCESS = "READ_CATALOG_DEFAULTS_SUCCESS";
export const READ_CATALOG_TIMESHEET_TYPE_SUCCESS = "READ_CATALOG_TIMESHEET_TYPE_SUCCESS";
export const LOAD_CHAT_UNREAD_MESSAGES_COUNTER_SUCCESS = "LOAD_CHAT_UNREAD_MESSAGES_COUNTER_SUCCESS";
export const IS_SHARED_TOKEN = "IS_SHARED_TOKEN";
export const LOAD_TOKENS_INFO_SUCCESS = "LOAD_TOKENS_INFO_SUCCESS";
export const LOAD_HISTORY_ENTRIES_SUCCESS = "LOAD_HISTORY_ENTRIES_SUCCESS";
export const SET_DATA_TYPE_HISTORY_SUCCESS = "SET_DATA_TYPE_HISTORY_SUCCESS";
export const SESSION_ACCESS_TOKEN_ACTIVE = "SESSION_ACCESS_TOKEN_ACTIVE";
export const UPDATE_WORKORDER_TEMPLATE_SUCCESS = "UPDATE_WORKORDER_TEMPLATE_SUCCESS";
export const READ_WORKORDER_TEMPLATE_SUCCESS = "READ_WORKORDER_TEMPLATE_SUCCESS";
export const LOAD_WORKORDER_TEMPLATES_SUCCESS = "LOAD_WORKORDER_TEMPLATES_SUCCESS";
export const MEMBERS_TEAM_LIST_SUCCESS = "MEMBERS_TEAM_LIST_SUCCESS";
export const BLOCK_SCROLL_SUCCESS = "BLOCK_SCROLL_SUCCESS";

export const LOAD_TIMESHEET_SUCCESS = "LOAD_TIMESHEET_SUCCESS";
export const READ_TIMESHEET_SUCCESS = "READ_TIMESHEET_SUCCESS";
export const UPDATE_TIMESHEET_SUCCESS = "UPDATE_TIMESHEET_SUCCESS";
export const SET_FILTER_WORKBOARD = "SET_FILTER_WORKBOARD";
export const SET_TOUCHES_WORKBOARD = "SET_TOUCHES_WORKBOARD";

export const LOAD_CUSTOMERS_ATTACHMENTS_SUCCESS = "LOAD_CUSTOMERS_ATTACHMENTS_SUCCESS";
export const READ_CUSTOMERS_ATTACHMENTS_SUCCESS = "READ_CUSTOMERS_ATTACHMENTS_SUCCESS";
export const CREATE_CUSTOMERS_ATTACHMENTS_SUCCESS = "CREATE_CUSTOMERS_ATTACHMENTS_SUCCESS";
export const UPDATE_CUSTOMERS_ATTACHMENTS_SUCCESS = "UPDATE_CUSTOMERS_ATTACHMENTS_SUCCESS";
export const DELETE_CUSTOMERS_ATTACHMENTS_SUCCESS = "DELETE_CUSTOMERS_ATTACHMENTS_SUCCESS";
export const COUNTER_CUSTOMERS_ATTACHMENTS_SUCCESS = "COUNTER_CUSTOMERS_ATTACHMENTS_SUCCESS";

export const LOAD_WORKSITE_ATTACHMENTS_SUCCESS = "LOAD_WORKSITE_ATTACHMENTS_SUCCESS";
export const READ_WORKSITE_ATTACHMENTS_SUCCESS = "READ_WORKSITE_ATTACHMENTS_SUCCESS";
export const CREATE_WORKSITE_ATTACHMENTS_SUCCESS = "CREATE_WORKSITE_ATTACHMENTS_SUCCESS";
export const UPDATE_WORKSITE_ATTACHMENTS_SUCCESS = "UPDATE_WORKSITE_ATTACHMENTS_SUCCESS";
export const DELETE_WORKSITE_ATTACHMENTS_SUCCESS = "DELETE_WORKSITE_ATTACHMENTS_SUCCESS";
export const COUNTER_WORKSITE_ATTACHMENTS_SUCCESS = "COUNTER_WORKSITE_ATTACHMENTS_SUCCESS";