
import React, { useEffect } from "react";
import { tenantRoutePrefix } from "../../helpers/tenantHelper";
import { v4 as uuidv4 } from "uuid";
import logoGray from "./logo_gray_text.png";
import logoSmall from "./logo_small.png";
import logoRed from "./logo_light.png";
import logoDark from "./logo_dark.png";

export const Logo = (props) => {
    const {dimensions} = props;
    const [logo, setLogo] = React.useState(props); 
    let visiblelogo = logoSmall;
    switch(props.type) {
    case "small":
        visiblelogo = logoSmall;
        break;
    case "red":
        visiblelogo = logoRed;
        break;
    case "gray":
        visiblelogo = logoGray;
        break;
    case "dark":
        visiblelogo = logoDark;
        break;
    default:
        visiblelogo = logoSmall;
    }

    useEffect(() => {
        setLogo(visiblelogo);
    }, [visiblelogo]);

    const handleRoute = () => {
        const path = tenantRoutePrefix(props.tenant) + props.path.route;
        if(props.link){
            window.location.href = props.link;
        }
        else{
            props.actions.setModule({ url: path, name: props.path.name});
            props.history.push(path, {clickIndicator: uuidv4()});
            props.close();
        }
    };

    return (
        <div onClick={() => handleRoute(props.link)}>
            <img alt="Puskee™" width="140" height="40" src={logo.toString()} 
                style={{margin: "0px 18px", width: dimensions.width, height: dimensions.height, cursor: "pointer"}} />
        </div>
    );
};

export default Logo;

