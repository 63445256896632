import env from "../utils/env"; 
import * as constants from "../components/common/constants";


export const serviceKeys = {
    workCards: "workcards",
    tenants: "tenants",
    documents: "documents",
    documentSharing: "documentsharing",
    default: "default",
    translations: "translations",
    realTime: "realTime",
    users: "users",
    hostAppUrl: "hostAppUrl",
    registrationUrl: "registrationUrl",
};

export const handleResponse = async (response) => {
    if (response.status === 200) {
        if (response.data.success || typeof(response.data.success) === "undefined") {
            return response.data; 
        }
        else {
            throw new Error(response.data);
        }
    }
    else if (response.status === 400) {
        throw new Error(response.data);
    }
    else {
        throw new Error("Network response was not ok.");
    }
};

export const handleError = (error) => {
    var textError = "";
    if (error.message === "Network Error") {
        textError = "NetworkError";
    }
    else if (!error.response) {
        textError = error;
    }
    else if (error.response.data.hasOwnProperty("errors")) {
        textError = error.response.data.errors.message;
    }
    else if (error.response.data) {
        textError = error.response.data;
        if (typeof textError !== "object" && textError.toLowerCase().includes("already exists")) {
            textError = constants.UniqueKeyConstraintViolation;   
        }
    }
    else {
        textError = "SystemError" + error.response.status;
    }

    throw new Error(textError);
};

export const getApiUrl = (serviceKey) => {
    var url = "";
    
    if (process.env.REACT_APP_URL_MOCK && !process.env.REACT_APP_USE_MOCK_API_RESOURCE_ACCESS) {
        if (serviceKey === serviceKeys.realTime) {
            url = env.realTime;
        }
        else {
            url = process.env.REACT_APP_URL_MOCK;
        }
    }
    else if (serviceKey === serviceKeys.workCards) {
        url = env.workCardsApiUrl;
    }
    else if (serviceKey === serviceKeys.documents || serviceKey === serviceKeys.documentSharing) {
        url = env.documentsApiUrl;
    }
    else if (serviceKey === serviceKeys.tenants) {
        url = env.tenantsApiUrl;
    }
    else if (serviceKey === serviceKeys.default) {
        url = env.defaultApiUrl;
    }
    else if (serviceKey === serviceKeys.users) {
        url = env.usersApiUrl;
    }
    else if (serviceKey === serviceKeys.realTime) {
        url = env.realTime;
    }
    else if (serviceKey === serviceKeys.hostAppUrl) {
        url = env.hostAppUrl;
    }
    else if (serviceKey === serviceKeys.registrationUrl) {
        url = env.registrationUrl;
    }
    else {
        throw new Error(`Invalid service key ${serviceKey}.`);
    }


    return url;
};
