import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import * as styles from "./styles";


export default class Spinner extends React.PureComponent {
    render() {
        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                style={{...styles.modal, overflow: "hidden"}}
                open={this.props.open}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={this.props.open}>
                    <div style={styles.modalPaper}>
                        <CircularProgress style={styles.progressCircle} />
                    </div>
                </Fade>
            </Modal>
        );
    }
}

