import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from "./authorizationUtils";
import axios from "axios";

// azure functions can not handle ambigous routes
// we need explicit defined those


export const GetSASToken = (blobName, permissions, size, limit, cancelToken, sharedToken = "") => {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.default) + `/api/Aggregate/Blob/GetSASToken` +
    `?blobName=${blobName}&permissions=${permissions}&size=${size}&limit=${limit}&sharedToken=${sharedToken}`, options)
        .then(handleResponse)
        .catch(handleError);
};

export const GetSharedReadonlySASToken = (blobName, shareKey, cancelToken) => {
    const options = getRequestOptions(cancelToken, true);
    const permissions = "Read";
    return axios.get(getApiUrl(serviceKeys.default) + `/api/Aggregate/Blob/GetSASToken` +
    `?blobName=${blobName}&permissions=${permissions}&shareKey=${shareKey}`, options)
        .then(handleResponse)
        .catch(handleError);
};

