import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function notificationContextKeyReducer(state = initialState.notificationContextKey, action) {
    if(action.type === types.UPDATE_CURRENT_NOTIFICATION_CONTEXT_KEY){    
        return action.notificationContextKey;
    }
    else{   
        return state;
    }
}