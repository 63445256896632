import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function tokensReducer(state = initialState.tokensInfo, action) {
    if (action.type === types.LOAD_TOKENS_INFO_SUCCESS) {
        return action.tokensInfo;
    }
    else {
        return state;
    }
}