import React, { Component } from "react";
import * as styles from "./styles.js";
import "../../index.css";
import {
    Button,
    Tooltip
} from "@material-ui/core";

export class CircleButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    render() {
        return (
            <span disabled={this.props.disabled} >
                <Tooltip 
                    title={this.props.toolTipMessage} >
                    <Button 
                        data-testid={this.props.id}
                        id="CircleButton" 
                        name={this.props.name}
                        variant="contained"
                        className="circleButton" 
                        style={{...styles.circleButton, ...this.props.style}}
                        onClick={this.props.clickAction} 
                        
                        color={this.props.color} 
                    >
                        {this.props.iconButton}
                    </Button>
                </Tooltip>
            </span>
            
        );
    };
}

export default (CircleButton);
