import env from "../../utils/env"; 
import {
    subscribeToWebPush,
    unsubscribeFromWebPush,
    readWebPushSubscriptionState,
    sendWebNotification,
    closeNotificationsByContext
} from "../../serviceWorkerRegistration";
import * as notificationsApi from "../../api/notificationsApi";
import { toastMessage } from "./notificationsActions";

export const subscribe = (userId, showError) => {
    return function (dispatch) {
        const successCallback = (subscription) => {
            const subscriptionObject = {
                ...JSON.parse(JSON.stringify(subscription)),
                state: "valid"
            };

            notificationsApi.updateWebPushSubscription(userId, subscriptionObject);
        };

        const failCallback = (error) => {
            if (showError) {
                dispatch(toastMessage("WebPushSubscriptionFailed", "error"));
            }
        };

        subscribeToWebPush(env.applicationServerKey, dispatch, successCallback, failCallback);
    };
};

export const unsubscribe = (userId) => {
    return function (dispatch) {
        const successCallback = (subscription) => {
            const subscriptionObject = {
                endpoint: subscription.endpoint,
                state: "deleted"
            };

            notificationsApi.updateWebPushSubscription(userId, subscriptionObject);
        };

        unsubscribeFromWebPush(dispatch, successCallback);
    };
};

export const readSubscriptionState = () => {
    return function (dispatch) {
        readWebPushSubscriptionState(dispatch);
    };
};

export const closePushNotifications = (contextKey) => {
    return function (dispatch) {
        closeNotificationsByContext(contextKey);
    };
};

export const sendWebNotificationTest = () => {
    sendWebNotification("Puskee™", {body: "This is a test message sent by Puskee UI", tag: "puskee-test-message"});
};