import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from "./authorizationUtils";
import axios from "axios";
import * as constants from "../components/common/constants";
import FilterHelper from "../helpers/filterHelper"; 
import { store } from "../redux/store";

// azure functions can not handle ambigous routes
// we need explicit defined those

export const ReadWorkOrders = (filter, sort, page, workSiteId, parentId, cancelToken, tags, filterSharingStates, filterSentStatus) => {
    const userid = store.getState().oidc.user ? store.getState().oidc.user.profile.sub : "";
    var searchFilter = FilterHelper.getSearchfilter(constants.worOrderFilter);
    if (filterSharingStates) {
        searchFilter = FilterHelper.getSearchfilter(constants.sharedTasksFilter + userid);
    }

    const options = getRequestOptions(cancelToken);
    var data = {
        filter: filter,
        sort: sort,
        page: page,
        workSiteId: workSiteId,
        parentId: parentId,
        searchFields: searchFilter,
        filterTags: tags,
        filterSharingStates: filterSharingStates,
        sentStatusFilter: filterSentStatus
    };

    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/WorkOrder/GetAll`, data, options)
        .then(handleResponse)
        .catch(handleError);
};

export const ReadWorkOrder = (id, cancelToken, sharedToken = "") => {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.default) + `/api/Aggregate/WorkOrder/Get/${id}?sharedToken=${sharedToken}`, options)
        .then(handleResponse)
        .catch(handleError);
};

export const updateWorkOrder = (model) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/WorkOrder/POST`, model, options)
        .then(handleResponse)
        .catch(handleError);
};

export const ReadWorkOrderBatch = (batch, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/WorkOrder/GetBatch`, batch, options)
        .then(handleResponse)
        .catch(handleError);
};

export const switchQueue = (model, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/WorkOrder/switchQueue`, model, options)
        .then(handleResponse)
        .catch(handleError);
};


