import * as types from "./actionTypes";
import * as catalogsApi from "../../api/catalogsApi";
import * as notificationActions from "./notificationsActions";
import * as constants from "../../components/common/constants";

export const readCatalogSuccess = (catalog) => {
    return { type: types.READ_CATALOG_SUCCESS, catalog };
};

export const readCatalog = (id, cancelToken) => {
    return function(dispatch) {
        return catalogsApi
            .ReadCatalog(id, cancelToken)  
            .then((result) => {
                dispatch(readCatalogSuccess(result));
                return result;
            })
            .catch((error) => {
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notificationActions.toastMessage("ErrorLoadingCatalog", "error"));
                    throw new Error(error);
                }
            });
    };
}; 

export const readCatalogTitleSuccess = (catalogTitle) => {
    return { type: types.READ_CATALOG_TITLE_SUCCESS, catalogTitle };
};

export const readCatalogPaymentTypeSuccess = (catalogPaymentType) => {
    return { type: types.READ_CATALOG_PAYMENT_TYPE_SUCCESS, catalogPaymentType };
};

export const readCatalogAccountGroupSuccess = (catalogAccountGroup) => {
    return { type: types.READ_CATALOG_ACCOUNT_GROUP_SUCCESS, catalogAccountGroup };
};

export const readCatalogDefaultsSuccess = (catalogDefaults) => {
    return { type: types.READ_CATALOG_DEFAULTS_SUCCESS, catalogDefaults };
};

export const readCatalogTimeSheetTypeSuccess = (catalogTimeSheetType) => {
    return { type: types.READ_CATALOG_TIMESHEET_TYPE_SUCCESS, catalogTimeSheetType };
};


export const readCatalogs = (suppressErrors) => {
    return function(dispatch) {
        Promise.all([
            getCatalog(constants.catalogs.Title),
            getCatalog(constants.catalogs.PaymentType),
            getCatalog(constants.catalogs.AccountGroup),
            getCatalog(constants.catalogs.Defaults),
            getCatalog(constants.catalogs.TimesheetItemTypes)
        ])
            .then((values) => {
                dispatch(readCatalogTitleSuccess(values[0]));
                dispatch(readCatalogPaymentTypeSuccess(values[1]));
                dispatch(readCatalogAccountGroupSuccess(values[2]));
                dispatch(readCatalogDefaultsSuccess(values[3]));
                dispatch(readCatalogTimeSheetTypeSuccess(values[4]));
            })
            .catch((error) => {
                if (!suppressErrors &&
                    typeof(error.message) !== "undefined" && 
                    error.message !== constants.cancelHttpRequest &&
                    !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notificationActions.toastMessage("ErrorLoadingCatalog", "error"));
                    throw new Error(error);
                }
            });
    };
}; 

export const updateAllCatalogs = async (id, dispatch) => {
    var rows = await getCatalog(id);
    rows = rows.sort(fieldSorter(["name"]));
    switch (id) {
    case constants.catalogs.Title: 
        dispatch(readCatalogTitleSuccess(rows));
        break;
    case constants.catalogs.PaymentType:
        dispatch(readCatalogPaymentTypeSuccess(rows));
        break;
    case constants.catalogs.AccountGroup: 
        dispatch(readCatalogAccountGroupSuccess(rows));
        break;
    case constants.catalogs.Defaults: 
        dispatch(readCatalogDefaultsSuccess(rows));
        break;
    case constants.catalogs.TimesheetItemTypes: 
        dispatch(readCatalogTimeSheetTypeSuccess(rows));
        break;    
    default:
        break;    
    }
}; 

const getCatalog = (id) => {
    return catalogsApi
        .ReadCatalog(id)  
        .then((result) => {
            var rows = result.items;
            rows = rows.sort(fieldSorter(["name"]));
            return rows;
        })
        .catch((error) => {
            throw new Error(error);
        });
};

const fieldSorter = (fields) => (a, b) => fields.map((o) => {
    let dir = 1;
    if (o[0] === "-") {
        dir = -1; o = o.substring(1); 
    }

    return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
}).reduce((p, n) => (p ? p : n), 0);

export const updateCatalogSuccess = (catalog) => {
    return { type: types.UPDATE_CATALOG_SUCCESS, catalog };
};

export const updateCatalogItems = (model) => {
    return async function(dispatch) {
        var flag = false;
        await catalogsApi
            .updateCatalogItems(model)  
            .then(async (result) => {
                flag = true;
            })
            .catch((error) => {
                if (!error.toString().includes("PreconditionFailed")) {
                    dispatch(notificationActions.toastMessage("ErrorUpdatingCatalog", "error"));
                }
                
                throw new Error(error);
            });

        if (flag) {
            await updateAllCatalogs(model.catalogId, dispatch);    
        }
    };
}; 

