import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from "./authorizationUtils";
import axios from "axios";

// azure functions can not handle ambigous routes
// we need explicit defined those

export const sendRealTimeMessage = (message) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.realTime) + `/api/SendMessage`, message, options)
        .then(handleResponse)
        .catch(handleError);
};


