import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from "./authorizationUtils";
import axios from "axios";
import * as constants from "../components/common/constants";
import FilterHelper from "../helpers/filterHelper"; 

// azure functions can not handle ambigous routes
// we need explicit defined those

export const ReadContacts = (filter, sort, page, customerId, title, tags, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    if (customerId === "") {
        customerId = 0;
    }

    var data = {
        filter: filter,
        sort: sort,
        page: page,
        customerId: customerId,
        title: title,
        searchFields: FilterHelper.getSearchfilter(constants.contactFilter),
        filterTags: tags
    };
  
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/Contacts/GetAll`, data, options)
        .then(handleResponse)
        .catch(handleError);
};

export const ReadContact = (id, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.default) + `/api/Aggregate/Contacts/Get/${id}`, options)
        .then(handleResponse)
        .catch(handleError);
};

export const ReadContactBatch = (batch, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/Contacts/GetBatch`, batch, options)
        .then(handleResponse)
        .catch(handleError);
};

export const ReadContactBatchInfo = (data, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/Contacts/GetBatchInfo`, data, options)
        .then(handleResponse)
        .catch(handleError);
};


export const updateContact = (model) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/Contacts/POST`, model, options)
        .then(handleResponse)
        .catch(handleError);
};


