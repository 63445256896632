import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import CheckBox from "@material-ui/core/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-regular-svg-icons/faComments";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons/faMobileAlt";
import Info from "@material-ui/icons/Info";
import NotificationsActive from "@material-ui/icons/NotificationsActive";
import {initialNotificationSettings} from "../../utils/initialNotificationSettings";
import { Email } from "@material-ui/icons";
import Spinner from "../common/CircularSpinner";

export class NotificationSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            channels: props.notificationSettings.channels,
            notificationSettings: props.notificationSettings,
            settings: props.notificationSettings.settings,
            tenants: props.notificationSettings.tenants,
            emailIntervalOpen: false,
            webPushIntervalOpen: false,    
            interval: {
                email: "",
                webPush: ""
            }
        };
    }

    componentDidMount(){
        this.props.actions.readNotificationSettings(this.props.user.profile, this.props.availableTenants);
        this.props.actions.readSubscriptionState();
    }

    componentWillUnmount() {
        this.props.actions.readNotificationSettings(this.props.user.profile, this.props.availableTenants);
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.notificationSettings !== prevProps.notificationSettings) {
            this.setState({
                settings: this.mapSettings(this.props.notificationSettings.settings),
                tenants: this.props.notificationSettings.tenants,  
                interval: {
                    email: this.props.notificationSettings.channels.email.interval,
                    webPush: this.props.notificationSettings.channels.webPush.interval
                }
            });
        }
    }

    mapSettings = (settings) => {
        var initial = initialNotificationSettings(localStorage.getItem("i18nextLng"), [], "").settings;
        var notificationSettings = [];
        initial.forEach((element, idx) => {
            element.enabled = settings[idx].enabled;
            notificationSettings.push(element);
        });

        return notificationSettings;
    }
    
    Transition = React.forwardRef((props, ref) => {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    selectIcon = (type) => {
        if(type === "info"){
            return <Info style={{margin: 8, fontSize: "1.5rem"}} />;
        }
        
        if(type === "message"){
            return <FontAwesomeIcon style={{margin: 8, fontSize: "1.5rem"}} icon={faComments} />;
        }

        return null;
    }

    handleClick = (event) => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleIntervalChange = (type, e) => {
        if(type === "email"){
            this.setState((prevState) => ({
                interval: {
                    ...prevState.interval,
                    email: e.target.value
                }
            }), () => this.saveIntervals());
        }
        else if(type === "webPush"){
            this.setState((prevState) => ({
                interval: {
                    ...prevState.interval,
                    webPush: e.target.value
                }
            }), () => this.saveIntervals());
        }
    }

    initTemp = (temp, interval = true) => {
        temp.state = "Modified";
        temp.localization = localStorage.getItem("i18nextLng");
        temp._etag = "";
        if(!!interval){
            temp.channels.email.interval = this.state.interval.email;
            temp.channels.webPush.interval = "* * * * * *";
        }

        return temp;
    }

    handleTenantChange = (item, idx) => {
        var temp = JSON.parse(JSON.stringify({...this.props.notificationSettings}));
        temp = this.initTemp(temp);
        let tempTenants = [...this.state.tenants];
        let tempitem = JSON.parse(JSON.stringify({...item}));
        tempitem.selected = !item.selected;
        tempTenants.splice(idx, 1, tempitem); 
        temp.tenants = tempTenants;
        
        this.setState({tenants: temp.tenants, }, async() => {
            await this.props.actions.updateNotificationSettings(temp);
        });
    }

    handleWebPushSwitchChange = (enabled) => {
        if (enabled) {
            this.props.actions.subscribe(this.props.user.profile.sub, true);
        }
        else {
            this.props.actions.unsubscribe(this.props.user.profile.sub);
        }
    }

    handleSettingChange = (item, idx) => {
        var temp = JSON.parse(JSON.stringify({...this.props.notificationSettings}));
        temp = this.initTemp(temp);
        let tempSettings = [...this.state.settings];
        let tempitem = JSON.parse(JSON.stringify({...item}));
        tempitem.enabled = !item.enabled;
        tempSettings.splice(idx, 1, tempitem); 
        temp.settings = tempSettings;
        this.setState({settings: temp.settings, }, async() => {
            await this.props.actions.updateNotificationSettings(temp);
        });
    }

    handleChannelSwitchChange = (enabled, type) => {
        var temp = JSON.parse(JSON.stringify({...this.state.notificationSettings}));
        temp = this.initTemp(temp, false);
        temp.channels[type].enabled = enabled;
        this.setState((prevState) => ({
            channels: {
                ...prevState.channels,
                [type]: {enabled: enabled}
            }}
        )
        , async() => {
            await this.props.actions.updateNotificationSettings(temp);
        });
    }

    saveIntervals = () => {
        var temp = JSON.parse(JSON.stringify({...this.props.notificationSettings}));
        temp = this.initTemp(temp, true);

        this.setState({channels: temp.channels}, async() => {
            await this.props.actions.updateNotificationSettings(temp);
        });
    }

    handleOpenInterval = (type) => {
        if(type === "email"){
            this.setState({IntervalOpen: type});
        }
    }

    handleCloseInterval = (type) =>{
        this.setState({
            IntervalOpen: false,
        });
    }
        
    IntervalSetter = (type) => {
        return(
            <FormControl style={{width: "-webkit-fill-available"}}>
                <Select
                    id={"intervalselect" + type}
                    open={this.state.IntervalOpen === type}
                    onClose={() => this.handleCloseInterval(type)}
                    onOpen={() => this.handleOpenInterval(type)}
                    value={this.state.interval[type]}
                    MenuProps={{fontSize: "1rem"}}
                    onChange={(e) => this.handleIntervalChange(type, e)}
                >
                    <MenuItem value={"0 */1 * * * *"}>{this.props.t("Every1Minute")}</MenuItem>
                    <MenuItem value={"0 */10 * * * *"}>{this.props.t("Every10Minute")}</MenuItem>
                    <MenuItem value={"0 */30 * * * *"}>{this.props.t("Every30Minute")}</MenuItem>
                    <MenuItem value={"0 */60 * * * *"}>{this.props.t("Every1Hour")}</MenuItem>
                    <MenuItem value={"0 0 0 * * *"}>{this.props.t("Every1Day")}</MenuItem>
                    <MenuItem value={"0 0 0 * * MON"}>{this.props.t("EveryMonday")}</MenuItem>
                </Select>
            </FormControl>);
    }

    mainSettings = () => {
        return(
            <Grid item xs={12} style={{marginTop: 6}} >
                <Grid container justifyContent="flex-start" direction="column">
                    <Grid container alignItems="center" style={{width: "100%", display: "inline-flex"}}>
                        <Grid item xs={5} sm={3} md={2}>
                            <Switch id="appSwitch" checked={this.state.channels.app.enabled} 
                                onChange={(e) => this.handleChannelSwitchChange(e.target.checked, "app")}/>
                            <NotificationsActive style={{margin: "-8px 8px", color: this.state.channels.app.enabled ? "#343c75" : "black"}}/>
                        </Grid>
                        <Grid item xs={7} sm={7} style={{padding: 6}}>
                            <Typography style={{fontSize: "1rem"}}>{this.props.t("ActiveNotifications")}</Typography>     
                        </Grid>
                    </Grid>
                </Grid>
                {this.props.webPush.supported &&
                <Grid container justifyContent="flex-start" direction="column">
                    <Grid container alignItems="center" style={{width: "100%", display: "inline-flex"}}>
                        <Grid item xs={5} sm={3} md={2}>
                            <Switch checked={this.props.webPush.subscribed} 
                                onChange={(e) => this.handleWebPushSwitchChange(e.target.checked)}/>
                            <FontAwesomeIcon icon={faMobileAlt} style={{
                                color: this.props.webPush.subscribed ? "#343c75" : "black", fontSize: "1.5rem", margin: "-8px 12px"}} />
                        </Grid>
                        <Grid item xs={7} sm={7} style={{padding: 6}}>
                            <Typography style={{fontSize: "1rem"}}>{this.props.t("PushNotifications")}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                } 
                <Grid container justifyContent="flex-end" direction="column" >
                    <Grid container alignItems="center" style={{display: "inline-flex"}}>
                        <Grid item xs={5} sm={3} md={2}>
                            <Switch checked={this.state.channels.email.enabled}
                                onChange={(e) => this.handleChannelSwitchChange(e.target.checked, "email")}/>
                            <Email style={{margin: "-8px 8px", color: this.state.channels.email.enabled ? "#343c75" : "black"}}/>
                        </Grid>
                        <Grid item xs={7} sm={8} style={{padding: 6}}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item xs={12} sm={8} md={6}>
                                    <Typography style={{fontSize: "1rem"}}>{this.props.t("EmailNotifications")}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4} md={6}>
                                    {this.IntervalSetter("email")}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>);
    }

    settingsList = () => {
        return <Grid style={{ margin: 4, borderRadius: 4}}>
            <Typography style={{padding: "12px 12px 0px 12px", fontSize: 18}}>{this.props.t("IWantNotificationsWhen")}</Typography>
            {([...this.state.settings] || []).map((item, idx) => {
                return(
                    <Grid container justifyContent="flex-start" alignItems="center" direction="row" key={idx} 
                        style={{minHeight: 60, padding: 6}}>
                        <Grid item xs={12} style={{fontSize: "1rem"}}>
                            <Tooltip title={this.props.t(item.type + "TT")}>  
                                <FormControlLabel style={{fontSize: "1rem", marginRight: 0}}
                                    control={<Switch checked={item.enabled} 
                                        onChange={(e) => this.handleSettingChange(item, idx)}/>}
                                    label={this.props.t(item.type)}
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>;
    };

    tenantsList = () => {
        return( 
            <Grid xs={12} item style={{minHeight: 60, margin: 8, border: "1px solid gray", borderRadius: 4}}>
                <Typography style={{padding: 16, borderBottom: "1px solid gainsboro"}}>
                    {this.props.t("showNotificationsBySource")}</Typography>
                {([...this.state.tenants] || []).map((item, idx) => {
                    return(
                        <Grid container alignItems="center" direction="row" key={idx} >
                            <Grid sm={9} item style={{width: "100%", minHeight: 60, padding: 8}}>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<CheckBox 
                                            checked={item.selected} onChange={(e) => this.handleTenantChange(item, idx)} name="" />}
                                        label={item.name}
                                    />      
                                </FormGroup>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        );
    }

    render() {
        return (
            <div id="NotificationSettings" style={{margin: "1px", textAlign: "start", transition: "all 500ms ease-out 0s"}}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" >
                    {this.state.settings && this.state.channels ? 
                        <Grid xs={12} item >
                            {this.mainSettings()}
                            {this.settingsList()}
                            {this.state.tenants ?
                                this.tenantsList()
                                :
                                <Spinner open={true}/>}
                        </Grid>
                        :
                        <Spinner open={true}/>
                    }
                </Grid>
            </div>
        );
    }
}

export default (NotificationSettings);