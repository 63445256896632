import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function tagsReducer(state = initialState.tags, action) {
    if (action.type === types.LOAD_TAGS_SUCCESS) {
        return action.tags;
    }
    else {
        return state;
    }
}
