import React from "react";
import SnackbarContentWrapper from "./common/SnackBar";
import clsx from "clsx";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators, compose } from "redux";
import * as notificationActions from "../redux/actions/notificationsActions";

const styles = ({
    success: {
        position: "fixed",
        zIndex: "1400",
        maxWidth: 360,
        minWidth: 200,
        bottom: 5,
        right: 10,
        marginLeft: 10
    },
});


export class Toasts extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            toasts: []
        };
    }
    
    componentDidUpdate = (prevProps) => {
        this.addToast(this.props.toast);
    }

    addToast = (notification) => {
        if (!!notification && this.state.toasts.filter((n) => n.message === notification.message).length === 0) {
            this.props.actions.clearToasts();
            const newArr = this.state.toasts;
            newArr.push(notification);
            this.setState({
                toasts: newArr
            });
        }
    }

    handleCloseSnack = (idx) => {
        const newArr = this.state.toasts;
        newArr.splice(idx, 1);
        this.setState({
            toasts: newArr
        });
    }
    
    render = () => {
        const {t} = this.props;
        return (
            <div className={clsx(this.props.classes.success)}>
                { (this.state.toasts || []).map((key, idx) => (
                    <div style={{marginBottom: "5px"}} key={"divsnack" + idx}>
                        <SnackbarContentWrapper
                            key={"snack" + idx}
                            onClose={() => this.handleCloseSnack(idx)}
                            open={true}
                            message={
                                (this.state.toasts[idx].isTranslated ? this.state.toasts[idx].message :
                                    t(this.state.toasts[idx].message) 
                                    + (this.state.toasts[idx].data !== undefined ? this.state.toasts[idx].data : "")) || "Unspecified error"
                            }
                            variant={this.state.toasts[idx].variant}
                            lifetimeMs={this.state.toasts[idx].lifetimeMs}
                        />
                    </div>
                ))}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        toast: state.toast
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            clearToasts: bindActionCreators(notificationActions.clearToasts, dispatch),
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withTranslation("common"), withStyles(styles))(Toasts));

