import * as types from "./actionTypes";
import * as notificationsApi from "../../api/notificationsApi";
import * as constants from "../../components/common/constants";
import {beginApiCall, apiCallError } from "./apiStatusActions";
import * as realTimeActions from "../../redux/actions/realTimeActions";
import { closePushNotifications } from "../../redux/actions/webPushActions";
import {initialNotificationSettings} from "../../utils/initialNotificationSettings";

export const clearToasts = () => {
    return { 
        type: types.CLEAR_TOASTS,
        notification: {}
    };
};

export const toastMessage = (message, variant, isTranslated, lifetimeMs, data) => {
    var toast = {
        message: message || "Unspecified",
        variant: variant || "error",
        isTranslated: isTranslated || false,
        lifetimeMs: lifetimeMs || (variant === "error" ? 8000 : 3000),
        data: data
    };

    return { 
        type: types.TOAST_MESSAGE,
        toast
    };
};

export const updateNotificationContextKey = (notificationContextKey) => {
    return function(dispatch) {
        const action = { type: types.UPDATE_CURRENT_NOTIFICATION_CONTEXT_KEY, notificationContextKey };
        dispatch(action);
        dispatch(closePushNotifications(notificationContextKey));
    };
};

export const readNotificationsSuccess = (notifications) => {
    return { type: types.READ_NOTIFICATIONS_SUCCESS, notifications };
};

export const updateNotificationsSuccess = (notification) => {
    return { type: types.UPDATE_NOTIFICATIONS_SUCCESS, notification };
};

export const readNotifications = (filter, sort, page, tenants, cancelToken) => {
    return async function(dispatch) {
        return await notificationsApi
            .readNotifications(filter, sort, page, tenants, cancelToken)  
            .then((result) => {
                dispatch(readNotificationsSuccess(result));
            })
            .catch((error) => {
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                    !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(toastMessage("ErrorLoadingNotifications", "error"));
                    throw new Error(error);
                }
            });
    };
}; 

export const formNotifications = (notification, sendTo) => {
    var result = [];
    if(notification.state === "Deleted") {
        return [notification];
    }

    if(notification.state === "Modified"){
        return [notification];
    }

    var currentUser = notification.currentUser;
    notification.uri = notification.link;
    notification.time = new Date().toLocaleString(localStorage.getItem("i18nextLng"), {timeStyle: "short", dateStyle: "short" });
    notification.Channels = {
        Email: {SendToTime: "", Sent: false, localization: localStorage.getItem("i18nextLng")}, 
        WebPush: {SendToTime: "", Sent: false, localization: localStorage.getItem("i18nextLng")}
    };

    sendTo.forEach((user) => {
        if(user.id !== currentUser) {
            var newNotification = JSON.parse(JSON.stringify({...notification}));
            newNotification.userId = user.id;
            result.push(newNotification);
        }
    });

    return result;
};

export const updateNotifications = (notification, tenantId) => {
    return async function(dispatch) {
        return notificationsApi
            .updateNotifications(notification)  
            .then((result) => {
                dispatch(updateNotificationsSuccess(result));
                if(notification.state === "Added" && result !== ""){
                    var realTimeMessage = {
                        collectionId: constants.notifications,
                        notification: {...notification, id: result, tenantId: tenantId},
                        userId: notification.userId
                    };

                    dispatch(realTimeActions.sendRealTimeMessage(realTimeMessage));
                }
                    
                return result;
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (constants.UniqueKeyConstraintViolation === error ||
                    constants.UniqueKeyConstraintViolation === error.message) {
                    dispatch(toastMessage("Error Sending Notifications", "error"));
                }
                else if (error.message && error.message.toLowerCase().includes("preconditionfailed")) {
                    return constants.PreconditionFailed;   
                }
                else if (!error.toString().includes("PreconditionFailed")) {
                    dispatch(toastMessage("ErrorSendingNotification", "error"));
                }
    
                throw new Error(error);
            });
    };
};

export const updateNotificationSettings = (settings) => {
    return function(dispatch) {
        return notificationsApi
            .updateNotificationSettings(settings)  
            .then((result) => {
                dispatch(updateNotificationSettingsSuccess(settings));
                return result;
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (constants.UniqueKeyConstraintViolation === error ||
                    constants.UniqueKeyConstraintViolation === error.message) {
                    dispatch(toastMessage("Error updating Notification settings", "error"));
                }
                else if (error.message && error.message.toLowerCase().includes("preconditionfailed")) {
                    return constants.PreconditionFailed;   
                }
                else if (!error.toString().includes("PreconditionFailed")) {
                    dispatch(toastMessage("ErrorSendingNotificationSettings", "error"));
                }
    
                throw new Error(error);
            });
    };
};

export const readNotificationSettingsSuccess = (settings) => {
    return { type: types.READ_NOTIFICATIONSETTINGS_SUCCESS, settings };
};

export const updateNotificationSettingsSuccess = (settings) => {
    return { type: types.UPDATE_NOTIFICATIONSETTINGS_SUCCESS, settings };
};

export const readNotificationSettings = (user, tenants) => {
    return function(dispatch) {
        dispatch(beginApiCall());
        return notificationsApi
            .readSettings(user.sub)  
            .then((settings) => {
                if(settings.localization !== localStorage.getItem("i18nextLng") || (!!tenants && tenants.length !== settings.tenants.length)) {
                    settings.localization = localStorage.getItem("i18nextLng");
                    if (!!tenants) {
                        var tenantArray = [];
                        tenants.forEach((tenant) => {
                            tenantArray.push({TenantId: tenant.id, name: tenant.name, selected: true});
                        });

                        settings.tenants = tenantArray;
                    }

                    settings.state = constants.Modified;
                    dispatch(updateNotificationSettings(settings));
                }
                
                dispatch(readNotificationSettingsSuccess(settings));
            })
            .catch((error) => {
                if(error.message === "Not Found") {
                    var settings = initialNotificationSettings(localStorage.getItem("i18nextLng"), tenants, user.email);
                    dispatch(updateNotificationSettings(settings)).then(() => {
                        dispatch(readNotificationSettings(user, tenants));
                    });
                }
                else {
                    dispatch(toastMessage("ErrorLoadingNotificationSettings", "error"));
                }
            });
    };
}; 

export const updateNotificationsByContext = async (props, cancelToken) => {
    var id = null;
    [...props.notifications].every((element) => {
        if(element.contextKey === props.workBoard.tenantId + "_" + props.workOrder.workOrderNumber && element.type !== "messageNotification"){
            id = element.id;
            return false;
        }
        else {
            return true;
        }
    });

    var notification = {
        type: "deleteByContext",
        title: "", 
        linkName: "",
        contextKey: props.workBoard.tenantId + "_" + props.workOrder.workOrderNumber,
        description: "",
        link: "",
        state: "Deleted",
        currentUser: props.currentUser.id,
        payload: {},
        id: id
    };

    if(id !== null){         
        var notifications = formNotifications(notification);
        var promises = [];
        notifications.forEach((item) => {
            promises.push(props.actions.updateNotifications(item, props.workBoard.tenantId));
        });

        Promise.all(promises).then(() => {
            props.actions.readNotifications("", "time DESC", 0, props.notificationSettings.tenants, cancelToken.token);
        })
            .catch(() => {});
    }
};
