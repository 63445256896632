import { handleResponse, handleError, getApiUrl, serviceKeys } from "./apiUtils";
import getRequestOptions from "./authorizationUtils";
import axios from "axios";
import * as constants from "../components/common/constants";
import FilterHelper from "../helpers/filterHelper"; 

export const readTenant = (tenantId, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.tenants) + `/api/Aggregate/tenants/Get/${tenantId}`, options)
        .then(handleResponse)
        .catch(handleError);
};

export const getAllAvailableTenants = (filter, sort, page, cancelToken, tags) => {
    filter = filter ? filter : "";
    sort = sort ? sort : "Tenant.Name ASC";
    page = page ? page : 1;

    var data = {
        filter: filter,
        sort: sort,
        page: page,
        searchFields: FilterHelper.getSearchfilter(constants.TenantFilter, ["Tenant.Name"]),
        filterTags: tags,
    };
    
    const options = getRequestOptions(cancelToken);
    return axios.post(getApiUrl(serviceKeys.tenants) + `/api/Aggregate/tenants/GetAll`, data, options)
        .then(handleResponse)
        .catch(handleError);
};

export const createtenant = (tenant) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.tenants) + `/api/Aggregate/tenants/createtenant`, tenant, options)
        .then(handleResponse)
        .catch(handleError);
};

export const updatetenant = (id, tenant) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.tenants) + `/api/Aggregate/tenants/updatetenant/${id}`, tenant, options)
        .then(handleResponse)
        .catch(handleError);
};

export const deletetenant = (id) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.tenants) + `/api/Aggregate/tenants/deletetenant/${id}`, null, options)
        .then(handleResponse)
        .catch(handleError);
};
