import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function chatMessagesUnreadCounterReducer(state = initialState.chatChannelUnReadMessagesCounter, action) {
    if (action.type === types.LOAD_CHAT_UNREAD_MESSAGES_COUNTER_SUCCESS) {
        return action.chatChannelUnReadMessagesCounter;
    }
    else if (action.type === types.INCREMENT_TOTAL_NUMBER_OF_MESSAGES) {
        if (state.find((ch) => ch.channelId === action.channelId)) {
            const stateClone = JSON.parse(JSON.stringify(state));
            const channel = stateClone.find((ch) => ch.channelId === action.channelId);
            channel.totalNumberOfMessages++;
            return stateClone;
        }
        else {
            return state;
        }
    }
    else {
        return state;
    }
}