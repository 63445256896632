import React, {Component, Fragment} from "react";
import {ai} from "../services/TelemetryService";
import {withAITracking} from "@microsoft/applicationinsights-react-js";
import {withRouter} from "react-router-dom";
import env from "../utils/env";

export class TelemetryProvider extends Component {
    state = {
        initialized: false
    };

    componentDidUpdate (prevProps){
        if (prevProps.user !== this.props.user || prevProps.tenant !== this.props.tenant) {
            this.setState({
                initialized: false
            });

            this.initTelemetry();
        }
    }

    componentDidMount() {
        this.initTelemetry();
    }

    initTelemetry = () => {
        if (this.props.user && this.props.tenant.id) {
            const {history} = this.props;
            const {initialized} = this.state;
            const AppInsightsInstrumentationKey = env.appInsightsKey; 
            
            if (!Boolean(initialized) && Boolean(AppInsightsInstrumentationKey) && Boolean(history)) {
                ai.initialize(AppInsightsInstrumentationKey, history, this.props.user.profile.sub, this.props.tenant.id, 
                    !!this.props.tenant.licenseInfo ? this.props.tenant.licenseInfo.id : "undefined");
                
                this.setState({initialized: true});
            }
            
            this.props.after();
        }
    }

    render() {
        const {children} = this.props;
        return (
            <Fragment>
                {children}
            </Fragment>
        );
    }
}

export default withRouter(withAITracking(ai.getReactPlugin(), TelemetryProvider));
