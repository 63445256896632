import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from "./authorizationUtils";
import axios from "axios";

// azure functions can not handle ambigous routes
// we need explicit defined those

export const readWorkOrderTemplates = (workBoardId, cancelToken, documentId) => {
    const options = getRequestOptions(cancelToken);
    var data = {
        filter: null,
        BoardId: workBoardId,
        sort: "",
        DocumentId: documentId,
        page: 0,
        searchFields: [],
        filterTags: null
    };
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/WorkOrder/GetAllTemplates`, data, options)
        .then(handleResponse)
        .catch(handleError);
};

export const readWorkOrderTemplate = (id, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.default) + `/api/Aggregate/WorkOrder/GetTemplate/${id}`, options)
        .then(handleResponse)
        .catch(handleError);
};

export const updateWorkOrderTemplate = (model) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/WorkOrder/POSTTemplate`, model, options)
        .then(handleResponse)
        .catch(handleError);
};

export const documentExistsInTemplates = (workBoardId, documentId, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.default) + `/api/Aggregate/WorkOrder/documentExistsInTemplates` +
    `?workBoardId=${workBoardId}&documentId=${documentId}`, options)
        .then(handleResponse)
        .catch(handleError);
};

