import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function catalogReducer(state = initialState.catalog, action) {
    if (action.type === types.READ_CATALOG_SUCCESS || action.type === types.UPDATE_CATALOG_SUCCESS) {
        return action.catalog;
    }
    else {
        return state;
    }
}
