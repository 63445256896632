import { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as serviceWorkerActions from "../redux/actions/serviceWorkerActions";
import * as notificationsActions from "../redux/actions/notificationsActions";
import { withTranslation } from "react-i18next";

//const checkUpdatesInterval = 600000; // 10 min

export class ServiceWorkerTracker extends Component {
    constructor () {
        super();
        this.state = {
            updateCalled: false,
        };
    }

    componentDidMount() {
        if (!!this.props.oidc && !!this.props.oidc.user && !this.props.oidc.user.expired) {
            // Try to update the service worker immediately after the user has logged in
            this.setState({ updateCalled: true });
            this.props.actions.update();
        }
    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps, prevState) {
        if (!!this.props.oidc && !!this.props.oidc.user && !this.props.oidc.user.expired) {
            // Try to update the service worker immediately after the user has logged in
            if (!this.state.updateCalled) {
                this.setState({ updateCalled: true });
                this.props.actions.update();
            }

            // Show update notification if the user is logged in and a new update becomes available
            if (this.props.serviceWorker.waitingUpdate && (!prevProps.serviceWorker.waitingUpdate)) {
                this.props.actions.toastMessage("NewVersionAvailable", "info", false, 60000);
            }
        }
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        oidc: state.oidc,
        serviceWorker: state.serviceWorker
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            update: bindActionCreators(serviceWorkerActions.update, dispatch),
            toastMessage: bindActionCreators(notificationsActions.toastMessage, dispatch)
        }
    };
};

export default compose(withTranslation("common"),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ServiceWorkerTracker);
