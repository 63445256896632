import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function workSiteAttachmentReducer(state = initialState.workSiteAttachment, action) {
    if (action.type === types.READ_WORKSITE_ATTACHMENTS_SUCCESS) {
        return action.workSiteAttachment;
    }
    else {
        return state;
    }
}
