import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function contactsReducer(state = initialState.timeSheets, action) {
    if (action.type === types.LOAD_TIMESHEET_SUCCESS) {
        return action.timeSheets;
    }
    else {
        return state;
    }
}
