import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function workOrderTemplateReducer(state = initialState.workOrderTemplate, action) {
    if (action.type === types.READ_WORKORDER_TEMPLATE_SUCCESS) {
        return action.workOrderTemplate;
    }
    else {
        return state;
    }
}
