import {ApplicationInsights, Telemetry} from "@microsoft/applicationinsights-web";
import {ReactPlugin} from "@microsoft/applicationinsights-react-js";
import env from "../utils/env";
import meta from "../utils/meta.json";

let dict = {
    productsRegister: {
        list: "Products Register",
        edit: "Product Register" 
    },
    productGroups: {
        list: "Product Groups",
        edit: "Product Group" 
    },
    productUnits: {
        list: "Product Units",
        edit: "Product Unit" 
    },
    contacts: {
        list: "Contacts",
        edit: "Contact" 
    },
    customers: {
        list: "Customers",
        edit: "Customer" 
    },
    worksites: {
        list: "Work Sites",
        edit: "Work Site" 
    },
    workOrders: {
        list: "Work Orders",
        edit: "Work Order" 
    },
    workBoards: {
        list: "Work Boards",
    },
    workBoard: {
        edit: "Work Board" 
    },
    usersManagement: {
        list: "Users Management",
        edit: "User Management" 
    },
    roles: {
        list: "Roles",
        edit: "Role" 
    },
    tenants: {
        edit: "Tenant" 
    },
};


export const reactPlugin = new ReactPlugin();

// eslint-disable-next-line max-lines-per-function
const createTelemetryService = () => {
    let appInsights = null;

    const initialize = (instrumentationKey, browserHistory, userId, tenantId, licenseId) => {
        if (!browserHistory) {
            throw new Error("Could not initialize Telemetry Service");
        }

        if (!instrumentationKey) {
            throw new Error("Instrumentation key not provided in ./src/telemetry-provider.jsx");
        }

        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey,
                maxBatchInterval: 15000,
                disableAjaxTracking: false,
                disableFetchTracking: false,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {
                        history: browserHistory
                    }
                },
                disableTelemetry: env.disableTelemetry,
            }
        });

       
        const telemetryInitializer = (envelope) => {
            if (envelope.baseType === Telemetry.PageView.dataType || envelope.baseType === Telemetry.PageViewPerformance.dataType) {
                adjustName(envelope.baseData.name);        
            }

            envelope.data.appVersion = meta.appVersion;
            envelope.data.userId = userId;
            envelope.data.tenantId = tenantId;
            envelope.data.license = licenseId;
        };

        appInsights.loadAppInsights();
        appInsights.addTelemetryInitializer(telemetryInitializer);
    };

    const getReactPlugin = () => reactPlugin;
    const getAppInsights = () => appInsights;

    return {getReactPlugin, getAppInsights, initialize};
};

const adjustName = (itemname) => {
    var name = itemname.replace("AppName", "");
    var start = itemname.indexOf("/");
    if (start !== -1) {
        start = start + 1;
        var end = itemname.indexOf("/", start);
        if (end === -1) {
            end = itemname.indexOf("?", start);
        }
      
        if (end !== -1) {
            name = itemname.substring(start, end);
        }
        else {
            name = itemname.substring(start, itemname.lenght);
        }
      
        if (dict[name]) {
            if (end !== -1) {
                if (dict[name].edit) {
                    name = dict[name].edit;
                }
                else {
                    name = dict[name].list;
                }
            }
            else {
                if (dict[name].list) {
                    name = dict[name].list;
                }
                else {
                    name = dict[name].edit;
                }
            }
        }
    }

    return name;
};

export const ai = createTelemetryService();
export const getAppInsights = ai.getAppInsights;
