import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function contactReducer(state = initialState.contact, action) {
    if (action.type === types.READ_CONTACT_SUCCESS) {
        return action.contact;
    }
    else {
        return state;
    }
}
