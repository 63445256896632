// eslint-disable-next-line import/no-anonymous-default-export
export default {
    network: {
        offline: false,
    },
    oidc: null,
    apiCallsInProgress: 0,
    serviceWorker: {
        waitingUpdate: false
    },
    tenant: {
        id: null,
        name: "", 
        host: "",
        uniqueKey: "",
        rootGroup: "",
        companyInfo: {
            name: "",
            wwwAddress: "",
            vatCode: "",
            contactInfo: "",
            logoBase64: ""
        },
        licenseInfo: {
            id: "",
            maxNumberOfTeams: 0,
            maxNumberOfUsers: 0,
            maxFileStorageBytes: 0
        },
        modules: [] 
    },
    availableTenants: [],
    workCards: [],
    blobProgress: {
        size: 0,
        progress: 0
    },
    workCard: {
        workCard: {
            id: "",
            number: 0,
            title: "",
            description: "",
            workSiteNumber: 0,
            projectId: "",
            startDate: "2019-03-04T00:00:00",
            endDate: "2019-07-28T00:00:00",
            employeeName: "",
            sendAsEmail: false,
            signature: "",
            state: "Unchanged",
            hasReportingLevels: false
        },
        additional: {},
        normal: {},
        infoRows: [],
        approver: null,
        state: "Unchanged",
        customer: {
            name: "",
            phone: "",
            email: "",
            address: "",
            reference: ""
        },
        contact: {
            name: "",
            phone: "",
            email: "",
        },
    },
    workCardDocument: {
        templateLoaded: false,
        blob: null,
        rendered: false,
        sent: false,
    },
    workOrderTemplates: [],
    workOrderTemplate: {
        id: "",
        title: "",
        tasks: [],
        tags: [],
        attachments: []
    },
    translationLoaded: null,
    notification: null,
    notificationContextKey: "",
    notificationSettings: {},
    toast: null,
    notifications: [],
    webPush: { 
        supported: false, 
        subscribed: false,
        expirationTime: undefined 
    },
    sessionMetadata: {
        logoutInProgress: false,
    },
    isSessionActive: false,
    persons: [],
    productsRegister: [],
    productRegister: {
        productRegister: {
            itemCode: "",
            name: "",
            description: "",
            unit: "",
            costPrice: 0,
            basicSalePrice: 0,
            productGroup: "",
            quantity: 0
        },
        productsBuilderDetails: []
    },
    productGroups: [],
    productGroup: {
        itemCode: "",
        name: ""
    },
    productUnits: [],
    productUnit: {
        itemCode: "",
        name: ""
    },
    navigation: {
        module: {
            name: "",
            url: ""
        },
        context: {
            parent: "",
            title: "",
        },
        showModuleTitle: true,
    },
    ui: {
        width: 0,
        height: 0,
        bodyHeight: 0,
        topNavBarHeight: 0,
        contentHeight: 0,
        contentWidth: 0
    },
    contacts: [],
    contact: {
        id: "00000000-0000-0000-0000-000000000000",
        name: "",
        email: "",
        phone: "",
        customerNumber: 0,
        title: "",
        tags: []
    },
    customers: [],
    customer: {
        id: 0,
        customerNumber: 0,
        name: "",
        vatCode: "",
        tags: [],
        paymentInfoModel: {
            eInvoiceAddress: "",
            eInvoiceOperator: "",
            paymentType: "",
            accountGroup: ""
        },
        invoiceAddress: {
            address: "",
            addressLine2: "",
            postalCode: "",
            city: "",
            country: "",
        },
        contactId: "00000000-0000-0000-0000-000000000000"
    },
    catalog: {
        id: "",
        items: []
    },
    workSite: {
        name: "",
        number: 0,
        key: "",
        address: {
            address: "",
            addressLine2: "",
            postalCode: "",
            city: "",
            country: "",
        },
        contacts: [],
        description: "",
        descriptionOnInvoice: "",
        customerNumber: 0,
        worksiteNumber: 0,
        tags: [],
        id: "",
        startTime: null,
        deliveryTime: null
    },
    workOrders: [],
    workSites: [],
    workQueues: [],
    workOrder: {
        name: "",
        workOrderNumber: 0,
        workSiteId: "0",
        internalDescription: "",
        invoiceDescription: "",
        tags: [],
        members: [],
        progress: {total: 0, completed: 0},
        agreedInterval: {
            startTime: null,
            endTime: null
        },
        products: [],
        contacts: [],
        assigned: "",
        status: "",
        workOrderTasks: []
    },
    address: {
        address: "",
        addressLine2: "",
        postalCode: "",
        city: "",
        country: "",
    },
    realTimeMessage: {
        collectionId: "",
        documentId: "",
    },
    isConnectionSignalActive: false,
    isConnectionLost: false,
    workOrderDiaryEntry: {
        id: "00000000-0000-0000-0000-000000000000",
        documentId: "00000000-0000-0000-0000-000000000000",
        workOrderId: "",
        blobType: "",
        filename: "",
        fileExtension: "",
        mimeType: "",
        description: "",
        createdBy: "00000000-0000-0000-0000-000000000000",
        timeCreated: "2019-03-04T00:00:00",
        modifiedBy: "00000000-0000-0000-0000-000000000000",
        TimeModified: "2019-03-04T00:00:00",
    },
    workOrderDiaryEntries: [],
    currentWorkBoardId: null,
    currentWorkQueueIndex: 0,
    workQueue: {
        title: "",
        workBoardId: "",
        ownerId: "",
        roles: [],
        workOrders: [],
        defaultStatus: 0
    },
    workBoardAggregate: {
        workBoard: {
            name: "",
            ownerId: "",
            members: [],
            invitations: [],
            description: "",
            workQueues: [],
        },
        workQueues: {},
        workOrders: {},
        workSites: {},
        tags: []
    },
    workBoards: [],
    usersGeneralInfo: [],
    users: [],
    currentUser: null,
    user: {
        username: "",
        enabled: false,
        email: "",
        emailVerified: false,
        firstName: "",
        lastName: "",
        attributes: {
            displayName: "",
            displayEmail: "",
            locale: "",
        },
        realmRoles: [],
    },
    role: {
        id: "",
        name: "",
        permissions: {}
    },
    roles: {},
    userPermissions: {},
    tag: {
        id: ""
    },
    tags: [],
    pendingRouter: {
        pendingRouter: "",
        name: "",
        stopRouter: false,
        continueSaving: false,
        goMenuOption: false
    },
    chatChannel: {
        token: "",
        name: "",
        description: "",
        members: [],
        state: "",
        id: "",
    },
    chatMessage: {
        channelId: "",
        type: "",
        text: "",
        data: { },
        sender: "",
        tenantIdChannelId: "",
        state: "",
        sendDateTime: "2019-03-04T00:00:00"
    },
    chatMessages: [],
    catalogTitle: [],  
    catalogPaymentType: [],  
    catalogAccountGroup: [],  
    catalogDefaults: [],
    catalogTimeSheetType: [],
    chatChannelUnReadMessagesCounter: [],
    tokenInfo: {
        type: "",
        sharedId: "",
        expireTime: "",
        payload: {},
        token: "",
        state: ""
    },
    isSharedToken: false,
    tokensInfo: [],
    historyEntries: [],
    historyDataType: "",
    accessToken: "",
    membersTeam: [],
    blockScroll: false,
    timeSheet: {
        userId: "",
        entryDate: "2019-03-04T00:00:00",
        description: "",
        totalQuantity: 0,
        items: [],
        state: ""
    },
    timeSheets: [],
    workOrderDiaryEntriesCounter: [],
    blobsProgress: [],
    blobsAbortProgress: [],
    filterWorkBoard: {
        text: "", 
        tags: [], 
        tagtext: "", 
        userFilters: []
    },
    formTemplate: {
        id: "",
        name: "",
        description: "",
        fields: [],
        state: "",
        tags: []
    },
    formTemplates: [],
    touchesWorkboard: {
        clientX: 0,
        clientY: 0
    },
    customersAttachment: {
        id: "00000000-0000-0000-0000-000000000000",
        documentId: "00000000-0000-0000-0000-000000000000",
        workOrderId: "",
        blobType: "",
        filename: "",
        fileExtension: "",
        mimeType: "",
        description: "",
        createdBy: "00000000-0000-0000-0000-000000000000",
        timeCreated: "2019-03-04T00:00:00",
        modifiedBy: "00000000-0000-0000-0000-000000000000",
        TimeModified: "2019-03-04T00:00:00",
    },
    customersAttachments: [],
    workSiteAttachment: {
        id: "00000000-0000-0000-0000-000000000000",
        documentId: "00000000-0000-0000-0000-000000000000",
        workSiteId: "",
        blobType: "",
        filename: "",
        fileExtension: "",
        mimeType: "",
        description: "",
        createdBy: "00000000-0000-0000-0000-000000000000",
        timeCreated: "2019-03-04T00:00:00",
        modifiedBy: "00000000-0000-0000-0000-000000000000",
        TimeModified: "2019-03-04T00:00:00",
    },
    workSiteAttachments: [],
};