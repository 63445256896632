import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function navigationReducer(state = initialState.navigation, action) {
    if (action.type === types.SET_MODULE) {
        const navigation = {
            ...state,
            module: action.modules,
            context: {
                title: "",
                parent: state.context.parent
            }
        };
        return navigation;
    }
    else if (action.type === types.SET_CONTEXT) {
        const navigation = {
            ...state,
            context: {
                title: action.title,
                parent: action.parent
            }

        };
        return navigation;
    }
    else if(action.type === types.SHOW_MODULE_TITLE){
        const navigation = {
            ...state,
            showModuleTitle: action.show
        };
        return navigation;
    }
    else {
        return state;
    }
}
