import * as types from "./actionTypes";
import * as workOrderDiaryEntryApi from "../../api/workOrderDiaryEntryApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";
import * as constants from "../../components/common/constants";
import * as usersApi from "../../api/usersApi";
import * as formTemplatesApi from "../../api/formTemplatesApi";
import * as historyEntryActions from "./historyEntryActions";

export const readWorkOrderDiaryEntriesSuccess = (workOrderDiaryEntries) => {
    return { type: types.LOAD_WORKORDER_DIARYENTRY_SUCCESS, workOrderDiaryEntries };
};

export const readWorkOrderDiaryEntries = (sort, page, workOrderId, cancelToken, tagsandFilter, sharedToken) => {
    return async function(dispatch) {
        var settings = [];
        await workOrderDiaryEntryApi
            .ReadWorkOrderDiaryEntries(sort, page, workOrderId, cancelToken, tagsandFilter, "", sharedToken)  
            .then((workOrderDiaryEntries) => {
                settings = workOrderDiaryEntries;
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notifications.toastMessage("ErrorloadingWorkOrderDiaryEntries", "error"));
                    throw new Error(error);
                }
            });

        var dict = {};
        var dictFormTemplate = {};
        settings.map((element) => {
            if (element.modifiedBy !== "") {
                dict[element.modifiedBy] = "";
            }

            if (element.createdBy !== "") {
                dict[element.createdBy] = "";
            }

            if (element.blobType.toLowerCase() === constants.fileType.formTemplate.toLowerCase() && !element.payLoad.isPDFSaved) {
                dictFormTemplate[element.payLoad.id] = "";
            }
    
            return element;
        });
    
        var batch = [];
        for(var key in dict) {
            batch.push(key);
        }
    
        var sendData = {
            batch: batch
        };
        
        await getUserBatch(sendData, cancelToken, settings, dispatch);

        if (Object.keys(dictFormTemplate).length > 0) {
            batch = [];
            for(var keyf in dictFormTemplate) {
                batch.push(keyf);
            }
        
            sendData = {
                batch: batch
            };
    
            await getFormTemplateBatch(sendData, cancelToken, settings, dispatch);
        }
        else {
            dispatch(readWorkOrderDiaryEntriesSuccess(settings));
        }
    };
}; 

export const readWorkOrderDiaryEntrySuccess = (workOrderDiaryEntry) => {
    return { type: types.READ_WORKORDER_DIARYENTRY_SUCCESS, workOrderDiaryEntry };
};

const getUserBatch = async(sendData, cancelToken, settings, dispatch) => {
    await usersApi
        .ReadUserBatch(sendData, cancelToken)  
        .then((result) => {
            for (var x = 0; x < settings.length; x++) {
                var created = findElement(result, settings[x].createdBy);
                var modified = findElement(result, settings[x].modifiedBy);
                if (created) {
                    settings[x].createdBy = created;
                    if(modified){
                        settings[x].modifiedBy = modified;
                    }
                }
                else {
                    settings[x].modifiedBy = "";
                    settings[x].createdBy = "";
                }
            }
        })
        .catch((error) => {
            dispatch(apiCallError(error));
            if (error.message !== constants.cancelHttpRequest && !error.toString().includes(constants.cancelHttpRequest)) {
                dispatch(notifications.toastMessage("ErrorLoadingUsers", "error"));
                throw new Error(error);
            }
        });
};

const getFormTemplateBatch = async(sendData, cancelToken, settings, dispatch) => {
    await formTemplatesApi
        .ReadFormTemplateBatch(sendData, cancelToken)  
        .then((result) => {
            for (var x = 0; x < settings.length; x++) {
                if (settings[x].payLoad) {
                    var item = findElement(result, settings[x].payLoad.id);
                    if (item) {
                        settings[x].payLoad = item;
                        settings[x].formTemplateId = settings[x].payLoad.id;
                    }
                }
            }
           
            dispatch(readWorkOrderDiaryEntriesSuccess(settings));
        })
        .catch((error) => {
            dispatch(apiCallError(error));
            if (error.message !== constants.cancelHttpRequest && !error.toString().includes(constants.cancelHttpRequest)) {
                dispatch(notifications.toastMessage("ErrorloadingFormTemplates", "error"));
                throw new Error(error);
            }
        });
};

const findElement = (items, item) => {
    return items.find((e) => e.id === item);
};

export const readWorkOrderDiaryEntry = (id, cancelToken) => {
    return function(dispatch) {
        dispatch(beginApiCall());
        return workOrderDiaryEntryApi
            .ReadWorkOrderDiaryEntry(id, cancelToken)  
            .then((workOrderDiaryEntry) => {
                dispatch(readWorkOrderDiaryEntrySuccess(workOrderDiaryEntry));
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notifications.toastMessage("ErrorloadingWorkOrderDiaryEntry", "error"));
                    throw new Error(error);
                }
            });
    };
}; 

export const updateWorkOrderDiaryEntrySuccess = (workOrderDiaryEntry) => {
    return { type: types.UPDATE_WORKORDER_DIARYENTRY_SUCCESS, workOrderDiaryEntry };
};

export const updateWorkOrderDiaryEntry = (workOrderDiaryEntry, cancelToken, sharedToken) => {
    return function(dispatch) {
        dispatch(beginApiCall());
        return workOrderDiaryEntryApi
            .updateWorkOrderDiaryEntry(workOrderDiaryEntry, cancelToken, sharedToken)  
            .then((result) => {
                dispatch(updateWorkOrderDiaryEntrySuccess(result));
                dispatch(historyEntryActions.updateHistoryEntry(workOrderDiaryEntry, constants.collections.workOrderDiaryEntry));
                return result;
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                dispatch(notifications.toastMessage("ErrorUpdatingWorkOrderDiaryEntry", "error"));
                throw new Error(error);
            });
    };
}; 

export const readWorkOrderDiaryEntryByDocumentId = (documentId) => {
    return async function(dispatch) {
        return await workOrderDiaryEntryApi
            .ReadWorkOrderDiaryEntries(undefined, undefined, undefined, undefined, [], documentId)  
            .then((workOrderDiaryEntries) => {
                if (workOrderDiaryEntries.length === 0) {
                    return false;
                }

                return true;
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notifications.toastMessage("ErrorGettingorkOrderDiaryEntryByDocumentId", "error"));
                    throw new Error(error);
                }
            });
    };
}; 

export const GetAttachmentsCounterSuccess = (workOrderDiaryEntriesCounter) => {
    return { type: types.COUNTER_WORKORDER_DIARYENTRY_SUCCESS, workOrderDiaryEntriesCounter };
};

export const GetAttachmentsCounter = (ids, cancelToken) => {
    return function(dispatch) {
        return workOrderDiaryEntryApi
            .GetAttachmentsCounter(ids, cancelToken)  
            .then((result) => {
                dispatch(GetAttachmentsCounterSuccess(result));
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                dispatch(notifications.toastMessage("ErrorloadingWorkOrderAttachmentsCounter", "error"));
                throw new Error(error);
            });
    };
}; 

export const UpdateAttachmentsCounter = (workOrderDiaryEntriesCounter, realTimeMessage) => {
    return function(dispatch) {
        const { workOrderId, state } = realTimeMessage;
        if (state === constants.Added || state === constants.Deleted) {
            var idx = workOrderDiaryEntriesCounter.findIndex((e) => e.workOrderId === workOrderId);
            if (idx > -1) {
                let temp = JSON.parse(JSON.stringify(workOrderDiaryEntriesCounter));
                temp[idx].totalNumber = state === constants.Added ? temp[idx].totalNumber + 1 :
                    state === constants.Deleted && temp[idx].totalNumber > 0 ? 
                        temp[idx].totalNumber - 1 : temp[idx].totalNumber;

                dispatch(GetAttachmentsCounterSuccess(temp));
            }
        }

        return null;
    };
};

