import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from "./authorizationUtils";
import axios from "axios";
import FilterHelper from "../helpers/filterHelper";
import * as constants from "../components/common/constants";

// azure functions can not handle ambigous routes
// we need explicit defined those

export const ReadWorkOrderDiaryEntries = (sort, page, workOrderId, cancelToken, tagsandFilter, documentId, sharedToken = "") => {
    const options = getRequestOptions(cancelToken);
    var data = {
        filter: tagsandFilter ? tagsandFilter.filter : null,
        sort: sort,
        page: page,
        searchFields: FilterHelper.getSearchfilter(constants.diaryEntryFilter),
        filterTags: tagsandFilter ? tagsandFilter.tags : null,
        workOrderId: workOrderId,
        documentId: documentId,
        sharedToken: sharedToken
    };

    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/WorkOrderDiaryEntry/GetAll`, data, options)
        .then(handleResponse)
        .catch(handleError);
};

export const ReadWorkOrderDiaryEntry = (id, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.default) + `/api/Aggregate/WorkOrderDiaryEntry/Get/${id}`, options)
        .then(handleResponse)
        .catch(handleError);
};

export const updateWorkOrderDiaryEntry = (diaryentry, cancelToken, sharedToken) => {
    const options = getRequestOptions(cancelToken);
    const urlParams = (sharedToken && `?sharedToken=${sharedToken}`) || "";
    return axios.post(getApiUrl(serviceKeys.default) + "/api/Aggregate/WorkOrderDiaryEntry/POST" + urlParams, diaryentry, options)
        .then(handleResponse)
        .catch(handleError);
};

export const GetAttachmentsCounter = (ids, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    var data = {
        ids: ids
    };

    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/WorkOrderDiaryEntry/GetAttachmentsCounter`, data, options)
        .then(handleResponse)
        .catch(handleError);
};
