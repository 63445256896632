import React from "react";
import AppInsightsHelper from "../helpers/appInsightsHelper";
import { withRouter } from "react-router-dom";
import LayoutSpinner from "./layoutSpinner";

export class RefreshPage extends React.Component {
    constructor() {
        super();

        this.state = {
            refreshUrl: null,
            refreshIndex: 0,
        };
    }

    componentDidMount() {
        AppInsightsHelper.trackEvent("Refresh");           
        this.setState({
            refreshIndex: (this.props.history.location.state.refreshIndex || 0),
            refreshUrl: this.props.history.location.state.refreshUrl
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const refreshIndex = (this.props.history.location.state.refreshIndex || 1);
        if (this.state.refreshIndex !== refreshIndex) {
            this.setState({ refreshIndex: refreshIndex });
        }
        else if (prevState.refreshIndex < this.state.refreshIndex) {
            this.props.history.replace(this.state.refreshUrl, {refreshIndex: this.state.refreshIndex});
        }
    }

    render() {
        return (
            <LayoutSpinner show={true} />
        );
    }
}

export const Refresh = withRouter(RefreshPage);
