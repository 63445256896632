import * as types from "./actionTypes";
import * as usersApi from "../../api/usersApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";
import * as constants from "../../components/common/constants";
import { store } from "../store";
import { parseJwt } from "../../helpers/jwtHelpers";

export const readUsersGeneralInfoSuccess = (usersGeneralInfo) => {
    return { type: types.LOAD_USERS_SUCCESS, usersGeneralInfo };
};

export const ReadUsersGeneralInfo = (filter, sort, page, cancelToken) => {
    return function(dispatch) {
        dispatch(beginApiCall());
        return usersApi
            .ReadUsersGeneralInfo(filter, sort, page, cancelToken)  
            .then((usersGeneralInfo) => {
                dispatch(readUsersGeneralInfoSuccess(usersGeneralInfo));
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notifications.toastMessage("ErrorLoadingUsers", "error"));
                    throw new Error(error);
                }
            });
    };
}; 

export const ReadDisplayInfoBatch = (batch, tenant, cancelToken) => {
    return function(dispatch) {
        dispatch(beginApiCall());
        return usersApi
            .ReadUserDisplayInfoBatch(batch, tenant, cancelToken)  
            .then((batchInfo) => {
                return batchInfo;
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notifications.toastMessage("ErrorLoadingUsers", "error"));
                    throw new Error(error);
                }
            });
    };
}; 

export const readUsersSuccess = (users) => {
    return { type: types.LOAD_DATA_USERS_SUCCESS, users };
};

export const ReadUsers = (filter, sort, page, cancelToken) => {
    return function(dispatch) {
        dispatch(beginApiCall());
        return usersApi
            .ReadUsersGetAll(filter, sort, page, cancelToken)  
            .then((users) => {
                dispatch(readUsersSuccess(users));
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notifications.toastMessage("ErrorLoadingUsers", "error"));
                    throw new Error(error);
                }
            });
    };
}; 

export const getUserSuccess = (user) => {
    return { type: types.READ_USER_SUCCESS, user };
};

export const getUser = (id, cancelToken) => {
    return function(dispatch) {
        dispatch(beginApiCall());
        return usersApi
            .GetUser(id, cancelToken)  
            .then((result) => {
                dispatch(getUserSuccess(result));
                return result;
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notifications.toastMessage("ErrorLoadingUser", "error"));
                    throw new Error(error);
                }
            });
    };
};

export const getCurrentUserSuccess = (user) => {
    return { type: types.READ_CURRENT_USER_SUCCESS, user };
};

export const getCurrentUser = (id, cancelToken) => {
    return function(dispatch) {
        const token = parseJwt(store.getState().accessToken);
        if (!token || !token.groups || token.groups.length === 0) {
            return Promise.resolve();
        }

        if (!store.getState().tenant.id) {
            return Promise.resolve();
        }

        return usersApi
            .GetUser(id, cancelToken)  
            .then((result) => {
                dispatch(getCurrentUserSuccess(result));
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notifications.toastMessage("ErrorLoadingUser", "error"));
                    throw new Error(error);
                }
            });
    };
};


export const checkUsernameSuccess = (user) => {
    return { type: types.CHECK_USER_NAME_SUCCESS, user };
};

export const checkUsername = (username, cancelToken) => {
    return function(dispatch) {
        dispatch(beginApiCall());
        return usersApi
            .CheckUsername(username, cancelToken)  
            .then((result) => {
            //dispatch(checkUsernameSuccess(result));
                return result.value;
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notifications.toastMessage("ErrorChekingUserName", "error"));
                    throw new Error(error);
                }
            });
    };
}; 

const updateUserRoles = async(roles, dispatch) => {
    await usersApi
        .updateUserRoles(roles)  
        .then((result) => {
        })
        .catch((error) => {
            dispatch(apiCallError(error));
            if (typeof(error.message) !== "undefined" &&
                error.message !== constants.cancelHttpRequest) {
                dispatch(notifications.toastMessage("ErrorUpdatingUserRoles", "error"));
                throw new Error(error);
            }
        });
};

export const createUserSuccess = (user) => {
    return { type: types.UPDATE_USER_SUCCESS, user };
};

export const createUser = (user, roles) => {
    return function(dispatch) {
        dispatch(beginApiCall());
        return usersApi
            .createUser(user)  
            .then(async (result) => {
                if (!!roles && roles.addedRoles.length > 0) {
                    roles.userId = result;
                    await updateUserRoles(roles, dispatch);
                }

                return result;
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" &&
                error.message !== constants.cancelHttpRequest) {
                    dispatch(notifications.toastMessage("ErrorCreatingUser", "error"));
                    throw new Error(error);
                }
            });
    };
};

export const updateUserSuccess = (user) => {
    return { type: types.UPDATE_USER_SUCCESS, user };
};

export const updateUser = (id, user, roles) => {
    return async function(dispatch) {
        await usersApi
            .updateUser(id, user)  
            .then(async(result) => {
                if (!!roles && (roles.addedRoles.length > 0 || roles.deletedRoles.length > 0)) {
                    await updateUserRoles(roles, dispatch);
                }

                dispatch(updateUserSuccess(result));
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" &&
                error.message !== constants.cancelHttpRequest) {
                    dispatch(notifications.toastMessage("ErrorUpdatingUser", "error"));
                    throw new Error(error);
                }
                else if (error.toString().includes("PreconditionFailed")) {
                    throw new Error(error);
                }
                else {
                    dispatch(notifications.toastMessage("ErrorUpdatingUser", "error"));
                    throw new Error(error);
                }
            });
    };
}; 

export const deleteUserSuccess = (user) => {
    return { type: types.UPDATE_USER_SUCCESS, user };
};

export const deleteUser = (id) => {
    return function(dispatch) {
        dispatch(beginApiCall());
        return usersApi
            .deleteUser(id)  
            .then((result) => {
            //dispatch(updateUserSuccess(result));
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" &&
                error.message !== constants.cancelHttpRequest) {
                    dispatch(notifications.toastMessage("ErrorDeletingUser", "error"));
                    throw new Error(error);
                }
            });
    };
}; 

export const resetpasswordSuccess = (user) => {
    return { type: types.UPDATE_USER_PASSWORD_SUCCESS, user };
};

export const resetpasswordUser = (id, data) => {
    return function(dispatch) {
        dispatch(beginApiCall());
        return usersApi
            .resetpassword(id, data)  
            .then((result) => {
            //dispatch(updateUserSuccess(result));
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                if (typeof(error.message) !== "undefined" &&
                error.message !== constants.cancelHttpRequest) {
                    dispatch(notifications.toastMessage("ErrorUpdatingPassword", "error"));
                    throw new Error(error);
                }
            });
    };
}; 

export const createPersonalRootGroup = () => {
    return function(dispatch) {
        return usersApi
            .createPersonalRootGroup()  
            .then(() => {})
            .catch((error) => {
                dispatch(notifications.toastMessage("ErrorCreatePersonalRootGroup", "error"));
                throw new Error(error);
            });
    };
}; 

export const GetEmailUserById = (id, cancelToken) => {
    return function(dispatch) {
        return usersApi
            .GetEmailUserById(id, cancelToken)  
            .then((result) => {
                return result;
            })
            .catch((error) => {
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notifications.toastMessage("ErrorGettingUserEmail", "error"));
                    throw new Error(error);
                }
            });
    };
};

export const GetUserByEmail = (username, cancelToken) => {
    return function(dispatch) {
        return usersApi
            .GetUserByEmail(username, cancelToken)  
            .then((result) => {
                return result;
            })
            .catch((error) => {
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notifications.toastMessage("ErrorGettingUserByEmail", "error"));
                    throw new Error(error);
                }
            });
    };
};

export const CreatePersonalInvitationToken = (tenantRootGroup, invitedEmail, anonymousId, cancelToken) => {
    return function(dispatch) {
        return usersApi
            .CreatePersonalInvitationToken(tenantRootGroup, invitedEmail, anonymousId, cancelToken)  
            .then((result) => {
                return result;
            })
            .catch((error) => {
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notifications.toastMessage("ErrorCreatePersonalInvitationToken", "error"));
                    throw new Error(error);
                }
            });
    };
};

export const JoinWithToken = (tenantRootGroup, token, anonymousId, cancelToken) => {
    return function(dispatch) {
        return usersApi
            .JoinWithToken(tenantRootGroup, token, anonymousId, cancelToken)  
            .then((result) => {
                return result;
            })
            .catch((error) => {
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notifications.toastMessage("ErrorJoinWithToken", "error"));
                    throw new Error(error);
                }
            });
    };
};

export const AddNewRootGroup = (userid, rootGroupPath, cancelToken) => {
    return function(dispatch) {
        return usersApi
            .AddNewRootGroup(userid, rootGroupPath, cancelToken)  
            .then((result) => {
                return result;
            })
            .catch((error) => {
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notifications.toastMessage("ErrorAddNewRootGroup", "error"));
                    throw new Error(error);
                }
            });
    };
};

export const GetUserCount = (cancelToken) => {
    return function(dispatch) {
        return usersApi
            .GetUserCount(cancelToken)  
            .then((result) => {
                return result;
            })
            .catch((error) => {
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notifications.toastMessage("ErrorGettingUserCount", "error"));
                    throw new Error(error);
                }
            });
    };
};
