import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function usersDataReducer(state = initialState.users, action) {
    if (action.type === types.LOAD_DATA_USERS_SUCCESS) {   
        return action.users;
    }
    else {
        return state;
    }
}
