
import fiFI from "../i18n/languages/fi-FI.json";
import enGB from "../i18n/languages/en-GB.json";
import svSE from "../i18n/languages/sv-SE.json";

export const getTranslations = () => {
    return {
        ...fiFI,
        ...svSE,
        ...enGB,
        "sv-FI": svSE["sv-SE"],
        "en-US": enGB["en-GB"],
    };
};
