import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function catalogAccountGroupReducer(state = initialState.catalogAccountGroup, action) {
    if (action.type === types.READ_CATALOG_ACCOUNT_GROUP_SUCCESS) {
        return action.catalogAccountGroup;
    }
    else {
        return state;
    }
}
