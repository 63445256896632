import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function tenantReducer(state = initialState.tenant, action) {
    if (action.type === types.READ_TENANT_SUCCESS) {
        return action.tenant;
    }
    else if (action.type === types.READ_TENANT_ACCESS_DENIED) {
        if (state.id === action.tenantId) {
            return { 
                ...state,
                id: null,
            };
        }
        else {
            return state;
        }
    }
    else if (action.type === types.SELECT_TENANT_SUCCESS) {
        return { 
            ...state,
            id: action.tenantId,
        };
    }
    else {
        return state;
    }
}
