import React from "react";
import CancelPromisesHOC from "../../helpers/cancelPromisesHOC";
import SideBarToggle from "./SideBarToggle";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import ProfileDrawer from "./ProfileDrawer";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import * as navBarStyles from "./styles";
import { bindActionCreators, compose } from "redux";
import { v4 as uuidv4 } from "uuid";
import { ProtectedContent } from "../auth/protectedContent";
import * as navigationActions from "../../redux/actions/navigationActions";
import * as tenantActions from "../../redux/actions/tenantActions";
import ArrowBack from "@material-ui/icons/ArrowBack";
import * as notificationActions from "../../redux/actions/notificationsActions";
import * as localizationActions from "../../redux/actions/localizationActions";
import * as userActions from "../../redux/actions/usersActions";
import * as webPushActions from "../../redux/actions/webPushActions";
import * as constants from "../../components/common/constants";
import Logo from "./Logo";
import axios from "axios";
import { tenantRoutePrefix } from "../../helpers/tenantHelper";

const styles = (theme) => ({
    navbar: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        zIndex: 100,
        background: "#302D40",
        color: "white",
        userSelect: "none",
        WebkitUserSelect: "none",
        height: (props) => props.height
    },
    moduletext: {
        fontSize: "1.3rem",
        textDecoration: "unset",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
});
export class TopNavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            host: "",
        };
    }

    componentDidUpdate(prevProps){
        if(this.props.tenant){
            const cancelToken = axios.CancelToken.source();
            if (this.props.realTimeMessage !== prevProps.realTimeMessage && 
                this.props.realTimeMessage.collectionId === constants.notifications) {
                this.props.actions.readNotifications("", "time DESC", 0, this.props.notificationSettings.tenants, cancelToken.token);
            }
        }
    }

    handleBackClick = () => {
        const path = tenantRoutePrefix(this.props.tenant) + "/workBoards";
        this.props.actions.setModule({ url: "/workBoards", name: "workBoards"});
        this.props.history.push(path, {clickIndicator: uuidv4()});
        this.props.close();
    };

    handleTitleClick = () => {
        const { navigation } = this.props;
        if(!!navigation.module.refresh) {
            const current = this.props.history.location.pathname;
            if (current !== "/refresh") {
                const refreshIndex = (this.props.history.location.state && this.props.history.location.state.refreshIndex) || 0;
                this.props.history.replace("/refresh", {refreshUrl: current, refreshIndex: refreshIndex + 1});
            }
            else{
                this.handleBackClick();
            }
        }
        else{
            this.props.history.push(navigation.module.url, {clickIndicator: uuidv4()});
        }
    }

    navItems = () => {
        return( 
            <ProtectedContent requireTenant={true}>
                <div style={{flex: 1}}>
                    <ProfileDrawer
                        tenant={this.props.tenant}
                        webPush={this.props.webPush}
                        availableTenants={this.props.availableTenants}
                        actions={this.props.actions}
                        notifications={this.props.notifications}
                        notificationSettings={this.props.notificationSettings}
                        user={this.props.user}
                        currentUser={this.props.currentUser}
                        t={this.props.t}
                        history={this.props.history}>
                    </ProfileDrawer>
                </div>
            </ProtectedContent>);
    }

    render() {
        const { t, classes, navigation, staticMenu } = this.props;
        return (
            <header className={classes.navbar}>
                <nav className="topnavbar_nav">
                    {!staticMenu && !this.props.showBackButton &&
                        <SideBarToggle style={{margin: 16}} click={this.props.drawerClickHandler} />} 
                    {!!this.props.isSharedToken && <Logo 
                        tenant={this.props.tenant} 
                        history={this.props.history} 
                        actions={this.props.actions}
                        close={this.props.close}
                        dimensions={{width: 140, height: "auto"}}
                        highlight={this.highlight}
                        link={"https://app.puskee.com"}
                        path={{route: "app.puskee.com", name: "WorkBoards"}}
                        type={"dark"}>
                    </Logo>}
                    {!!this.props.showBackButton && !this.props.isSharedToken ?
                        <Tooltip title={t(navigation.module ? navigation.module.name : "")}>
                            <IconButton style={{padding: 0, color: "white"}} onClick={() => this.handleTitleClick()}>
                                <ArrowBack style={{cursor: "pointer", margin: 16}} />
                            </IconButton>
                        </Tooltip>
                        :
                        <Typography edge="start"
                            style={{
                                marginLeft: !!staticMenu ? "230px" : "1rem",
                                display: (this.props.showModuleTitle ? "inline-block" : "none"),
                                cursor: "pointer",
                                fontSize: "1.1rem"
                            }}
                            onClick={() => this.handleTitleClick()}
                            color="inherit" aria-label="menu">
                            {t(!this.props.isSharedToken && navigation.module ? navigation.module.name : "")}
                        </Typography>
                    }
                    <div container={"true"} direction="row" id="react-module-title" className={classes.moduletext}>                       
                        {!this.props.isSharedToken && !!navigation.context.title && <div style={{margin: 8}}>
                            <Typography style={navBarStyles.moduleContext}>
                                {navigation.context.parent}
                                {navigation.context.parent !== "" && " / "}
                                {navigation.context.title}
                            </Typography>
                        </div>}
                    </div>
                    {!this.props.isSharedToken && this.navItems()}
                </nav>
            </header>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        currentUser: state.currentUser,
        tenant: state.tenant,
        availableTenants: state.availableTenants,
        navigation: state.navigation,
        showModuleTitle: state.navigation.showModuleTitle,
        isSharedToken: state.isSharedToken,
        realTimeMessage: state.realTimeMessage,
        notifications: state.notifications,
        notificationSettings: state.notificationSettings,
        webPush: state.webPush,
        notificationContextKey: state.notificationContextKey
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            showModuleTitle: bindActionCreators(navigationActions.showModuleTitle, dispatch),
            setNavigationContext: bindActionCreators(navigationActions.setContext, dispatch),
            setModule: bindActionCreators(navigationActions.setModule, dispatch),
            selectTenant: bindActionCreators(tenantActions.selectTenant, dispatch),
            readNotifications: bindActionCreators(notificationActions.readNotifications, dispatch),
            updateNotifications: bindActionCreators(notificationActions.updateNotifications, dispatch), 
            updateUser: bindActionCreators(userActions.updateUser, dispatch),
            getUser: bindActionCreators(userActions.getUser, dispatch),
            getAllAvailableTenants: bindActionCreators(tenantActions.getAllAvailableTenants, dispatch),
            getCurrentUserSuccess: bindActionCreators(userActions.getCurrentUserSuccess, dispatch),   
            selectLanguage: bindActionCreators(localizationActions.selectLanguage, dispatch),
            updateNotificationSettings: bindActionCreators(notificationActions.updateNotificationSettings, dispatch),
            readNotificationSettings: bindActionCreators(notificationActions.readNotificationSettings, dispatch),
            readSubscriptionState: bindActionCreators(webPushActions.readSubscriptionState, dispatch),
            subscribe: bindActionCreators(webPushActions.subscribe, dispatch),
            unsubscribe: bindActionCreators(webPushActions.unsubscribe, dispatch),
        }
    };
};

export default compose(
    CancelPromisesHOC, 
    withTranslation("common"), 
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(withRouter(TopNavBar));