/* eslint-disable no-console */
// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

import {
    SERVICE_WORKER_UPDATE_WAITING,
    SUBSCRIBE_TO_PUSH_NOTIFICATIONS_SUCCESS,
    SUBSCRIBE_TO_PUSH_NOTIFICATIONS_ERROR,
    UNSUBSCRIBE_FROM_PUSH_NOTIFICATIONS_SUCCESS,
    UNSUBSCRIBE_FROM_PUSH_NOTIFICATIONS_ERROR,
    PUSH_NOTIFICATIONS_STATUS_UPDATE
} from "./redux/actions/actionTypes"; 
import { store } from "./redux/store";
import AppInsightsHelper from "./helpers/appInsightsHelper";

const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const register = () => {
    if ((process.env.NODE_ENV === "production") && "serviceWorker" in navigator) {
    // The URL constructor is available in all browsers that support SW.
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location);
        if (publicUrl.origin !== window.location.origin) {
            // Our service worker won't work if PUBLIC_URL is on a different origin
            // from what our page is served on. This might happen if a CDN is used to
            // serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
            return;
        }

        window.addEventListener("load", () => {
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

            if (isLocalhost) {
                // This is running on localhost. Lets check if a service worker still exists or not.
                checkValidServiceWorker(swUrl);
            }
            else {
                // Is not local host. Just register service worker
                registerValidSW(swUrl);
            }
        });
    }
};

const registerValidSW = (swUrl) => {
    navigator.serviceWorker
        .register(swUrl)
        .then((registration) => {
            if (registration.waiting) {
                store.dispatch({ type: SERVICE_WORKER_UPDATE_WAITING });
            }

            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === "installed") {
                        if (navigator.serviceWorker.controller && registration.active) {
                            store.dispatch({ type: SERVICE_WORKER_UPDATE_WAITING });
                        }
                        else {
                            // At this point, everything has been precached.
                            // It's the perfect time to display a
                            // "Content is cached for offline use." message.
                            console.log("Content is cached for offline use.");
                        }
                    }
                };
            };
        })
        .catch((error) => {
            console.error("Error during service worker registration:", error);
            AppInsightsHelper.trackException(error);
        });
    

    let refreshing = false;

    // detect controller change and refresh the page
    navigator.serviceWorker.addEventListener("controllerchange", (event) => {
        if (!refreshing) {
            refreshing = true;
            AppInsightsHelper.trackEvent("Service worker controller change");
            window.location.reload();
        }
    });
};

const checkValidServiceWorker = (swUrl) => {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl)
        .then((response) => {
            // Ensure service worker exists, and that we really are getting a JS file.
            if (
                response.status === 404 ||
        response.headers.get("content-type").indexOf("javascript") === -1
            ) {
                // No service worker found. Probably a different app. Reload the page.
                navigator.serviceWorker.ready.then((registration) => {
                    registration.unregister().then(() => {
                        window.location.reload();
                    });
                });
            }
            else {
                // Service worker found. Proceed as normal.
                registerValidSW(swUrl);
            }
        })
        .catch(() => {
            console.log(
                "No internet connection found. App is running in offline mode."
            );
        });
};

export const skipWaiting = () => {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
            const registrationWaiting = registration.waiting;
            if (registrationWaiting && registrationWaiting.state === "installed") {
                AppInsightsHelper.trackEvent("Service worker update");
                registrationWaiting.postMessage({ type: "SKIP_WAITING" });
            }
        });
    }
};

export const sendWebNotification = (title, options) => {
    if ("serviceWorker" in navigator) {
        Notification.requestPermission((permission) => {
            if (permission === "granted") {
                navigator.serviceWorker.ready.then((registration) => {
                    registration.showNotification(title, options);
                });
            }
        });
    }
};

export const subscribeToWebPush = (applicationServerKey, dispatch, successCallback, failCallback) => {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
            var options = {
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(applicationServerKey)
            };
            
            if ("pushManager" in registration) {
                // As a side effect, at the time of calling subscribe, 
                // the browser will request the permission for showing notifications
                // so there is no need to call Notification.requestPermission explicitly.
                registration.pushManager.subscribe(options).then((subscription) => {
                    successCallback(subscription);
                    dispatch({ type: SUBSCRIBE_TO_PUSH_NOTIFICATIONS_SUCCESS, state: getSubscriptionState(subscription, true) });
                }, (error) => {
                    console.log(error);
                    AppInsightsHelper.trackException(error);
                    dispatch({ type: SUBSCRIBE_TO_PUSH_NOTIFICATIONS_ERROR, error });
                    failCallback(error);
                });
            }
        });
    }
    else {
        console.log("No service worker");
        store.dispatch({ type: SUBSCRIBE_TO_PUSH_NOTIFICATIONS_ERROR, error: "no service worker" });
    }
};

const getSubscriptionState = (subscription) => {
    return {
        supported: true, 
        subscribed: !!subscription, 
        expirationTime: (!subscription ? undefined : subscription.expirationTime),
    };
};

export const unsubscribeFromWebPush = (dispatch, successCallback) => {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
            if ("pushManager" in registration) {
                registration.pushManager.getSubscription().then((subscription) => {
                    if (!!subscription) {
                        subscription.unsubscribe().then((successful) => {
                            successCallback(subscription);

                            dispatch({ type: UNSUBSCRIBE_FROM_PUSH_NOTIFICATIONS_SUCCESS });
                        }).catch((e) => {
                            AppInsightsHelper.trackException(e);
                            dispatch({ type: UNSUBSCRIBE_FROM_PUSH_NOTIFICATIONS_ERROR, error: e.message });
                        });
                    }
                });
            }
        });
    }
    else {
        console.log("No service worker");
        dispatch({ type: UNSUBSCRIBE_FROM_PUSH_NOTIFICATIONS_ERROR, error: "no service worker" });
    }
};

export const readWebPushSubscriptionState = (dispatch) => {
    const notSupportedState = { supported: false, subscribed: false, expirationTime: undefined };

    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
            if ("pushManager" in registration) {
                registration.pushManager.getSubscription().then((subscription) => {
                    dispatch({ type: PUSH_NOTIFICATIONS_STATUS_UPDATE, state: getSubscriptionState(subscription)});
                });
            }
            else {
                dispatch({ type: PUSH_NOTIFICATIONS_STATUS_UPDATE, state: notSupportedState});
            }
        });
    }
    else {
        dispatch({ type: PUSH_NOTIFICATIONS_STATUS_UPDATE, state: notSupportedState});
    }
};

/**
 * urlBase64ToUint8Array
 * 
 * @param {string} base64String a public vavid key
 */
const urlBase64ToUint8Array = (base64String) => {
    var padding = "=".repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
        // eslint-disable-next-line no-useless-escape
        .replace(/\-/g, "+")
        .replace(/_/g, "/");

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
};

export const unregister = () => {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
            registration.unregister();
        });
    }
};

export const closeNotificationsByContext = (contextKey) => {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
            if ("getNotifications" in registration) {
                const options = { tag: contextKey };
                registration.getNotifications(options).then((notifications) => {
                    notifications.forEach((notification) => {
                        notification.close();
                    });
                });
            }
        });
    }
};
