import { useRef, useEffect } from "react";
import * as constants from "../components/common/constants";

export const makeCancelable = (promise) => {
    let isCanceled = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise
            .then((val) => (isCanceled ? reject(new Error({ isCanceled })) : resolve(val)))
            .catch((error) => (isCanceled ? reject(new Error({ isCanceled })) : reject(error)));
    });
        
    return {
        promise: wrappedPromise,
        cancel() {
            isCanceled = true;
        },
    };
};

const useCancellablePromise = (cancelable = makeCancelable) => {
    const emptyPromise = Promise.resolve(true);
    if (cancelable(emptyPromise).cancel === undefined) {
        throw new Error("promise wrapper argument must provide a cancel() function");
    }

    const promises = useRef(); 
    let tokens = [];
    
    const cleanPromises = (id) => {
        var idx = promises.current.findIndex((e) => e.id === id);
        if (idx >= 0) {
            promises.current[idx].cancel();
            promises.current.splice(idx, 1); 
        }
    };

    const cleanTokens = (id) => {
        var idx = tokens.findIndex((e) => e.id === id);
        if (idx >= 0) {
            tokens[idx].cancel(constants.cancelHttpRequest);
            tokens.splice(idx, 1); 
        }
    };

    const cancelPromises = (id) => {
        if (id) {
            cleanTokens(id);
            promises.current = promises.current || [];
            cleanPromises(id);
        }
    };

    const cancellablePromise = (p, cancelToken) => {
        tokens = [...tokens, cancelToken];
        var cPromise = cancelable(p);
        cPromise.id = cancelToken.id;
        promises.current.push(cPromise);
        return cPromise.promise;
    };

    useEffect(
        () => {
            promises.current = promises.current || [];
            return function cancel() {
                cleanPromises();
            };
        }, []
    );

    return { cancellablePromise, cancelPromises };
};

export default useCancellablePromise;
