import { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as networkActions from "../redux/actions/networkActions";
import * as notificationsActions from "../redux/actions/notificationsActions";
import { withTranslation } from "react-i18next";
import AppInsightsHelper from "../helpers/appInsightsHelper";

export class OfflineDetector extends Component {
    constructor(props) {
        super(props);

        this.onOnlineEvent = this.onOnlineEvent.bind(this);
        this.onOfflineEvent = this.onOfflineEvent.bind(this);
    }

    componentDidMount() {
        if (window.navigator.onLine) {
            this.props.actions.onlineDetected();
        }
        else {
            this.props.actions.offlineDetected();
        }

        window.addEventListener("offline", this.onOfflineEvent);
        window.addEventListener("online", this.onOnlineEvent);
    }

    componentWillUnmount() {
        window.removeEventListener("offline", this.onOfflineEvent);
        window.removeEventListener("online", this.onOnlineEvent);
    }

    onOnlineEvent() {
        this.props.actions.onlineDetected();
    }

    onOfflineEvent() {
        this.props.actions.offlineDetected();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.network.offline && this.props.network.offline) {
            AppInsightsHelper.trackEvent("Offline");
            this.props.actions.toastMessage(this.props.t("OfflineNotification"), "info");
        }
        else if (prevProps.network.offline && !this.props.network.offline) {
            AppInsightsHelper.trackEvent("Online");
            this.props.actions.toastMessage(this.props.t("OnlineNotification"), "info");
        }
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        network: state.network,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            toastMessage: bindActionCreators(notificationsActions.toastMessage, dispatch),
            onlineDetected: bindActionCreators(networkActions.onlineDetected, dispatch),
            offlineDetected: bindActionCreators(networkActions.offlineDetected, dispatch),
        }
    };
};

export default compose(withTranslation("common"),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(OfflineDetector);
