import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function productsRegisterReducer(state = initialState.productsRegister, action) {
    if (action.type === types.LOAD_PRODUCTREGISTER_SUCCESS) {
        return action.productsRegister;
    }
    else {
        return state;
    }
}
