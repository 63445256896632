import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function workSiteAttachmentsReducer(state = initialState.workSiteAttachments, action) {
    if (action.type === types.LOAD_WORKSITE_ATTACHMENTS_SUCCESS) {
        return action.workSiteAttachments;
    }
    else {
        return state;
    }
}
