import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from "./authorizationUtils";
import axios from "axios";

// azure functions can not handle ambigous routes
// we need explicit defined those

export const ReadWorkQueues = (filter, sort, page, userId, cancelToken, tags) => {
    const options = getRequestOptions(cancelToken);
    var data = {
        filter: filter,
        sort: sort,
        page: page,
        searchFields: "",
        filterTags: tags,
        userId: userId
    };
    
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/WorkQueue/GetAll`, data, options)
        .then(handleResponse)
        .catch(handleError);
};

export const ReadWorkQueue = (id, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.default) + `/api/Aggregate/WorkQueue/Get/${id}`, options)
        .then(handleResponse)
        .catch(handleError);
};

export const updateWorkQueue = (model) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/WorkQueue/POST`, model, options)
        .then(handleResponse)
        .catch(handleError);
};

export const UpdateWorkqueues = (model) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/WorkQueue/UpdateWorkqueues`, model, options)
        .then(handleResponse)
        .catch(handleError);
};

export const UpdateWorkqueuesData = (model) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/WorkQueue/UpdateWorkqueuesData`, model, options)
        .then(handleResponse)
        .catch(handleError);
};

export const ReadWorkQueueBatch = (batch, cancelToken, ownerId, roles) => {
    const options = getRequestOptions(cancelToken);
    var data = {
        batch: batch,
        ownerId: ownerId,
        roles: roles
    };

    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/WorkQueue/GetBatch`, data, options)
        .then(handleResponse)
        .catch(handleError);
};


