import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from "./authorizationUtils";
import axios from "axios";

// azure functions can not handle ambigous routes
// we need explicit defined those

export const ReadMessages = (filter = "", channelId, token, sort, page, sharedToken = "", cancelToken) => {
    const options = getRequestOptions(cancelToken);
    var data = {
        filter: filter,
        channelId: channelId,
        token: token,
        sort: sort ? sort : "SendDateTime DESC",
        page: page,
        sharedToken: sharedToken,
    };

    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/ChatMessages/GetAll`, data, options)
        .then(handleResponse)

        .catch(handleError);
};

export const postMessage = (data, token, sharedToken = "") => {
    const options = getRequestOptions();
    var model = {
        message: data,
        sharedToken: sharedToken,
        token: token
    };

    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/ChatMessages/POST`, model, options)
        .then(handleResponse)
        .catch(handleError);
};

export const postMembershipChange = (data) => {
    const options = getRequestOptions();
    var model = {
        message: data
    };

    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/ChatMessages/POSTMembershipChange`, model, options)
        .then(handleResponse)
        .catch(handleError);
};

export const GetUnreadMessagesCounter = (model, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/ChatMessages/GetUnreadMessagesCounter`, model, options)
        .then(handleResponse)
        .catch(handleError);
};

export const documentExistsInMessages = (channelId, documentId, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.default) + `/api/Aggregate/ChatMessages/documentExistsInMessages` +
    `?channelId=${channelId}&documentId=${documentId}`, options)
        .then(handleResponse)
        .catch(handleError);
};
