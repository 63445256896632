import { combineReducers } from "redux";
import tenant from "./tenantReducer";
import serviceWorker from "./serviceWorkerReducer";
import availableTenants from "./availableTenantsReducer";
import workCards from "./workCardsReducer";
import workCard from "./workCardReducer";
import workCardDocument from "./workCardDocumentReducer";
import localization from "./localizationReducer";
import toast from "./toastReducer";
import notifications from "./notificationsReducer";
import notificationSettings from "./notificationSettingsReducer";
import webPush from "./webPushReducer";
import persons from "./personsReducer";
import productRegister from "./productRegisterReducer";
import productsRegister from "./productsRegisterReducer";
import productGroup from "./productGroupReducer";
import productGroups from "./productGroupsReducer";
import productUnit from "./productUnitReducer";
import productUnits from "./productUnitsReducer";
import navigation from "./navigationReducer";
import sessionMetadata from "./sessionMetadataReducer";
import { reducer as oidcReducer } from "redux-oidc";
import contact from "./contactReducer";
import contacts from "./contactsReducer";
import customer from "./customerReducer";
import customers from "./customersReducer";
import catalog from "./catalogsReducer";
import workSite from "./workSiteReducer";
import workSites from "./workSitesReducer";
import workOrder from "./workOrderReducer";
import workOrders from "./workOrdersReducer";
import realTimeMessage from "./realTimeMessageReducer";
import workOrderDiaryEntry from "./workOrderDiaryEntryReducer";
import workOrderDiaryEntries from "./workOrderDiaryEntriesReducer";
import blobProgress from "./blobProgressReducer";
import workQueue from "./workQueueReducer";
import currentWorkBoardId from "./currentWorkBoardReducer";
import currentWorkQueueIndex from "./currentWorkQueueReducer";
import workBoardAggregate from "./workBoardReducer";
import workBoards from "./workBoardsReducer";
import usersGeneralInfo from "./usersReducer";
import isSessionActive from "./sessionLifeReducer";
import isConnectionSignalActive from "./realTimeConnectionSignalReducer";
import users from "./usersDataReducer";
import user from "./userReducer";
import currentUser from "./currentUserReducer";
import roles from "./rolesReducer";
import role from "./roleReducer";
import userPermissions from "./userPermisionsReducer";
import tag from "./tagReducer";
import tags from "./tagsReducer";
import tenantEditable from "./tenantEditableReducer";
import network from "./networkReducer";
import pendingRouter from "./pendingRouterReducer";
import chatChannel from "./chatChannelReducer";
import chatMessages from "./chatMessagesReducer";
import catalogTitle from "./catalogTitleReducer";
import catalogPaymentType from "./catalogPaymentTypeReducer";
import catalogAccountGroup from "./catalogAccountGroupReducer";
import catalogDefaults from "./catalogDefaultsReducer";
import catalogTimeSheetType from "./catalogTimeSheetReducer";
import chatChannelUnReadMessagesCounter from "./chatMessagesUnreadCounterReducer";
import isSharedToken from "./isSharedTokenReducer";
import tokensInfo from "./tokensReducer";
import historyEntries from "./historyEntriesReducer";
import historyDataType from "./historyDataTypeReducer";
import workOrderTemplate from "./workOrderTemplateReducer";
import workOrderTemplates from "./workOrderTemplatesReducer";
import accessToken from "./sessionAccessTokenReducer";
import membersTeam from "./workOrderMembersTeamReducer";
import blockScroll from "./blockScrollReducer";
import timeSheet from "./timeSheetReducer";
import timeSheets from "./timeSheetsReducer";
import workOrderDiaryEntriesCounter from "./workOrderDiaryEntriesCounterReducer";
import notificationContextKey from "./notificationContextKeyReducer";
import blobsProgress from "./blobsProgressReducer";
import blobsAbortProgress from "./blobsAbortProgressReducer";
import filterWorkBoard from "./filterWorkBoardReducer";
import formTemplates from "./formTemplatesReducer";
import formTemplate from "./formTemplateReducer";
import touchesWorkboard from "./touchesWorkboardReducer";
import customersAttachment from "./customersAttachmentReducer";
import customersAttachments from "./customersAttachmentsReducer";
import workSiteAttachment from "./workSiteAttachmentReducer";
import workSiteAttachments from "./workSiteAttachmentsReducer";

// eslint-disable-next-line max-lines-per-function
const createRootReducer = (history) => combineReducers({
    network,
    serviceWorker,
    oidc: oidcReducer,
    blobProgress,
    sessionMetadata,
    tenant,
    availableTenants,
    workCards,
    workCard, 
    workCardDocument,
    localization,
    toast,
    notifications,
    notificationContextKey,
    notificationSettings,
    webPush,
    persons,
    productRegister,
    productsRegister,
    productGroup,
    productGroups,
    productUnit,
    productUnits,
    navigation,
    contact,
    contacts,
    customer,
    customers,
    catalog,
    workSite,
    workSites,
    workOrder,
    workOrders,
    realTimeMessage,
    workOrderDiaryEntry,
    workOrderDiaryEntries,
    workQueue,
    workBoardAggregate,
    workBoards,
    currentWorkBoardId,
    currentWorkQueueIndex,
    usersGeneralInfo,
    isSessionActive,
    isConnectionSignalActive,
    users,
    user,
    currentUser,
    roles,
    role,
    userPermissions,
    tag,
    tags,
    tenantEditable,
    pendingRouter,
    chatChannel,
    chatMessages,
    catalogTitle,
    catalogPaymentType,
    catalogAccountGroup,
    catalogDefaults,
    catalogTimeSheetType,
    chatChannelUnReadMessagesCounter,
    isSharedToken,
    tokensInfo,
    historyEntries,
    historyDataType,
    accessToken,
    workOrderTemplate,
    workOrderTemplates,
    membersTeam,
    blockScroll,
    timeSheet,
    timeSheets,
    workOrderDiaryEntriesCounter,
    blobsAbortProgress,
    blobsProgress,
    filterWorkBoard,
    formTemplate,
    formTemplates,
    touchesWorkboard,
    customersAttachment,
    customersAttachments,
    workSiteAttachment,
    workSiteAttachments
});

export default createRootReducer;