export const initialNotificationSettings = (locale, tenants, email, privacySettings) => {
    var tenantArray = [];
    tenants.forEach((tenant) => {
        tenantArray.push({TenantId: tenant.id, name: tenant.name, selected: true});
    });
    
    return {
        privacySettings: privacySettings || {
            privacyPolicyAcknowledgement: false,
            marketingPermission: false,
        }, 
        tenants: tenantArray,
        localization: locale,
        state: "Added",
        userEmail: email,
        channels: {
            email: {enabled: true, interval: "0 */30 * * * *", configuration: {}},
            webPush: {enabled: false, interval: "* * * * * *", configuration: {}},
            app: { enabled: true, interval: "* * * * * *", configuration: {}}
        },
        settings: [
            {
                type: "boardMemberChange", 
                enabled: true,
            },
            {
                type: "cardAssignedUserChanges", 
                enabled: false,
            },  
            {
                type: "newCardsOnBoard", 
                enabled: false,
            },
            {
                type: "messageNotification", 
                enabled: true,
            },
        ]
    };
};