import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from "./authorizationUtils";
import axios from "axios";

// azure functions can not handle ambigous routes
// we need explicit defined those

export const ReadCatalog = (id, cancelToken) => {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.default) + `/api/Aggregate/GeneralCatalogs/Get/${id}`, options)
        .then(handleResponse)
        .catch(handleError);
};

export const updateCatalogItems = (model) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/GeneralCatalogs/UpdateItems`, model, options)
        .then(handleResponse)
        .catch(handleError);
};
