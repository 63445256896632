import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function currentWorkQueueReducer(state = initialState.currentWorkQueueIndex, action) {
    if (action.type === types.SET_CURRENT_WORKQUEUE){
        return action.currentWorkQueueIndex;
    }
    else {
        return state;
    }
}
