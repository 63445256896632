import React from "react";
import "./NavBar.css";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import * as navBarStyles from "./styles.js";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import * as navigationActions from "../../redux/actions/navigationActions";
import * as pendingRouterActions from "../../redux/actions/pendingRouteActions";
import * as tenantActions from "../../redux/actions/tenantActions";
import * as workBoardActions from "../../redux/actions/workBoardActions";

export const maximizedWidth = 212;
export class TenantSelectorList extends React.Component {
    constructor(props) {
        super(props);
        this.handleOpen = this.handleOpen.bind(this);

        this.state = {
            wideNav: true,
            navWidth: props.menuWidth,
            selectedId: this.props.tenant.id,
        };
    }

    componentDidMount() {
        this.props.actions.getAllAvailableTenants();
    }

    handleOpen = () => {
        this.setState({ wideNav: true });
        this.setState({ navWidth: maximizedWidth });
    };

    handleRoute = (e, item) => {
        this.setState({selectedId: item.id});

        if (item.id !== this.props.tenant.id) {
            this.props.actions.selectTenant(item.id);
            this.props.close(item);
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div id="main-menu"
                style={{
                    background: "none", 
                    color: "black",
                    width: "100%"
                }} 
            >
                <List>
                    {this.props.availableTenants.map(
                        (item) => {
                            return (
                                <ListItem
                                    button
                                    id={"side-nav-" + item.id}
                                    onClick={!item.external ? ((e) => this.handleRoute(e, item)) : (this.handleClick.bind(
                                        this,
                                        item.id,
                                    ))}
                                    key={item.id}
                                    style={navBarStyles.teamSelector(this.props.tenant.id === item.id)}
                                >
                                    <ListItemText
                                        primary={t(item.name)}
                                        style={{padding: "0 1em"}}
                                    />
                                </ListItem>
                            );
                        }
                    )}
                </List>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        tenant: state.tenant,
        open: state.open,
        navigation: state.navigation,
        userPermissions: state.userPermissions,
        pendingRouter: state.pendingRouter,
        isSharedToken: state.isSharedToken,
        availableTenants: state.availableTenants
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            setModule: bindActionCreators(navigationActions.setModule, dispatch),
            pendingRouterProgress: bindActionCreators(pendingRouterActions.pendingRouterProgress, dispatch),
            selectTenant: bindActionCreators(tenantActions.selectTenant, dispatch),
            setCurrentWorkBoard: bindActionCreators(workBoardActions.setCurrentWorkBoard, dispatch),
            getAllAvailableTenants: bindActionCreators(tenantActions.getAllAvailableTenants, dispatch),
        }
    };
};

export default compose(withTranslation("common"), connect(
    mapStateToProps,
    mapDispatchToProps
))(withRouter(TenantSelectorList));
