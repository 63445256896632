import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function attachmentsCounterReducer(state = initialState.workOrderDiaryEntriesCounter, action) {
    if (action.type === types.COUNTER_WORKORDER_DIARYENTRY_SUCCESS) {
        return action.workOrderDiaryEntriesCounter;
    }
    else {
        return state;
    }
}