import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function workSitesReducer(state = initialState.workSites, action) {
    if (action.type === types.LOAD_WORKSITE_SUCCESS) {
        return action.WorkSites;
    }
    else {
        return state;
    }
}
