
export const NewButtonMinimized = {
    borderRadius: 50,
    margin: "-10px 8px 20px 8px",
    width: 40,
};

export const ModalPaper = {
    display: "block",
    textAlign: "start",
    alignItems: "center",
    backgroundColor: "white",
    padding: "3px",
    width: "auto",
    margin: "1em auto",
};

export const boardSelector = (focus) => {
    return {
        borderLeft: "4px solid #2E2B2B",
        borderRight: "4px solid #2E2B2B",
        textAlign: "center",
        padding: 12,
        margin: "16px 0px 8px 0px",
        minHeight: "1.5em",
        cursor: "pointer",
        backgroundColor: focus ? "#61616126" : "transparent",
        transition: "all 300ms cubic-bezier(0.4, 0, 1, 1) 0s"
    };
};

export const teamSelector = (focus) => {
    return {
        borderLeft: focus ? "4px solid #DE2027" : "none",
        borderRight: focus ? "4px solid #DE2027" : "none",
        textAlign: "center",
        padding: 8,
        cursor: "pointer",
        backgroundColor: focus ? "#61616126" : "transparent",
        transition: "all ease 1s",
        color: "#2E2B2B"
    };
};

export const ProfileDrawerListItem = {
    justifyContent: "center",
    margin: "auto",
    fontSize: 21
};

export const profileAction = {
    flexDirection: "column",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    maxWidth: 80
};

export const profileActionButton = {
    padding: 16, 
    background: "rgb(147 192 230)",
    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
};

export const profileActionText = {
    fontSize: "1rem",
    fontWeight: 600,
    padding: 10

};

export const moduleContext = { 
    color: "white", 
    display: "inline-block", 
    fontSize: "80%", 
    maxWidth: "45vw", 
    verticalAlign: "bottom", 
    overflow: "hidden", 
    textOverflow: "ellipsis",
};


