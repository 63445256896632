import * as types from "./actionTypes";

export const layoutNavigationFixer = () => {
    return function(dispatch) {
        window.scrollTo(0, 0);
    };
};

export const setModule = (modules) => {
    return { 
        type: types.SET_MODULE,
        modules
    };
};

export const setContext = (contextTitle, parent = "") => {
    if(parent === contextTitle){
        parent = "";
    }

    return { 
        type: types.SET_CONTEXT,
        title: contextTitle,
        parent: parent,
    };
};

export const showModuleTitle = (show) => {
    return { 
        type: types.SHOW_MODULE_TITLE,
        show
    };
};
