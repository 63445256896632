import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function ToastReducer(state = initialState.toast, action) {
    if (action.type === types.TOAST_MESSAGE) { 
        return action.toast;
    }
    else if (action.type === types.CLEAR_TOASTS) {
        return initialState.toast;
    }

    return state;
}