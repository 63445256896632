import * as types from "./actionTypes";
import * as chatMessagesApi from "../../api/chatMessagesApi";
import * as notificationActions from "./notificationsActions";
import * as constants from "../../components/common/constants";
import * as chatChannelActions from "./chatChannelActions";
import * as workOrderDiaryEntryActions from "./workOrderDiaryEntryActions";
import * as BlobActions from "./blobsActions";
import uuid from "uuid/v4";

export const readChatUnReadMessagesCounterSuccess = (chatChannelUnReadMessagesCounter) => {
    return { type: types.LOAD_CHAT_UNREAD_MESSAGES_COUNTER_SUCCESS, chatChannelUnReadMessagesCounter };
};

export const readChatMessagesSuccess = (chatMessages) => {
    return { type: types.LOAD_CHAT_MESSAGES_SUCCESS, chatMessages};
};

export const readChatMessages = (filter, channelId, token, sort, page, host, cancelToken) => {
    return function(dispatch) {
        if (token && token !== "") {
            return chatMessagesApi
                .ReadMessages(filter, channelId, token, sort, page, host, cancelToken)  
                .then((chatMessages) => {
                    dispatch(readChatMessagesSuccess(chatMessages, page));
                })
                .catch((error) => {
                    if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                    !error.message.includes(constants.cancelHttpRequest)) {
                        dispatch(notificationActions.toastMessage("ErrorloadingChatMessages", "error"));
                        throw new Error(error);
                    }
                });
        } 
       
        return [];
    };
}; 

export const postChatMessageSuccess = (chatMessage) => {
    return { type: types.POST_CHAT_MESSAGE_SUCCESS, chatMessage };
};

export const postChatMessage = (model, token, sharedToken) => {
    return async function(dispatch) {
        return chatMessagesApi
            .postMessage(model, token, sharedToken)  
            .then(async(result) => {
                if (model.state !== constants.Deleted) {
                    dispatch(postChatMessageSuccess(result));
                    dispatch(chatChannelActions.SetMessagesCounter(model.channelId, sharedToken));
                }
                else {
                    if (model.data && model.data.file && model.data.file.documentId) {
                        var documentId = model.data.file.documentId;
                        var find = await dispatch(workOrderDiaryEntryActions.readWorkOrderDiaryEntryByDocumentId(documentId));
                        if (!find) {
                            await BlobActions.Blobs(documentId, constants.blobPermissions.Deleted, null, false, model.channelId, );
                        }
                    }
                }
                
                return result;
            })
            .catch((error) => {
                if (!error.toString().includes("PreconditionFailed")) {
                    dispatch(notificationActions.toastMessage("ErrorPostingMessage", "error"));
                }
                
                throw new Error(error);
            });
    };
}; 

export const documentExistsInMessages = (channelId, documentId, cancelToken) => {
    return function(dispatch) {
        return chatMessagesApi
            .documentExistsInMessages(channelId, documentId, cancelToken)  
            .then((result) => {
                return result;
            })
            .catch((error) => {
                if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest &&
                    !error.message.includes(constants.cancelHttpRequest)) {
                    dispatch(notificationActions.toastMessage("ErrorChekingIfExistDocumentInMessages", "error"));
                    throw new Error(error);
                }
            });
    };
}; 

export const postMembershipMessages = (added, removed, channelId, currentUser, usersGeneralInfo, t) => {
    return function(dispatch) {
        removed.forEach((user) => {
            dispatch(postMembershipMessage(false, user.id, channelId, currentUser, usersGeneralInfo, t));
        });

        added.forEach((user) => {
            dispatch(postMembershipMessage(true, user.id, channelId, currentUser, usersGeneralInfo, t));
        });
    };
};

export const postMembershipMessage = (added, userId, channelId, currentUser, usersGeneralInfo, t) => {
    return function(dispatch) {
        var currentUserName = currentUser.firstName + " " + currentUser.lastName;
        var text = "";
        
        if (currentUser.id === userId) {
            text = !!added ?
                currentUserName + t("ChatMemberAddedSelf") :
                currentUserName + t("ChatMemberRemovedSelf");
        }
        else {
            const found = [usersGeneralInfo].find((member) => member.id === userId);
            text = !!added ?
                currentUserName + t("ChatMemberAdded") + found.name :
                currentUserName + t("ChatMemberRemoved") + found.name;
        }
        
        var message = {
            id: uuid(),
            channelId: channelId,
            type: "memberchange",
            text: text,
            data: { 
                userId: userId,
                action: added ? "add" : "remove"
            },
            sender: currentUser.id,
            state: constants.Added,
            sendDateTime: new Date(),
        };
        
        chatMessagesApi
            .postMembershipChange(message)  
            .catch((error) => {
                dispatch(notificationActions.toastMessage("ErrorPostMembershipChange", "error"));
                throw new Error(error);
            });
    };
};
