/* /src/routes/privateRoute.js */
import React, { Fragment } from "react";
import { connect } from "react-redux";

export class ProtectedContentComponent extends React.Component {
    render = () => {
        const { user, sessionMetadata, isSharedToken } = this.props;
        if (isSharedToken || (!!user && !sessionMetadata.logoutInProgress
            && (!this.props.requireTenant || !!this.props.tenant.id)
            && !!this.props.accessToken)) {
            return <Fragment>{this.props.children}</Fragment>;
        }
        else {
            return null;
        }
    }
};

const mapStateToProps = (state) => {
    return {
        tenant: state.tenant,
        sessionMetadata: state.sessionMetadata,
        user: state.oidc.user,
        accessToken: state.accessToken,
        isSharedToken: state.isSharedToken
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    };
};

export const ProtectedContent = connect(mapStateToProps, mapDispatchToProps)(ProtectedContentComponent);