import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from "./authorizationUtils";
import axios from "axios";
import * as constants from "../components/common/constants";
import FilterHelper from "../helpers/filterHelper"; 

// azure functions can not handle ambigous routes
// we need explicit defined those
export const ReadHistoryEntries = (filter, sort, page, idType, dataType, tags, cancelToken) => {
    const options = getRequestOptions(cancelToken);
   
    var data = {
        filter: filter,
        sort: sort ? sort : "createdBy DESC",
        page: page,
        idType: idType,
        dataType: dataType,
        searchFields: FilterHelper.getSearchfilter(constants.historyEntryFilter),
        filterTags: tags
    };
  
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/HistoryEntry/GetAll`, data, options)
        .then(handleResponse)
        .catch(handleError);
};

export const updateHistoryEntry = (model) => {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.default) + `/api/Aggregate/HistoryEntry/POST`, model, options)
        .then(handleResponse)
        .catch(handleError);
};
