import { BEGIN_LOGIN, BEGIN_LOGOUT, BEGIN_SILENT_RENEW, SESSION_ACTIVE, SESSION_ACCESS_TOKEN_ACTIVE } from "./actionTypes";
import { loadUser } from "redux-oidc";
import { store } from "../../redux/store";
import userManager from "../../services/userManager";
import { setRedirectUri } from "../../routes/redirectStorage";
import * as networkActions from "./networkActions";
import * as notifications from "./notificationsActions";

window.addEventListener("storage", (event) => {
    if (event.storageArea === localStorage) {
        let isSessionActive = localStorage.getItem("isSessionActive");
        if (isSessionActive === null || !isSessionActive) {
            store.dispatch(logout());
        }
    }
});

export const login = () => {
    return function (dispatch) {
        const action = { type: BEGIN_LOGIN };
        dispatch(action);
        const mylocation = window.location.pathname + window.location.search;
        setRedirectUri(mylocation);
        userManager.signinRedirect()
            .then((result) => {
            })
            .catch((exception) => {
                if(exception.message === "Network Error") {
                    dispatch(notifications.toastMessage("NetworkError", "error"));
                }
                else {
                    dispatch(notifications.toastMessage("SigninRedirectError", "error"));
                }
            });

        return action;
    };
};

export const forceRenew = () => {
    return function (dispatch) {
        const action = { type: BEGIN_LOGIN };
        dispatch(action);

        const mylocation = window.location.pathname + window.location.search;
        setRedirectUri(mylocation);
        userManager.revokeAccessToken()
            .then(userManager.removeUser())
            .then(userManager.signinRedirect());
    };
};

export const silentRenew = () => {
    return function (dispatch) {
        const action = { type: BEGIN_SILENT_RENEW };
        dispatch(action);
        userManager.signinSilent().then((user) => {
            loadUser(store, userManager);
            dispatch(sessionAcessTokenActive(user.access_token));
            dispatch(networkActions.onlineDetected());
        },
        (error) => {
            if (error.message === "Network Error") {
                dispatch(networkActions.offlineDetected());
            }
            else {
                dispatch(login());
            }
        });
    };
};

export const logout = () => {
    return function (dispatch) {
        const action = { type: BEGIN_LOGOUT };
        dispatch(action);
        userManager.signoutRedirect();
    };
};

export const logoutSso = (issuerUrl, redirectUrl) => {
    return function (dispatch) {
        window.location.href = issuerUrl + 
            "protocol/openid-connect/logout?redirect_uri=" +
            encodeURI(redirectUrl);
    };
};

export const cleanAfterLogout = () => {
    return function (dispatch) {
        localStorage.clear();
    };
};

export const sessionActivated = (user) => {
    return function (dispatch) {
        localStorage.setItem("isSessionActive", true);
        const action = { type: SESSION_ACTIVE, isSessionActive: true };
        dispatch(action);
    };
};

export const sessionDeactivated = () => {
    return function (dispatch) {
        localStorage.setItem("isSessionActive", false);
        const action = { type: SESSION_ACTIVE, isSessionActive: false };
        dispatch(action);
    };
};

export const sessionAcessTokenActive = (accessToken) => {
    return function (dispatch) {
        const action = { type: SESSION_ACCESS_TOKEN_ACTIVE, accessToken };
        dispatch(action);
    };
};

