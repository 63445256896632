import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function sessionAccesTokenReducer(state = initialState.accessToken, action) {
    if (action.type === types.SESSION_ACCESS_TOKEN_ACTIVE) {
        return action.accessToken;
    }
    else {
        return state;
    }
}
