import * as types from "./actionTypes";
import { getTranslations } from "../../i18n/external";
import i18next from "i18next";
import i18n from "../../i18n/i18n";

import * as notificationActions from "./notificationsActions";
import { Locales, defaultLanguage } from "../../i18n/locales";


export const loadTranslationsSuccess = (value) => {
    return { type: types.LOAD_TRANSLATIONS_SUCCESS, translationLoaded: value };
};

export const loadTranslations = () => {
    return function(dispatch) {
        try {
            const translations = getTranslations();
            i18next.init();
            Object.keys(translations).forEach((key) => {
                i18next.addResourceBundle(key, "common", translations[key]);
            });

            dispatch(loadTranslationsSuccess(true));
        }
        catch (error) {
            dispatch(loadTranslationsSuccess(false));
            dispatch(notificationActions.toastMessage("ErrorLoadingTranslations", "error"));
        }
    };
};
 
export const selectDefaultLanguage = (targetLanguage) => {
    return function(dispatch) {
        try {
            if (Object.values(Locales).includes(targetLanguage)) {
                i18n.changeLanguage(targetLanguage);
            }
            else if (!!Locales[targetLanguage]) {
                i18n.changeLanguage(Locales[targetLanguage]);
            }
            else {
                i18n.changeLanguage(Locales[defaultLanguage]);
            }
        }
        catch (error) {
            dispatch(loadTranslationsSuccess(false));
            dispatch(notificationActions.toastMessage("DefaultLanguageError", "error"));
        }
    };
}; 

export const selectLanguage = (locale) => {
    return function(dispatch) {
        try {
            i18n.changeLanguage(Locales[locale]);
            localStorage.setItem("i18nextLng", Locales[locale]);
        }
        catch (error) {
            dispatch(notificationActions.toastMessage("ErrorSelectingLanguage", "error"));
        }
    };
};