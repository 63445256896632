import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function productUnitReducer(state = initialState.productUnit, action) {
    if (action.type === types.READ_PRODUCTUNIT_SUCCESS) {
        return action.productUnit;
    }
    else {
        return state;
    }
}
