import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function customersAttachmentsReducer(state = initialState.customersAttachments, action) {
    if (action.type === types.LOAD_CUSTOMERS_ATTACHMENTS_SUCCESS) {
        return action.customersAttachments;
    }
    else {
        return state;
    }
}
