import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function sessionLifeReducer(state = initialState.isSessionActive, action) {
    if (action.type === types.SESSION_ACTIVE) {
        return action.isSessionActive;
    }
    else {
        return state;
    }
}
