import {ONLINE_DETECTED, OFFLINE_DETECTED} from "../actions/actionTypes";
import initialState from "./initialState";

export default function networkReducer(state = initialState.network, action) {
    if (action.type === ONLINE_DETECTED) {
        return {
            ...state,
            offline: false
        };
    }
    else if (action.type === OFFLINE_DETECTED) {
        return {
            ...state,
            offline: true
        };
    }
    else {
        return state;
    }
}
