import { ONLINE_DETECTED, OFFLINE_DETECTED } from "./actionTypes";

export const onlineDetected = () => {
    return function (dispatch) {
        const action = { type: ONLINE_DETECTED };
        dispatch(action);
    };
};

export const offlineDetected = () => {
    return function (dispatch) {
        const action = { type: OFFLINE_DETECTED };
        dispatch(action);
    };
};
