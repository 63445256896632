import * as types from "../actions/actionTypes";
import initialState from "./initialState";

// eslint-disable-next-line max-lines-per-function
export default function workBoardReducer(state = initialState.workBoardAggregate, action) {
    if (action.type === types.READ_WORKBOARD_SUCCESS) {
        return {
            ...state,
            workBoard: action.workBoard
        };
    }
    else if (action.type === types.START_UPDATE_WORKBOARD) {
        return {
            ...state,
            workBoard: action.workBoard
        };
    }
    else if (action.type === types.UPDATE_WORKBOARD_SUCCESS) {
        return {
            ...state,
            workBoard: action.workBoard
        };
    }
    else if (action.type === types.READ_WORKQUEUES_SUCCESS) {
        return {
            ...state,
            workQueues: action.workQueues
        };
    }
    else if (action.type === types.START_UPDATE_WORKQUEUE) {
        return {
            ...state,
            workQueues: {
                ...state.workQueues,
                [action.workQueue.id]: action.workQueue
            }
        };
    }
    else if (action.type === types.READ_WORKQUEUE_SUCCESS) {
        return {
            ...state,
            workQueues: {
                ...state.workQueues,
                [action.workQueue.id]: action.workQueue
            }
        };
    }
    else if (action.type === types.READ_WORKORDERS_SUCCESS) {
        return {
            ...state,
            workOrders: action.workOrders,
            tags: getUsedTags(action.workOrders)
        };
    }
    else if (action.type === types.UPDATE_WORKORDER_SUCCESS) {
        const workOrders = {
            ...state.workOrders,
            [parseInt(action.workOrder.id)]: action.workOrder
        };

        return {
            ...state,
            workOrders: workOrders,
            tags: getUsedTags(workOrders)
        };
    }
    else if (action.type === types.READ_WORKSITES_SUCCESS) {
        return {
            ...state,
            workSites: action.workSites
        };
    }
    else if (action.type === types.MOVE_WORKORDER_ON_WORKBOARD) {
        const { workOrderId, fromWorkQueueId, toWorkQueueId, beforeWorkOrderId, position, status } = action.move;
        const workOrders = !state.workOrders[workOrderId] ? state.workOrders : {
            ...state.workOrders,
            [workOrderId]: {
                ...state.workOrders[workOrderId],
                status: status || state.workOrders[workOrderId].status,
            }
        };

        return {
            ...state,
            workQueues: manipulateWorkQueues(state.workQueues, workOrderId, fromWorkQueueId, toWorkQueueId, beforeWorkOrderId, position),
            workOrders
        };
    }
    else if (action.type === types.DELETE_WORKORDER_FROM_WORKBOARD) {
        const { workOrderId, fromWorkQueueId } = action.deletion;
        return {
            ...state,
            workQueues: deleteWorkOrderFromQueue(state.workQueues, workOrderId, fromWorkQueueId)
        };
    }
    else {
        return state;
    }
}

const manipulateWorkQueues = (workQueues, workOrderId, fromWorkQueueId, toWorkQueueId, beforeWorkOrderId, position) => {
    const workOrderNumber = parseInt(workOrderId);
    const beforeWorkOrderNumber = parseInt(beforeWorkOrderId);
    if (!fromWorkQueueId) {
        const sourceQueueModel = Object.values(workQueues).find((workQueue) => workQueue.workOrders.includes(workOrderNumber));
        if (sourceQueueModel) {
            fromWorkQueueId = sourceQueueModel.id;
        }
    }

    let changedQueues = {};

    let sourceQueue;
    if (fromWorkQueueId) {
        sourceQueue = [...workQueues[fromWorkQueueId].workOrders];
        const sourceIndex = sourceQueue.findIndex((id) => id === workOrderNumber);
        sourceQueue.splice(sourceIndex, 1);
        changedQueues[fromWorkQueueId] = {
            ...workQueues[fromWorkQueueId],
            workOrders: sourceQueue
        };
    }

    let destQueue = fromWorkQueueId === toWorkQueueId ? sourceQueue : [...workQueues[toWorkQueueId].workOrders];
    const destIndex = (position > 0 || position === 0) ? position : destQueue.findIndex((id) => id === beforeWorkOrderNumber);
    if (destIndex < 0) {
        destQueue = [...destQueue, workOrderNumber];
    }
    else {
        destQueue.splice(destIndex, 0, workOrderNumber);
    }

    changedQueues[toWorkQueueId] = {
        ...workQueues[toWorkQueueId],
        workOrders: destQueue
    };

    return {
        ...workQueues,
        ...changedQueues
    };
};

const deleteWorkOrderFromQueue = (workQueues, workOrderId, fromWorkQueueId) => {
    let sourceQueue = [...workQueues[fromWorkQueueId].workOrders];
    const sourceIndex = sourceQueue.findIndex((id) => id === parseInt(workOrderId));
    sourceQueue.splice(sourceIndex, 1);

    return {
        ...workQueues,
        [fromWorkQueueId]: {
            ...workQueues[fromWorkQueueId],
            workOrders: sourceQueue
        }
    };
};

const getUsedTags = (workOrders) => {
    let tagsObject = {};

    Object.values(workOrders).forEach((workOrder) => {
        workOrder.tags.forEach((tag) => {
            tagsObject[tag] = tag;
        });
    });

    let tags = Object.keys(tagsObject);
    tags.sort();

    return tags;
};

