import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function blobProgressReducer(state = initialState.blockScroll, action) {
    if (action.type === types.BLOCK_SCROLL_SUCCESS) { 
        return action.blockScroll;
    }
    else {
        return state;
    }
}