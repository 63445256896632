import React, { lazy, Suspense, useContext } from "react";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { RequireTenant } from "./requireTenantRoute";
import { RequireUser } from "./requireUserRoute";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import LayoutSpinner from "../components/layoutSpinner";
import { LayoutContext } from "../components/layout";
import LayoutNavigationFixer from "./layoutNavigationFixer";

import { Callback } from "../components/auth/callback";
import { Logout } from "../components/auth/logout";
import { LogoutCallback } from "../components/auth/logoutCallback";
import { SilentRenew } from "../components/auth/silentRenew";
import { Refresh } from "../components/refresh";
import { NotFound } from "../components/notFound";

import FrontPageRedirect from "../components/common/welcome/frontPageRedirect";
const Join = lazy(() => import("../components/common/welcome/join"));
const Welcome = lazy(() => import("../components/common/welcome/welcome"));
const CompanyRegistrationRedirect = lazy(() => import("../components/common/welcome/companyRegistrationRedirect"));
const CompanyRegistration = lazy(() => import("../components/common/welcome/companyRegistration"));

const TenantRedirect = lazy(() => import("../components/tenants/tenantRedirect"));
const TenantEdit = lazy(() => import("../components/tenants/tenantEdit"));
const ProductsRegister = lazy(() => import("../components/productsRegister/productsRegister"));
const ProductRegister = lazy(() => import("../components/productsRegister/productRegister"));
const ProductGroups = lazy(() => import("../components/productGroup/productGroups"));
const ProductGroup = lazy(() => import("../components/productGroup/productGroup"));
const ProductUnits = lazy(() => import("../components/productUnit/productUnits"));
const ProductUnit = lazy(() => import("../components/productUnit/productUnit"));
const Contacts = lazy(() => import("../components/contacts/contacts"));
const Contact = lazy(() => import("../components/contacts/contact"));
const Customers = lazy(() => import("../components/customers/customers"));
const Customer = lazy(() => import("../components/customers/customer"));
const WorkSites = lazy(() => import("../components/workSite/workSites"));
const WorkSite = lazy(() => import("../components/workSite/workSite"));
const WorkOrders = lazy(() => import("../components/workOrder/workOrders"));
const WorkOrder = lazy(() => import("../components/workOrder/workOrder"));
const InitialBoardCreator = lazy(() => import("../components/workQueues/initialBoardCreator"));
const JoinWorkBoard = lazy(() => import("../components/workQueues/joinWorkBoard"));
const EmptyBoard = lazy(() => import("../components/workQueues/emptyBoard"));
const NewBoard = lazy(() => import("../components/workQueues/newBoard"));
const WorkBoards = lazy(() => import("../components/workQueues/workBoards"));
const UsersManagement = lazy(() => import("../components/userManagement/usersManagement"));
const UserManagement = lazy(() => import("../components/userManagement/userManagement"));
const Roles = lazy(() => import("../components/roles/roles"));
const Role = lazy(() => import("../components/roles/role"));
const TimeSheets = lazy(() => import("../components/timesheet/timesheets"));
const TimeSheet = lazy(() => import("../components/timesheet/timeSheet"));
const WorkQueuePermissions = lazy(() => import("../components/workQueues/workQueuePermission"));
const FormTemplates = lazy(() => import("../components/formTemplate/formTemplates"));
const sharedTasks = lazy(() => import("../components/sharedTasks/sharedTasks"));


// eslint-disable-next-line max-lines-per-function
export const Routes = (props) => {
    const location = useLocation();
    const match = useRouteMatch("/:tenantName/workBoards/:workBoardId");
    const match2 = useRouteMatch("/:tenantName/workBoards/:workBoardId/:WorkOrderId");
    const layoutContext = useContext(LayoutContext);
    const refreshKey = (location.state && location.state.refreshIndex) || 0;

    return (
        <LayoutNavigationFixer>
            <Suspense fallback={<LayoutSpinner show={true} />}>
                <TransitionGroup>
                    <CSSTransition
                        key={location.pathname}
                        classNames="route"
                        timeout={500}
                    >
                        <div className="content-area" 
                            style={{
                                position: "absolute",
                                overflow: "visible",
                                width: "auto",
                                height: "auto",
                                background: "#f9f9f9",
                                left: layoutContext.menuWidth,
                                right: 0,
                                top: layoutContext.topNavBarHeight,
                                bottom: 0,
                                paddingRight: match || (match && match.isExact) || match2 ? 0 : layoutContext.scrollbarPlaceholder,
                            }}
                        >
                            <Switch location={location}>
                                <Route exact={true} path="/signin-oidc" component={Callback} />
                                <Route exact={true} path="/logout" component={Logout} />
                                <Route exact={true} path="/logout/callback" component={LogoutCallback} />
                                <Route exact={true} path="/silentrenew" component={SilentRenew} />
                                <Route exact={true} path="/refresh" component={Refresh} />
                                
                                <RequireUser exact={true} path='/notfound' component={NotFound} />
                                <RequireUser exact={true} path='/welcome' component={Welcome} />
                                <RequireUser exact={true} path='/companyRegistration' component={CompanyRegistration} />
                                <RequireUser exact={true} path='/companyRegistrationRedirect' component={CompanyRegistrationRedirect} />
                                <RequireUser exact={true} path='/join' component={Join} />
                                <RequireTenant exact={true} path='/:tenantName/productsRegister' refreshKey={refreshKey} component={ProductsRegister} />
                                <RequireTenant exact={true} path='/:tenantName/productsRegister/:productId' component={ProductRegister} />
            
                                <RequireTenant exact={true} path='/:tenantName/productGroups' refreshKey={refreshKey} component={ProductGroups} />
                                <RequireTenant exact={true} path='/:tenantName/productGroups/:productGroupId' component={ProductGroup} />
            
                                <RequireTenant exact={true} path='/:tenantName/productUnits' refreshKey={refreshKey} component={ProductUnits} />
                                <RequireTenant exact={true} path='/:tenantName/productUnits/:productUnitId' component={ProductUnit} />
            
                                <RequireTenant exact={true} path='/:tenantName/contacts/:contactId' component={Contact} />
                                <RequireTenant exact={true} path='/:tenantName/contacts' refreshKey={refreshKey} component={Contacts} />
                        
                                <RequireTenant exact={true} path='/:tenantName/customers/:customerNumber' component={Customer} />
                                <RequireTenant exact={true} path='/:tenantName/customers' refreshKey={refreshKey} component={Customers} />
            
                                <RequireTenant exact={true} path='/:tenantName/worksites' refreshKey={refreshKey} component={WorkSites} />
                                <RequireTenant exact={true} path='/:tenantName/worksites/:workSiteId' component={WorkSite} />
                        
                                <RequireTenant exact={true} path='/:tenantName/workOrders' refreshKey={refreshKey} component={WorkOrders} />
                                <RequireTenant exact={true} path='/:tenantName/workOrders/:workOrderId' component={WorkOrder} />
                                <RequireTenant exact={true} path='/:tenantName/workOrders/:parentWorkOrderId/:workOrderId' component={WorkOrder} />
            
                                <RequireTenant exact={true} path='/:tenantName/workBoards' refreshKey={refreshKey} component={WorkBoards} />
                                <RequireTenant exact={true} path='/:tenantName/workBoards/new' component={NewBoard} />
                                <RequireTenant exact={true} path='/:tenantName/workBoards/join' component={JoinWorkBoard} />
                                <RequireTenant 
                                    exact={true} 
                                    path='/:tenantName/workBoards/:workBoardId' 
                                    refreshKey={refreshKey} 
                                    component={WorkQueuePermissions} 
                                />
                                <RequireTenant exact={true} path='/:tenantName/workBoards/:workBoardId/:workOrderId' component={WorkOrder} />
            
                                <RequireTenant exact={true} path='/:tenantName/initiate' component={InitialBoardCreator} />
                                <RequireTenant exact={true} path='/:tenantName/noBoards' component={EmptyBoard} />
                    
                                <RequireTenant exact={true} path='/:tenantName/usersManagement' refreshKey={refreshKey} component={UsersManagement} />
                                <RequireTenant exact={true} path='/:tenantName/usersManagement/:userId' component={UserManagement} />
                    
                                <RequireTenant exact={true} path='/:tenantName/roles' refreshKey={refreshKey} component={Roles} />
                                <RequireTenant exact={true} path='/:tenantName/roles/:roleId' component={Role} />
                                
                                <RequireTenant exact={true} path='/:tenantName/timesheets/:timeSheetId' component={TimeSheet} />
                                <RequireTenant exact={true} path='/:tenantName/timeSheets' refreshKey={refreshKey} component={TimeSheets} />
                                
                                <RequireTenant exact={true} path='/:tenantName/formTemplates' refreshKey={refreshKey} component={FormTemplates} />
                                
                                <RequireTenant exact={true} path='/:tenantName/sharedTasks' refreshKey={refreshKey} component={sharedTasks} />
                                <RequireTenant exact={true} path='/:tenantName/info' component={TenantEdit} />

                                <Route exact={true} path='/workOrderShared/:workOrderId' component={WorkOrder}/>
                                <RequireUser exact={true} path='/workOrderShared/:workOrderId/accepted' component={WorkOrder} />

                                <RequireUser exact={true} path='/' component={FrontPageRedirect} />
                                <RequireTenant exact={true} path='/:tenantName/' component={TenantRedirect} />

                                <RequireUser component={NotFound} />
                            </Switch>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </Suspense>
        </LayoutNavigationFixer>
    );
};


export default Routes;