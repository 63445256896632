import React, { useEffect, useState } from "react";
import {TextField, Grid, Typography, IconButton, Select, FormControl, InputLabel, MenuItem} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import Notifications from "@material-ui/icons/Notifications";
import { DialogModal } from "../common/dialogModal.js";
import * as constants from "../common/constants";
import * as styles from "../common/styles";
import PasswordEditor from "../common/passwordEditor";
import NotificationSettings from "../notifications/NotificationSettings";
import BusinessCard from "./businessCard";
import MaterialIcon from "react-google-material-icons";

const UserSettingsModal = (props) => {
    const { 
        notificationSettings, 
        t, 
        user, 
        tenant, 
        handleClose, 
        availableTenants,
        webPush,
        actions,
        history } = props;
    
    const [isChanged, setIsChanged] = useState(false);
    const [currentUser, setCurrentUser] = useState(props.currentUser);
    const [openedPanel, setOpenedPanel ] = useState(props.openedPanel);
    const [displayName, setDisplayName] = useState(props.currentUser.attributes.displayName);
    const [displayEmail, setDisplayEmail] = useState(props.currentUser.attributes.displayEmail);
    const [displayPhoneNumber, setDisplayPhoneNumber] = useState(props.currentUser.attributes.displayPhoneNumber);
    const [locale, setLocale] = useState(currentUser.attributes.locale);

    useEffect(() => {
        setCurrentUser(props.currentUser);
    }, [props.currentUser]);

    const togglePanel = (panel) => {
        if(panel === openedPanel){
            setOpenedPanel(null);
        }
        else{
            setOpenedPanel(panel);
        }
    };

    const selectLocale = (target) => {
        actions.selectLanguage(target);
        setLocale(target);
    };

    const handleChangeUserSettingChange = (e) => {
        setIsChanged(true);
        setCurrentUser((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
            
        }));
    };

    const saveUserSettings = async (save) => {
        if(!!isChanged || !!save){
            var record = JSON.parse(JSON.stringify(currentUser));
            record.attributes.locale = [locale];
            record.attributes.displayName = [displayName];
            record.attributes.displayEmail = [displayEmail];
            record.attributes.displayPhoneNumber = [displayPhoneNumber];

            await actions.updateUser(currentUser.id, {...record}, {
                userId: currentUser.id,
                addedRoles: [],
                deletedRoles: []
            }).then(() => {})
                .catch(() => {})
                .finally(() =>{
                    actions.getCurrentUserSuccess(record);
                });
        }
    };

    useEffect(() => {
        saveUserSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale]);
    
    const renderNotificationSettings = () => {
        return(
            <Grid xs={11} sm={10} style={styles.userSettingContainer(openedPanel === "Notifications")} item >
                <Grid container justifyContent="flex-start" alignItems="center" onClick={() => togglePanel("Notifications")}>
                    <Grid item xs={2} sm={1}>
                        <Notifications style={{margin: 4}}/>
                    </Grid>
                    <Grid item xs={8} sm={10}>
                        <Grid container justifyContent="flex-start" alignItems="center" wrap={openedPanel === "Notifications" ? "wrap" : "nowrap"}>
                            <Typography style={{fontWeight: 600, margin: 4}}>{t("Notifications")}</Typography>
                            <Typography style={styles.userSettingDetails(openedPanel === "Notifications")}>{t("NotificationSettingDetails")}</Typography>  
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton>
                            {openedPanel === "Notifications" ? <ExpandLess/> : <ExpandMore/>}
                        </IconButton>
                    </Grid>
                </Grid> 
                {openedPanel === "Notifications" && 
                <Grid item style={{margin: 8, borderTop: "1px solid gainsboro"}}>
                    <NotificationSettings 
                        t={t} 
                        tenant={tenant}
                        webPush={webPush}
                        availableTenants={availableTenants} 
                        notificationSettings={notificationSettings}
                        actions={actions}
                        history={history}
                        user={user}
                    />
                </Grid>
                }
            </Grid>
        );
    };

    const renderBusinesscard = () => {
        return(
            <Grid xs={11} sm={10} style={styles.userSettingContainer(openedPanel === "BusinessCard")} item>
                <Grid container justifyContent="flex-start" alignItems="center" wrap="nowrap" onClick={() => togglePanel("BusinessCard")}>
                    <Grid item xs={2} sm={1}>
                        <MaterialIcon icon={"badge"} style={{margin: 4}}/>
                    </Grid>
                    <Grid item xs={8} sm={10}>
                        <Grid container justifyContent="flex-start" alignItems="center" wrap={openedPanel === "BusinessCard" ? "wrap" : "nowrap"}>
                            <Typography style={{fontWeight: 600, margin: 4}}>{t("BusinessCard")}</Typography>             
                            <Typography style={styles.userSettingDetails(openedPanel === "BusinessCard")}>{t("BusinessCardDetails")}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton>
                            {openedPanel === "BusinessCard" ? <ExpandLess/> : <ExpandMore/>}
                        </IconButton>
                    </Grid>
                </Grid> 
                {openedPanel === "BusinessCard" &&
                <Grid item style={{borderTop: "1px solid gainsboro"}}>
                    <Grid container alignItems="center" justifyContent="flex-start" style={{marginTop: 8}}>
                        <Grid item xs={12} md={6} style={{margin: 8}}> 
                            <Grid item xs={12} style={{margin: 8}}> 
                                <TextField variant="outlined" label={t("Name")} fullWidth={true} id="userSettings_DisplayName"
                                    value={displayName}
                                    onBlur={() => saveUserSettings(true)}
                                    onChange={(e) => setDisplayName(e.target.value)}></TextField>
                            </Grid>
                            <Grid item xs={12} style={{margin: 8}}> 
                                <TextField variant="outlined" label={t("Email")} fullWidth={true} id="userSettings_DisplayEmail"
                                    value={displayEmail}
                                    onBlur={() => saveUserSettings(true)}
                                    onChange={(e) => setDisplayEmail(e.target.value)}></TextField>
                            </Grid>
                            <Grid item xs={12} style={{margin: 8}}> 
                                <TextField variant="outlined" label={t("PhoneNumber")} fullWidth={true} id="userSettings_DisplayPhoneNumber"
                                    value={displayPhoneNumber}
                                    onBlur={() => saveUserSettings(true)}
                                    onChange={(e) => setDisplayPhoneNumber(e.target.value)}></TextField>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={5} style={{margin: 8}}> 
                            <BusinessCard
                                t={t}
                                displayPhoneNumber={displayPhoneNumber}
                                displayEmail={displayEmail}
                                displayName={displayName}>
                            </BusinessCard>
                        </Grid>
                    </Grid>

                  
                </Grid>
                }
            </Grid>
        );
    };

    const renderProfileSettings = () => {
        return(
            <Grid xs={11} sm={10} item style={styles.userSettingContainer(openedPanel === "User")}>
                <Grid container 
                    justifyContent="flex-start" 
                    alignItems="center" wrap="nowrap" 
                    onClick={() => togglePanel("User")}>
                    <Grid item xs={2} sm={1}>
                        <PersonIcon style={{margin: 4}}/>
                    </Grid>
                    <Grid item xs={8} sm={10} >
                        <Grid container justifyContent="flex-start" alignItems="center" wrap={openedPanel === "User" ? "wrap" : "nowrap"} >
                            <Typography style={{fontWeight: 600, margin: 4}}>{t("UserSettings")}</Typography>
                            <Typography style={styles.userSettingDetails(openedPanel === "User")}>
                                {t("UserSettingDetails")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton>
                            {openedPanel === "User" ? <ExpandLess/> : <ExpandMore/>}
                        </IconButton>
                    </Grid>
                </Grid>
                {openedPanel === "User" && 
                <Grid item style={{margin: 8, borderTop: "1px solid gainsboro"}}>
                    <Grid container alignItems="center" justifyContent="center" style={{marginTop: 8}}>
                        <Grid item xs={11} md={5} style={{margin: 8}}> 
                            <TextField variant="outlined" name="firstName" label={t("FirstName")} fullWidth={true} id="userSettings_FirstName"
                                value={currentUser.firstName}
                                onBlur={() => saveUserSettings(true)}
                                onChange={(e) => handleChangeUserSettingChange(e)}>
                            </TextField>
                        </Grid>
                        <Grid item xs={11} md={5} style={{margin: 8}}> 
                            <TextField variant="outlined" name="lastName" label={t("LastName")} fullWidth={true} id="userSettings_LastName"
                                value={currentUser.lastName}
                                onBlur={() => saveUserSettings(true)}
                                onChange={(e) => handleChangeUserSettingChange(e)}></TextField>
                        </Grid>
                        <Grid item xs={11} md={5} style={{margin: 8}}> 
                            <TextField variant="outlined" name="email" disabled={true} label={t("Email")} fullWidth={true} id="userSettings_Email"
                                value={currentUser.email}
                                onBlur={() => saveUserSettings(true)}
                                onChange={(e) => handleChangeUserSettingChange(e)}></TextField>
                        </Grid>
                        <Grid item xs={11} md={5} style={{margin: 8}}> 
                            <FormControl fullWidth variant="outlined" >
                                <InputLabel id="localeinput-label" >{t("Language")}</InputLabel>
                                <Select
                                    style={{padding: "6px 12px", textAlign: "left"}}
                                    labelId="localeinput-label"
                                    id="localeinput"
                                    value={locale}
                                    label={t("Language")}
                                    onChange={(e) => selectLocale(e.target.value)}
                                >
                                    {constants.availableLocales.map((option) => {
                                        return(
                                            <MenuItem key={option.value} value={option.value}>{t(option.name)}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>}
            </Grid>
        );
    };

    const renderChangePassword = () => {
        return(
            <Grid xs={11} sm={10} style={styles.userSettingContainer(openedPanel === "Password")} item>
                <Grid container justifyContent="flex-start" alignItems="center" wrap="nowrap" onClick={() => togglePanel("Password")}>
                    <Grid item xs={2} sm={1}>
                        <VpnKeyIcon style={{margin: 4}}/>
                    </Grid>
                    <Grid item xs={8} sm={10} >
                        <Grid container justifyContent="flex-start" alignItems="center" >
                            <Typography style={{fontWeight: 600, margin: 4}}>{t("ChangePassword")}</Typography> 
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton >
                            {openedPanel === "Password" ? <ExpandLess/> : <ExpandMore/>}
                        </IconButton>
                    </Grid>
                </Grid> 
                {openedPanel === "Password" && 
                    <Grid item style={{margin: 8, borderTop: "1px solid gainsboro"}}>
                        <PasswordEditor open={true} userId={user.profile.sub} t={t}/>
                    </Grid>
                }
            </Grid>
        );
    };

    const renderContent = () => {
        return (
            <Grid container alignItems="center" justifyContent="center" style={{marginTop: 8}}>
                {renderProfileSettings()}
                {renderBusinesscard()}
                {renderNotificationSettings()}
                {renderChangePassword()}
            </Grid>
        );
    };

    return (
        <DialogModal id="dialogQuery" backgroundColor={"#FAFAFA"} open={true} onClose={handleClose} transition={true}
            clickAction={handleClose} fullWidth={true} maxWidth={"md"}
            t={t} title={t("UserSettings")} backButton={true} hideCloseButton={true} hideOkButton={true}
            content={renderContent} includeOnlyContent={true} forceFocus={true} PaperProps={{style: {height: "90vh", backgroundColor: "#FAFAFA"}}}/>
    );
};

export default UserSettingsModal;

