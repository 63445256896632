import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function workOrderDiaryEntryReducer(state = initialState.workOrderDiaryEntry, action) {
    if (action.type === types.READ_WORKORDER_DIARYENTRY_SUCCESS) {
        return action.workOrderDiaryEntry;
    }
    else {
        return state;
    }
}
